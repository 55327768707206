import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import ClientIcon from '../../../../assets/img/png/clientesIcon.png';
import UserIcon from '../../../../assets/img/png/usersIcon.png';
import WarningIcon from '../../../../assets/img/png/warningIcon.png';

import './Modules.scss';
import { useHistory } from 'react-router';
import useUser from '../../../../hooks/useUser';
import { ROL_ADMIN } from '../../../../utils/constants';

export default function Modules() {
    const { person } = useUser();
    const catalog = [
        {
            name: 'supplier',
            isAdmin: true,
        },
        {
            name: 'clients',
            isAdmin: true,
        },
        {
            name: 'users',
            isAdmin: true,
        },
        {
            name: 'warnings',
            isAdmin: false,
        },
    ];

    return (
        <div className="modules">
            <Row gutter={[0, 15]}>
                {[ROL_ADMIN].some((r) => person.roles.includes(r)) && (
                    <>
                        {catalog.map((cat) => {
                            if (cat.isAdmin) {
                                return (
                                    <Col
                                        span={12}
                                        className="modules__container animate__animated  animate__bounceInLeft"
                                    >
                                        <ButtonAction type={cat.name} />
                                    </Col>
                                );
                            }
                        })}
                    </>
                )}
                {catalog.map((cat) => {
                    if (!cat.isAdmin) {
                        return (
                            <Col
                                span={12}
                                className="modules__container animate__animated  animate__bounceInLeft"
                            >
                                <ButtonAction type={cat.name} />
                            </Col>
                        );
                    }
                })}
            </Row>
        </div>
    );
}

function ButtonAction(props) {
    const { type } = props;
    const urlImage =
        type === 'supplier'
            ? ClientIcon
            : type === 'users'
            ? UserIcon
            : type === 'clients'
            ? ClientIcon
            : WarningIcon;
    const title =
        type === 'supplier'
            ? 'Proveedores'
            : type === 'users'
            ? 'Usuarios'
            : type === 'clients'
            ? 'Clientes'
            : 'Alertas';
    const { Title } = Typography;
    const history = useHistory();

    const handleRedirect = () => {
        if (type === 'supplier') {
            history.push('/administracion/proveedores');
        } else if (type === 'users') {
            history.push('/administracion/usuarios');
        } else if (type === 'warnings') {
            history.push('/administracion/alertas');
        } else if (type === 'clients') {
            history.push('/administracion/clientes');
        }
    };

    return (
        <Button
            className="modules__button"
            style={{ padding: 10 }}
            onClick={() => handleRedirect()}
        >
            <img width={60} src={urlImage} />
            <Title style={{ color: 'white', marginTop: 10 }} level={5}>
                {title}
            </Title>
        </Button>
    );
}
