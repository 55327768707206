export const API_URL_CATALOG = 'Catalog';
export const API_URL_CATALOG_VALUES = 'CatalogValues';
export const API_URL_SUPPLIER_CONTACTS = 'Contact';
export const API_URL_CUSTOMER_CONTACTS = 'Contact';
export const API_URL_RATE = 'Rate';
export const API_URL_QUOTE = 'Quote';
export const API_URL_CATALOG_SUPPLIER = 'CatalogValues/Supplier';
export const API_URL_RFQ = 'RFQ';
export const API_URL_QUOTE_VALIDATE_LINE = 'QuoteLines/ValidateLine';
export const API_URL_QUOTE_PDF = (id) => `Quote/${id}/pdf/`;
export const API_SEND_QUOTE_EMAIL = `Quote/SendQuoteEmail`;
