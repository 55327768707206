import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Modal as ModalAntd,
} from 'antd';
import React, { useState } from 'react';
import { PlusCircleOutlined, EditOutlined } from '@ant-design/icons';
import TarifaImg from '../../../assets/img/jpg/tarifa.jpg';
import ProveedorImg from '../../../assets/img/jpg/proveedor.jpg';
import CotizacionImg from '../../../assets/img/jpg/cotizacion.jpg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AddEditPricingForm from '../AddEditPricingForm/AddEditPricingForm';
import AddSupplierForm from '../../Admin/Administration/SuppliersTable/AddSupplierForm';
import Modal from '../../General/Modal';
import useUser from '../../../hooks/useUser';
import {
  ROL_ADMIN,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_PRICING,
  ROL_VENTAS,
} from '../../../utils/constants';

const CardsHome = () => {
  const { Title } = Typography;
  let history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [reloadSuppliers, setReloadSuppliers] = useState(null);
  const [reloadTable, setReloadTable] = useState(null);
  const { person } = useUser();

  const showModal = (type, rate) => {
    if (type === 'AddTarifa') {
      setModalIsVisible(true);
      setTitleModal('Agregar tarifa');
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          setReloadTable={setReloadTable}
        ></AddEditPricingForm>
      );
    } else if (type === 'AddSupplier') {
      setModalIsVisible(true);
      setTitleModal('Agregar proveedor');
      setContextModal(
        <AddSupplierForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadSuppliers}
        ></AddSupplierForm>
      );
    }
  };

  return (
    <div>
      <Row gutter={16}>
        {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER, ROL_PRICING].some((r) =>
          person.roles.includes(r)
        ) && (
          <Col md={8} xs={24}>
            <div className='admin__container'>
              <Col>
                <img width={'100%'} src={TarifaImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Agregar nueva tarifa
                </Title>
                <p style={{ textAlign: 'center' }}>Descripción</p>
                <Divider />
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => showModal('AddTarifa')}
                  className='btn-download'
                  icon={<PlusCircleOutlined />}
                  style={{ width: 120 }}
                >
                  Agregar
                </Button>
              </Col>
            </div>
          </Col>
        )}
        {[ROL_ADMIN, ROL_LAYOUT, ROL_MANAGER, ROL_VENTAS].some((r) =>
          person.roles.includes(r)
        ) && (
          <Col md={8} xs={24}>
            <div className='admin__container'>
              <Col>
                <img width={'100%'} src={CotizacionImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Agregar nueva cotización
                </Title>
                <p style={{ textAlign: 'center' }}>Descripción</p>
                <Divider />
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => history.push(`/cotizacion/nueva`)}
                  className='btn-download'
                  icon={<PlusCircleOutlined />}
                  style={{ width: 120 }}
                >
                  Agregar
                </Button>
              </Col>
            </div>
          </Col>
        )}
        {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER, ROL_PRICING].some((r) =>
          person.roles.includes(r)
        ) && (
          <Col md={8} xs={24}>
            <div className='admin__container'>
              <Col>
                <img width={'100%'} src={ProveedorImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Agregar nuevo proveedor
                </Title>
                <p style={{ textAlign: 'center' }}>Descripción</p>
                <Divider />
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => showModal('AddSupplier')}
                  className='btn-download'
                  icon={<PlusCircleOutlined />}
                  style={{ width: 120 }}
                >
                  Agregar
                </Button>
              </Col>
            </div>
          </Col>
        )}
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default CardsHome;
