import React, { useEffect, useReducer, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Select,
  Checkbox,
  Tabs,
  Alert,
} from 'antd';
import { registerSupplierApi } from '../../../../../api/supplier';
import { getCompanyIdApi } from '../../../../../api/company';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import {
  CERTIFICATIONS_CATALOG_ID,
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
  STATE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  SERVICES_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  PACKAGING_UNIT_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
} from '../../../../../helpers/constants';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import TextArea from 'antd/lib/input/TextArea';

const AddSupllierForm = ({ setModalIsVisible, setReloadSuppliers }) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const companyId = getCompanyIdApi();
  const [financiales, setFinanciales] = useState([]);
  const token = getAccessTokenApi();
  const [countrySelected, setCountrySelected] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { TabPane } = Tabs;
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [operationsCatalog, setOperationsCatalog] = useState([]);
  const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
  const [certificationsCatalog, setCertificationsCatalog] = useState([]);
  const [hazMatCatalog, setHazMatsCatalog] = useState([]);
  const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
  const [packagingCatalog, setPackagingCatalog] = useState([]);
  const [measurementUnitCatalog, setMeasurementUnitCatalog] = useState([]);
  const [servicesCatalog, setServicesCatalog] = useState([]);
  const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);

  useEffect(() => {
    if (loadingForm) {
      const filters = `?role=${'Financial'}`;
      getUsersApi(token, filters)
        .then((response) => {
          if (response) {
            let supp = response.result.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            setFinanciales(supp);
          }
          setLoadingForm(false);
        })
        .catch((err) => {});
    }
  }, [token, loadingForm]);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getCatalogValueHandle(
      OPERACIONES_CATALOG_ID,
      setOperationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setUnitTypeCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      CERTIFICATIONS_CATALOG_ID,
      setCertificationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(HAZMAT_CATALOG_ID, setHazMatsCatalog, setLoadingForm);
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setTypeOfServiceCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      PACKAGING_UNIT_CATALOG_ID,
      setPackagingCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      MEASUREMENT_UNIT_CATALOG_ID,
      setMeasurementUnitCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      SERVICES_CATALOG_ID,
      setServicesCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoadCatalog,
      setLoadingForm
    );
  }, []);

  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        state: '',
        city: '',
        address: '',
        zipCode: '',
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
  };
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
  };

  const addSupplierFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['parentSupplierId'] = '';
    data['level'] = 0;
    data['company'] = companyId;

    const response = await registerSupplierApi(token, data);
    if (response === undefined) {
      notification['error']({
        message: 'Ocurrió un error, Inténtelo más tarde',
      });
      return false;
    }
    if (response.statusCode === 401) {
      notification['error']({
        message: 'Usuario no autorizado.',
      });
      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1500);
      return false;
    }
    if (response.statusCode === 200) {
      setModalIsVisible(false);
      setReloadSuppliers(true);
      notification['success']({
        message: 'Proveedor agregado con éxito',
      });
    } else {
      let messageError = 'Ocurrió un error, Inténtelo otra vez';
      if (response.statusCode === 409) {
        messageError = response.description;
      }
      if (response.statusCode === 400) {
        messageError = response.description;
      }
      notification['error']({
        message: messageError,
      });
    }
    setLoadingForm(false);
  };
  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (!values.name || !values.supplierId) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete los campos obligatorios de detalle de proveedor'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 10000);
    }
  };
  return (
    <Spin spinning={loadingForm} tip='Cargando...'>
      <Form
        name='add-promo'
        layout='vertical'
        form={form}
        onFinish={addSupplierFunction}
        initialValues={{ active: true }}
        onFinishFailed={errorFields}
      >
        <div>
          <Tabs defaultActiveKey='1' type='card' size={'middle'}>
            {/* TABPANE1 */}
            <TabPane tab='Detalle' key='1'>
              <div>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label='Nombre de proveedor:'
                      name='name'
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un nombre',
                        },
                      ]}
                    >
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label='Id de proveedor:'
                      name='supplierId'
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un Id',
                        },
                      ]}
                    >
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item label='Número de proveedor:' name='supplierNum'>
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label='Proveedor estatus:'
                      name='active'
                      valuePropName='checked'
                    >
                      <Checkbox>Activo</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label='Correo electrónico:' name='emailAddress'>
                      <Input
                        type={'email'}
                        placeholder='Ingresa tu información'
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='RFC:' name='fiscalID'>
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label='Nombre de contacto:' name='contactName'>
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <span>
                          Teléfono:&nbsp;
                          <Tooltip title='El número telefónico debe contener 10 dígitos'>
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      name='phoneNumber'
                      rules={[
                        {
                          required: false,
                          message: 'Por favor ingresa número telefónico',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || value.toString().length === 10) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'Por favor ingresa número telefónico a 10 dígitos'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label='Financiero:'
                  name='financialId'
                  rules={[
                    {
                      message: 'Por favor seleccione un financiero',
                    },
                  ]}
                >
                  <Select placeholder='Selecciona una opción' allowClear={true}>
                    {financiales.map((f) => (
                      <Select.Option key={f.value} value={f.value}>
                        {f.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label='Observaciones:' name='observations'>
                  <TextArea placeholder='Ingresa tu información' />
                </Form.Item>
              </div>
            </TabPane>
            {/* TABPANE2 */}
            <TabPane tab='Ubicación' key='2' forceRender>
              <div>
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item label='País' name='country'>
                      <Select
                        // disabled={disableAllInputs}
                        // disabled={disableAllInputs || service === 'land'}
                        placeholder='Selecciona una opción'
                        onChange={onChangeCountryOrigin}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {countries.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='Estado:' name='state'>
                      <Select
                        // disabled={disableAllInputs}
                        placeholder='Selecciona una opción'
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeState}
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {states.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='Ciudad:' name='city'>
                      <Select
                        // disabled={disableAllInputs}
                        placeholder='Selecciona una opción'
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {cities.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label='Dirección:' name='address'>
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label='CP:' name='zipCode'>
                      <Input placeholder='Ingresa tu información' />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </TabPane>
            {/* TABPANE3 */}
            <TabPane tab='Cátalogos' key='3' forceRender>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label='Operaciones:' name='operationsArray'>
                    <Select
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      allowClear
                      showArrow
                    >
                      {operationsCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label='Certificaciones:'
                    name='certificationsArray'
                  >
                    <Select
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      allowClear
                      showArrow
                    >
                      {certificationsCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label='Unidades:' name='unitsArray'>
                    <Select
                      allowClear
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      showArrow
                    >
                      {unitTypeCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label='Tipo de carga:' name='loadTypesArray'>
                    <Select
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      allowClear
                      showArrow
                    >
                      {typeOfLoadCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label='Servicios:' name='servicesArray'>
                    <Select
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      allowClear
                      showArrow
                    >
                      {servicesCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label='Tipo servicio:' name='typeOfServicesArray'>
                    <Select
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      allowClear
                      showArrow
                    >
                      {typeOfServiceCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  {/* <Form.Item
                                        label="Unidad de medida:"
                                        name="measurementUnitsArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                        >
                                            {measurementUnitCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                </Col>
                <Col md={12} xs={24}>
                  {/* <Form.Item
                                        label="Embalaje:"
                                        name="packagingArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                        >
                                            {packagingCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col ms={12} xs={24}>
                  <Form.Item label='HAZ MAT:' name='hazMatArray'>
                    <Select
                      placeholder='Selecciona una opción'
                      mode='multiple'
                      allowClear
                      showArrow
                    >
                      {hazMatCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          {showAlert && (
            <Alert
              message={textType}
              description={textDescription}
              type='warning'
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Row>
            <Col span={24}>
              <Form.Item>
                <Button htmlType='submit' className='btn-enviar' block>
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddSupllierForm;
