import React, { useEffect, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Checkbox,
    Input,
    Form,
    Spin,
    Button,
    notification,
} from 'antd';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { useParams } from 'react-router-dom';
import { getInvoiceIdApi } from '../../../../api/billing';
import { addClarificationApi } from '../../../../api/clarifications';
import './AddClarificationPayment.scss';
import { useHistory } from 'react-router-dom';

export default function AddClarificationPayment() {
    const [loadingForm, setLoadingForm] = useState(false);
    let { idAclaracion } = useParams();
    const token = getAccessTokenApi();
    let history = useHistory();

    const [form] = Form.useForm();

    useEffect(() => {
        // console.log(idAclaracion, 'idAclaracion');
        // setClarificationsTable(response.result);
        form.setFieldsValue({
            reference: idAclaracion,
        });
    }, [idAclaracion]);

    const sendClarification = async (values) => {
        // console.log(values);
        setLoadingForm(true);
        let data = values;
        data['status'] = true;
        data['source'] = 'PaymentComplement';
        data['portalType'] = 1;

        const response = await addClarificationApi(token, data);
        // console.log(response, 'response');
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            notification['success']({
                message: 'Aclaración agregada con éxito',
            });
            history.goBack();
            setLoadingForm(false);
            return;
        }
        if (response.statusCode === 400) {
            notification['error']({
                message: response.description,
            });
            setLoadingForm(false);
            return;
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="addClarification"
                layout="vertical"
                form={form}
                onFinish={sendClarification}
            >
                <Row>
                    <Col xs={16} lg={6}>
                        <Form.Item
                            label="Referencia o factura"
                            name="reference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese referencia o factura',
                                },
                            ]}
                        >
                            <Input placeholder="4232" disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={8} lg={6}>
                        <Row>
                            <Col lg={2} xs={6}>
                                <div id="circulo"></div>
                            </Col>
                            <Col lg={2} xs={6}>
                                <p>Abierto</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={10} lg={12} xs={24}>
                        <Form.Item
                            label="Summary/Titulo de la Aclaración"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un Summary/Titulo de la Aclaración no mayor a 70 caracteres',
                                    max: 70,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={10} lg={12} xs={24}>
                        <Form.Item
                            label="Descripción"
                            name="Description"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese una descripción',
                                },
                            ]}
                        >
                            <TextArea
                                placeholder="Ingrese su descripción máximo 300 caracteres"
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row xs={24}>
                    <Col xs={12}>
                        <Form.Item
                            label="Referencia o factura"
                            name="reference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese referencia o factura',
                                },
                            ]}
                        >
                            <Input placeholder="4232" disabled />
                        </Form.Item>
                    </Col>
                    <Row
                        xs={2}
                        style={{
                            marginLeft: 0,
                        }}
                    >
                        <div id="circulo"></div>
                        <p>Abierto</p>
                    </Row>
                </Row>
                <Col xs={21}>
                    <Form.Item
                        label="Descripción"
                        name="Description"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese una descripción',
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="Ingrese su descripción máximo 150 caracteres"
                            maxLength={150}
                        />
                    </Form.Item>
                </Col> */}

                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                className="btn-enviar"
                                block
                            >
                                Enviar
                            </Button>
                        </Form.Item>

                        {/* <Button className="btn-cancelar">Cancelar</Button> */}
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
}
