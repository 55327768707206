import React, { useEffect, useState } from 'react';
import { Row, Col, Layout, Card, Calendar, Tag, notification } from 'antd';
import { useHistory } from 'react-router';
import ChartAmount from '../../components/PortalClients/ChartAmount';
import TableHome from '../../components/Admin/TableHome/TableHome';
import GreetingsTop from '../../components/Admin/GreetingsTop/GreetingsTop';
import moment from 'moment';
import { getAccessTokenApi, logout } from '../../api/auth';
import {
    getBalanceSummaryApi,
    getInvoiceNoticesApi,
} from '../../api/finantial';
import { formatMoney } from '../../utils/general';
import useStatusFilter from '../../hooks/useStatusFilter';
import {
    getOperationNoticesApi,
} from '../../api/operations';
import useUser from '../../hooks/useUser';
import { ROL_CUSTOMER, ROL_FINANCIAL } from '../../utils/constants';

import './DashboardCustomer.scss';

const DashboardCustomer = () => {
    const [balanceSummary, setBalanceSumary] = useState([]);
    // const [paymentComplement, setPaymentComplement] = useState([]);
    const [notices, setNotices] = useState([]);
    const [operationNotices, setOperationsNotices] = useState([]);
    const token = getAccessTokenApi();
    const { person } = useUser();
    const [loadindSaldos, setLoadindSaldos] = useState(true);

    useEffect(() => {
        // if (reloadUsers) {
        setLoadindSaldos(true);
        const date = [moment().startOf('month'), moment().endOf('month')]; 
        let dateSent = {
            startDate: moment
                .utc(date[0].hour(0).minute(0))
                .format('YYYY-MM-DD HH:mm'),
            endDate: moment
                .utc(date[1].hour(23).minute(59))
                .format('YYYY-MM-DD HH:mm'),
        };
        const filters = `?StartDate=${dateSent.startDate}&EndDate=${dateSent.endDate}`;
        
        getBalanceSummaryApi(token, filters).then((response) => {
            // console.log(response, 'BALANCE summary');
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    const result = response.result[0] ? response.result[0] : {};
                    setBalanceSumary(result);
                }
            }
            setLoadindSaldos(false);

        });
        getInvoiceNoticesApi(token).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    const result = response.result.slice(0,5);
                    setNotices(result);
                }
            }
        });

        getOperationNoticesApi(token).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    const result = response.result.slice(0,5);
                    setOperationsNotices(result);
                }
            }
        });
    }, []);

    function onPanelChange(value, mode) {
        console.log(value, mode);
    }

    function onDateChangeCalendar(date) {
        console.log(date);
    }
    return (
        <Layout className="admin">
            <GreetingsTop />

            <div className="admin__container">
                {[ROL_CUSTOMER, ROL_FINANCIAL].some((r) => person.roles.includes(r)) && (
                    <CardAmount balanceSummary={balanceSummary} loadindSaldos={loadindSaldos} />
                )}

                <ChartAmount />
            </div>
            <Row className="admin__row">
                <Col md={24} lg={12} xxl={12}>
                    <div className="admin__container">
                        <TableHome
                            title="AVISOS | OPERACIONES"
                            type="operaciones"
                            data={operationNotices}
                        />
                    </div>
                </Col>
                <Col md={24} lg={12} xxl={12}>
                    <div className="admin__container">
                        <TableHome
                            title="AVISOS | CUENTAS"
                            type="cuentas"
                            data={notices}
                        />
                    </div>
                </Col>
                {/* <Col md={24} lg={8} xxl={8} style={{ textAlign: 'right' }}>
                    <div className="admin__container">
                        <div className="site-calendar-demo-card">
                            <Calendar
                                fullscreen={false}
                                onPanelChange={onPanelChange}
                                onChange={onDateChangeCalendar}
                            />
                        </div>
                    </div>
                </Col> */}
            </Row>
        </Layout>
    );
};

export default DashboardCustomer;

function CardAmount({ balanceSummary, loadindSaldos }) {
    const { setStatusFilter } = useStatusFilter();
    let history = useHistory();

    const gotoAccountBalance = (status) => {
        if(!loadindSaldos){
            const data = {
                type: status,
                dateStart: balanceSummary.startDate ? moment.utc(balanceSummary.startDate).local().format('YYYY-MM-DD') : "",
                dateEnd: balanceSummary.endDate ? moment.utc(balanceSummary.endDate).local().format('YYYY-MM-DD') : ""
            }
            setStatusFilter(data);
            history.push('/estado/cuentas');
        }
    };
    return (
        <>
            {/* <Row style={{ justifyContent: 'center', marginBottom: 20 }}>
                <Tag color={'green'}>Crédito activo</Tag>
            </Row> */}
            <Row className="card-amount ">
                <Col md={24} xs={24} sm={24} lg={8}>
                    <Card
                        className="animate__animated  animate__zoomIn"
                        onClick={() => {
                            // gotoAccountBalance('Pagado');
                            gotoAccountBalance('Todos');
                        }}
                    >
                        <Row>
                            <Col xs={6}>
                                <div className="card-amount__circule green ">
                                    <span>$</span>
                                </div>
                            </Col>
                            <Col xs={18}>
                                <p className="card-amount__price green">
                                    ${' '}
                                    {formatMoney(balanceSummary?.totalBalance)}{' '}
                                    {balanceSummary?.currencyId}
                                </p>
                                <p className="card-amount__pays">Saldo Total</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col md={24} xs={24} sm={24} lg={8}>
                    <Card
                        onClick={() => {
                            gotoAccountBalance('Vigente');
                        }}
                        className="animate__animated  animate__zoomIn"
                    >
                        <Row>
                            <Col xs={6}>
                                <div className="card-amount__circule orange">
                                    <span>$</span>
                                </div>
                            </Col>
                            <Col xs={18}>
                                <p className="card-amount__price orange">
                                    ${' '}
                                    {formatMoney(
                                        balanceSummary?.currentBalance
                                    )}{' '}
                                    {balanceSummary?.currencyId}
                                </p>
                                <p className="card-amount__pays">
                                    Saldo Vigente
                                </p>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col md={24} xs={24} sm={24} lg={8}>
                    <Card
                        onClick={() => {
                            gotoAccountBalance('Vencido');
                        }}
                        className="animate__animated  animate__zoomIn"
                    >
                        <Row>
                            <Col xs={6}>
                                <div className="card-amount__circule red">
                                    <span>$</span>
                                </div>
                            </Col>
                            <Col xs={18}>
                                <p className="card-amount__price red">
                                    $ {formatMoney(balanceSummary?.dueBalance)}{' '}
                                    {balanceSummary?.currencyId}
                                </p>
                                <p className="card-amount__pays">
                                    Saldo Vencido
                                </p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
