/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Select,
  Checkbox,
  Divider,
  InputNumber,
  Spin,
  Alert,
  Tag,
  Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  UserSwitchOutlined,
  DeleteOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {
  ADUANA_VALUE_ID,
  TERRESTRE_VALUE_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  HAZMAT_VALUE_ID,
  SERVICES_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  MEASUREMENT_UNIT_CATALOG_ID,
  FRECUENCY_CATALOG_ID,
  PESO_VALUE_ID,
  PIEZAS_VALUE_ID,
  DIMENSIONES_VALUE_ID,
  FLETE_VALUE_ID,
  CURRENCY_CATALOG_ID,
} from '../../../../../../helpers/constants';
import { getCatalogValueReducerHandle } from '../../../../../../helpers/handlerFunctionApi';
import { useReducer } from 'react';
import { fetchApi } from '../../../../../../helpers/fetch';
import { catalogQouteReducer } from '../../../../../../reducers/catalogsQoute/catalogQouteReducer';
import { getAccessTokenApi } from '../../../../../../api/auth';
import { METHOD_GET } from '../../../../../../utils/constants';
import {
  API_URL_CATALOG_SUPPLIER,
  API_URL_CATALOG_VALUES,
} from '../../../../../../helpers/urls';
import { types } from '../../../../../../types/types';
import DimensionsTable from '../../DimensionsTable/DimensionsTable';
import Rute from '../Rute/Rute';
import { formatMoney } from '../../../../../../utils/general';
const { Text } = Typography;
const initialStateQT = {
  typeOfLoad: [],
  operations: [],
  hazMat: [],
  typeOfService: [],
  typeOfEquipment: [],
  unit: [],
  measurementUnit: [],
  currency: [],
  packaging: [],
  frecuency: [],
};

const LineList = ({
  linea,
  disableAllInputs,
  showModal,
  linesForm,
  handleDeleteLine,
  pieces,
  setPieces,
  linesData,
  setLinesData,
  saveKey,
  onChangeFilterSupplier,
  setFilterSupplier,
  supplierSelectedLine,
  type,
  setSupplierSelected: setSupplierSelectedLine,
  handleSaveLine,
}) => {
  const [service, setService] = useState(TERRESTRE_VALUE_ID);
  const [serviceCatalag, setServiceCatalag] = useState([]);
  const [rfqChecked, setRfqChecked] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [catalogs, dispatch] = useReducer(catalogQouteReducer, initialStateQT);
  const [typeOfload, setTypeOfload] = useState(0);
  const token = getAccessTokenApi();
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalCBM, setTotalCbm] = useState(0);
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [unitCost, setUnitCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalSale, setTotalSale] = useState(linea.sale);
  const [totalProfit, setTotalProfit] = useState(0);
  const [unitPrice, setUnitPrice] = useState(linea.unitPrice);
  const [piecesTable, setPiecesTable] = useState({
    totalWeight: 0,
    totalCBM: 0,
    totalPieces: 0,
  });
  const [showAlertProfit, setShowAlertProfit] = useState(false);

  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (
      !values.unitType ||
      !values.destinyAddress ||
      !values.destinyCity ||
      !values.destinyCountry ||
      !values.destinyState ||
      !values.destinyZipCode ||
      // !values.frecuency ||
      !values.fullPrice ||
      // !values.hasRFQ ||
      // !values.isCompleted ||
      !values.lineNumber ||
      !values.loadType ||
      !values.measurementUnit ||
      !values.originAddress ||
      !values.originCity ||
      !values.originCountry ||
      !values.originState ||
      !values.originZipCode ||
      // !values.rate ||
      // !values.rateId ||
      !values.sale ||
      !values.service ||
      // !values.supplierId ||
      !values.typeOfService ||
      !values.unitPrice ||
      !values.unitType
    ) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la línea.');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  useEffect(() => {
    //  console.log(linea,"linea");
    let countWeight = 0;
    let countCbm = 0;
    let countPieces = 0;
    pieces?.forEach((element) => {
      countWeight += element.totalWeight;
      countCbm += element.totalCBM;
      countPieces += element.piecesNumber;
    });
    linesForm.setFieldsValue({
      totalWeight: countWeight,
      totalCBM: countCbm,
      totalPieces: countPieces,
    });
    setPiecesTable({
      totalWeight: countWeight,
      totalCBM: countCbm,
      totalPieces: countPieces,
    });
  }, [pieces, linea]);

  useEffect(() => {
    linesForm.setFieldsValue({ ...linea });
    setPieces(linea.pieces);
  }, [linea]);

  useEffect(() => {
    let totalCostLine = 0;
    let totalSaleLine = 0;
    let profiltLine = 0;

    if (linea.measurementUnit === PESO_VALUE_ID) {
      totalCostLine = linea.rateUnitPrice * piecesTable.totalWeight;
      totalSaleLine = unitPrice * piecesTable.totalWeight;
    }
    if (linea.measurementUnit === PIEZAS_VALUE_ID) {
      totalCostLine = linea.rateUnitPrice * piecesTable.totalPieces;
      totalSaleLine = unitPrice * piecesTable.totalPieces;
    }
    if (linea.measurementUnit === DIMENSIONES_VALUE_ID) {
      totalCostLine = linea.rateUnitPrice * piecesTable.totalCBM;
      totalSaleLine = unitPrice * piecesTable.totalCBM;
    }
    if (linea.measurementUnit === FLETE_VALUE_ID) {
      totalCostLine = linea.rateUnitPrice;
      totalSaleLine = unitPrice;
    }
    profiltLine = totalSaleLine - totalCostLine;
    setTotalCost(totalCostLine);
    setTotalSale(totalSaleLine);
    setTotalProfit(profiltLine);

    if (profiltLine < 0) {
      setShowAlertProfit(true);
    } else {
      setShowAlertProfit(false);
    }
    linesForm.setFieldsValue({
      fullPrice: totalCostLine,
      sale: totalSaleLine,
    });
  }, [linea, piecesTable, unitPrice, linesData]);

  useEffect(() => {
    const findLine = linesData[parseInt(saveKey)];
    if (findLine) {
      setSupplierSelected(findLine.supplierId);
      setTypeOfload(findLine.loadType);
      const filterSupplierLine = {
        unit: findLine.unitType,
        typeOfLoad: findLine.loadType,
        service: findLine.service,
        typeOfService: findLine.typeOfService,
        hazMat: findLine.hazMat ? findLine.hazMat : '',
      };
      setFilterSupplier(filterSupplierLine);
      setRfqChecked(findLine.hasRFQ ? true : false);
      setUnitCost(
        findLine.service === TERRESTRE_VALUE_ID
          ? findLine.rateUnitPrice
          : findLine.rateCost
      );
      setTotalCost(findLine.fullPrice);
      setTotalSale(findLine.sale);
      setTotalProfit(findLine.sale - findLine.fullPrice);
      // setSupplierSelectedLine({ supplierId: findLine.supplierId });
      // linesForm.setFieldsValue({ ...findLine });
      // setPieces(linea.pieces);
      linesForm.setFieldsValue({
        fullPrice: findLine.fullPrice,
        sale: findLine.sale,
      });
    }
  }, [saveKey]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${SERVICES_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setServiceCatalag(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    getCatalogValueReducerHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfLoad
    );
    getCatalogValueReducerHandle(
      HAZMAT_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getHazMat
    );
    getCatalogValueReducerHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getTypeOfService
    );
    getCatalogValueReducerHandle(
      UNIT_TYPE_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getUnity
    );
    getCatalogValueReducerHandle(
      MEASUREMENT_UNIT_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getMeasurementUnit
    );
    getCatalogValueReducerHandle(
      FRECUENCY_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getFrecuency
    );
    getCatalogValueReducerHandle(
      CURRENCY_CATALOG_ID,
      dispatch,
      setLoadingForm,
      0,
      types.getCurrency
    );
  }, []);

  function updateReducerCatalog(arrayCatalog, type) {
    if (arrayCatalog) {
      arrayCatalog.map((cat, index) => {
        cat['key'] = index;
        cat['label'] = cat.description;
        cat['value'] = cat.id;
        return cat;
      });
      if (type === 'service') {
        setServiceCatalag(arrayCatalog);
      } else {
        dispatch({ type: type, payload: arrayCatalog });
      }
    }
  }

  useEffect(() => {
    let ignore = false;
    const getCatalogsSupplier = () => {
      setLoadingCatalog(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_CATALOG_SUPPLIER}/${supplierSelected}`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response) {
            if (response.statusCode === 200) {
              const catalogSupplierResult = response.result;

              const loadTypeArray = catalogSupplierResult.loadType;
              const hazMatArray = catalogSupplierResult.hazMat;
              const operationsArray = catalogSupplierResult.operations;
              const typeOfServicesArray = catalogSupplierResult.typeOfServices;
              const unitTypeArray = catalogSupplierResult.unitType;
              const servicesArray = catalogSupplierResult.services;

              //Update catalog
              if (!ignore) {
                updateReducerCatalog(loadTypeArray, types.getTypeOfLoad);

                updateReducerCatalog(hazMatArray, types.getHazMat);
                updateReducerCatalog(operationsArray, types.getOperations);
                updateReducerCatalog(
                  typeOfServicesArray,
                  types.getTypeOfService
                );
                updateReducerCatalog(unitTypeArray, types.getUnity);
                updateReducerCatalog(unitTypeArray, types.getEquipmentType);

                updateReducerCatalog(servicesArray, 'service');
              }
            }
            if (!ignore) setLoadingCatalog(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (supplierSelected) {
      // setShowAllValues(false);
      getCatalogsSupplier();
    }
    return () => {
      ignore = true;
    };
  }, [supplierSelected, token]);

  useEffect(() => {
    // getRatesSupplier();
    if (supplierSelected) {
      linesForm.setFieldsValue({
        supplierId: supplierSelected,
      });
    } else {
      linesForm.setFieldsValue({
        supplierId: null,
        // contactId: null,
        // rateId: null,
        // rate: null,
      });
    }
  }, [supplierSelected]);

  return (
    <Form
      name='edit-line'
      layout='vertical'
      form={linesForm}
      onFinish={handleSaveLine}
      onFinishFailed={errorFields}
      // scrollToFirstError
    >
      <>
        <Spin
          spinning={loadingCatalog || loadingForm}
          className='line-list-form'
        >
          <Row>
            <Col md={24}>
              {/* ROW4 */}
              <Row>
                <Col xs={16} md={4}>
                  <Form.Item
                    label='Número de línea:'
                    name='lineNumber'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese número de línea',
                      },
                    ]}
                  >
                    <InputNumber placeholder='1' disabled />
                  </Form.Item>
                </Col>
                {service !== ADUANA_VALUE_ID && (
                  <Col xs={4} md={4}>
                    <Form.Item name='hasRFQ' valuePropName='checked'>
                      <Checkbox
                        onChange={(e) => setRfqChecked(e.target.checked)}
                        disabled={disableAllInputs}
                        checked={rfqChecked}
                      >
                        RFQ:
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} md={10} flex='end'>
                  <Button
                    style={{ marginLeft: 0, marginBottom: 10 }}
                    className='btn-delete'
                    icon={<DeleteOutlined />}
                    disabled={disableAllInputs}
                    onClick={() => handleDeleteLine(linea.lineNumber)}
                  >
                    Eliminar linea {linea.LineNumber}
                  </Button>
                </Col>
              </Row>
              {/* ROW5 */}
              <Row gutter={16}>
                <Col xxl={6} xl={8} lg={12} md={12} xs={24}>
                  <Form.Item
                    label='Tipo de carga:'
                    name='loadType'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese tipo de carga',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Selecciona una opción'
                      disabled={true}
                      // onChange={(value) => setTypeOfload(value)}
                      onChange={(value) => {
                        setTypeOfload(value);
                        onChangeFilterSupplier(value, 'typeOfLoad');
                      }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {catalogs.typeOfLoad.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xxl={6}
                  xl={typeOfload === HAZMAT_VALUE_ID ? 6 : 8}
                  lg={12}
                  md={12}
                  xs={24}
                >
                  <Form.Item
                    label='Frecuencia:'
                    name='frecuency'
                    rules={[
                      {
                        required: false,
                        message: 'Por favor ingrese frecuencia',
                      },
                    ]}
                  >
                    <Select
                      placeholder='Selecciona una opción'
                      showSearch
                      disabled={disableAllInputs}
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {catalogs.frecuency.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {typeOfload === HAZMAT_VALUE_ID && (
                  <Col xl={10} lg={12} md={12} xs={24}>
                    <Row gutter={16}>
                      <Col xs={24} md={16}>
                        <Form.Item
                          label='HAZ MAT:'
                          name='hazMat'
                          rules={[
                            {
                              required: false,
                              message: 'Por favor ingrese HAZ MAT',
                            },
                          ]}
                        >
                          <Select
                            disabled={typeOfload !== HAZMAT_VALUE_ID || true}
                            placeholder=''
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value) => {
                              onChangeFilterSupplier(value, 'hazMat');
                            }}
                          >
                            {catalogs.hazMat.map((s) => (
                              <Select.Option key={s.value} value={s.value}>
                                {s.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          name='hazMatDescription'
                          label='Descripción:'
                          rules={[
                            {
                              required: false,
                              message: 'Por favor ingrese HAZ MAT',
                            },
                          ]}
                        >
                          <Input disabled={typeOfload !== HAZMAT_VALUE_ID} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              {/* ROW6 */}

              <Row gutter={16}>
                <Col md={8} xs={24}>
                  <Form.Item
                    label='Servicio:'
                    name='service'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese servicio',
                      },
                    ]}
                  >
                    <Select
                      disabled={true}
                      placeholder='Selecciona una opción'
                      onChange={(value) => {
                        setService(value);
                        onChangeFilterSupplier(value, 'service');
                      }}
                    >
                      {serviceCatalag.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {service !== ADUANA_VALUE_ID && (
                  <Col md={8} xs={24}>
                    <Form.Item
                      label='Tipo de servicio:'
                      name='typeOfService'
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un tipo de servicio',
                        },
                      ]}
                    >
                      <Select
                        placeholder='Selecciona una opción'
                        showSearch
                        disabled={true}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          onChangeFilterSupplier(value, 'typeOfService');
                        }}
                      >
                        {catalogs.typeOfService.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col md={8} xs={24}>
                  <Form.Item
                    label='Unidad:'
                    name='unitType'
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese unidad',
                      },
                    ]}
                  >
                    <Select
                      disabled={true}
                      placeholder='Selecciona una opción'
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        onChangeFilterSupplier(value, 'unit');
                      }}
                    >
                      {catalogs.unit.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {service === ADUANA_VALUE_ID && (
                  <Col md={8} xs={24}>
                    <Row>
                      <Form.Item
                        label='Aduana:'
                        name='type'
                        rules={[
                          {
                            required: false,
                            message: 'Por favor ingrese aduana',
                          },
                        ]}
                      >
                        <Select
                          placeholder='Selecciona una opción'
                          disabled={disableAllInputs}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                )}
              </Row>

              {service === ADUANA_VALUE_ID && (
                <Row gutter={16}>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label='Patente:'
                      name='patente'
                      rules={[
                        {
                          required: false,
                          message: 'Por favor ingrese patente',
                        },
                      ]}
                    >
                      <Select disabled={disableAllInputs}></Select>
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Row>
                      <Form.Item
                        label='Agente aduanal:'
                        name='type'
                        rules={[
                          {
                            required: false,
                            message: 'Por favor ingrese agente aduanal',
                          },
                        ]}
                      >
                        <Input
                          placeholder='Ingrese información'
                          disabled={disableAllInputs}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label='Pedimento:'
                      name='unit'
                      rules={[
                        {
                          required: false,
                          message: 'Por favor ingrese pedimento',
                        },
                      ]}
                    >
                      <Select
                        placeholder='Selecciona una opción'
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {/* RUTA */}
              <Rute
                disableAllInputs={disableAllInputs}
                service={service}
                setLoadingForm={setLoadingForm}
                form={linesForm}
                type='show'
              />

              {service !== ADUANA_VALUE_ID && rfqChecked === false && (
                <>
                  <Divider>Proveedor</Divider>
                  <Row gutter={16}>
                    <Col md={3} xs={24}>
                      <Button
                        style={{
                          marginBottom: 5,
                        }}
                        type='primary'
                        icon={<UserSwitchOutlined />}
                        disabled={true}
                        onClick={() => showModal('Suppliers')}
                      >
                        Proveedor
                      </Button>
                    </Col>
                    <Col md={9} xs={24}>
                      <Form.Item name='supplierId'>
                        <Input placeholder='Ingrese información' disabled />
                      </Form.Item>
                    </Col>

                    <Col md={3} xs={24}>
                      <Button
                        style={{
                          marginBottom: 5,
                        }}
                        type='primary'
                        disabled={true}
                        icon={<DollarOutlined />}
                        onClick={() => showModal('Rate')}
                      >
                        Tarifa
                      </Button>
                    </Col>
                    <Col md={9} xs={24}>
                      <Form.Item name='rateIdStr'>
                        <Input placeholder='Ingrese información' disabled />
                      </Form.Item>
                      <Form.Item name='rateId' hidden>
                        <Input placeholder='Ingrese información' disabled />
                      </Form.Item>
                      <Form.Item name='sale' hidden>
                        <Input placeholder='Ingrese información' disabled />
                      </Form.Item>
                      <Form.Item name='fullPrice' hidden>
                        <Input placeholder='Ingrese información' disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Divider>Peso y dimensiones</Divider>

              <DimensionsTable
                disableAllInputs={disableAllInputs}
                pieces={pieces}
                setPieces={setPieces}
                linesData={linesData}
                setLinesData={setLinesData}
                saveKey={saveKey}
                setTotalWeight={setTotalWeight}
                setTotalCbm={setTotalCbm}
                linea={linea}
              ></DimensionsTable>

              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col md={8} xs={24}>
                  <Form.Item label='Piezas totales:' name='totalPieces'>
                    <InputNumber style={{ width: '100%' }} disabled={true} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item
                    label='Peso total:'
                    name='totalWeight'
                    initialValue={totalWeight}
                  >
                    <InputNumber
                      value={totalWeight}
                      style={{ width: '100%' }}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item
                    label='CBM total:'
                    name='totalCBM'
                    initialValue={totalWeight}
                  >
                    <InputNumber
                      value={totalCBM}
                      style={{ width: '100%' }}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {!rfqChecked && (
                <Row gutter={16} justify='center'>
                  <Divider>Información de la tarifa</Divider>
                  <Col xs={12} md={8}>
                    <Form.Item
                      label='Unidad de medida:'
                      name='measurementUnit'
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese unidad de medida',
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder='unidad de medida'
                        // style={{ width: 150 }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.measurementUnit.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={8}>
                    <Form.Item
                      label='Moneda:'
                      name='currency'
                      rules={[
                        {
                          required: !rfqChecked,
                          message: 'Por favor ingrese una moneda',
                        },
                      ]}
                    >
                      <Select
                        disabled
                        placeholder='Moneda'
                        // style={{ width: 150 }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {catalogs.currency.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <p style={{ marginBottom: 7 }}>Costo unitario:</p>
                    <Tag style={{ padding: 5, width: '100%' }}>
                      <Text className='text-label' style={{ color: 'gray' }}>
                        ${formatMoney(unitCost)}
                      </Text>
                    </Tag>
                  </Col>
                </Row>
              )}

              <Divider></Divider>
              {!rfqChecked && (
                <Row gutter={16}>
                  <Col xs={24} md={12} lg={6}>
                    <Form.Item
                      label='Precio unitario:'
                      name='unitPrice'
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un costo',
                          type: 'number',
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={disableAllInputs}
                        placeholder='Ingresa un costo'
                        style={{ width: '100%' }}
                        min={0}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => setUnitPrice(value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={6} style={{ marginBottom: 10 }}>
                    <p style={{ marginBottom: 7 }}>Costo total:</p>
                    <Tag color='blue' style={{ padding: 5, width: '100%' }}>
                      <Text className='text-label'>
                        ${formatMoney(totalCost)}
                      </Text>
                    </Tag>
                    <Form.Item
                      label='fullPrice'
                      name='fullPrice'
                      hidden
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={6} style={{ marginBottom: 10 }}>
                    <p style={{ marginBottom: 7 }}>Venta:</p>
                    <Tag color='orange' style={{ padding: 5, width: '100%' }}>
                      <Text className='text-label'>
                        ${formatMoney(totalSale)}
                      </Text>
                    </Tag>
                    <Form.Item
                      label='sale'
                      name='sale'
                      hidden
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12} lg={6} style={{ marginBottom: 10 }}>
                    <p style={{ marginBottom: 7 }}>Profit:</p>
                    <Tag
                      color={totalProfit < 0 ? 'red' : 'green'}
                      style={{ padding: 5, width: '100%' }}
                    >
                      <Text className='text-label'>
                        ${formatMoney(totalProfit)}
                      </Text>
                    </Tag>
                  </Col>
                </Row>
              )}
              <Row
                gutter={16}
                style={{
                  justifyContent: 'space-between',
                  marginBottom: 20,
                }}
              >
                <Col md={14} xs={24} style={{ marginBottom: -20 }}>
                  <Form.Item label='comentarios:' name='comments'>
                    <TextArea
                      placeholder='Ingrese información'
                      disabled={disableAllInputs}
                    />
                  </Form.Item>
                </Col>
                {showAlertProfit && (
                  <Col xs={24} md={10} style={{ marginTop: 27, marginBottom:20}}>
                    <Alert
                      message='No esta obteniendo una ganancia en esta línea'
                      type='warning'
                      showIcon
                      closable
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Spin>
        {showAlert && (
          <Alert
            message={textType}
            description={textDescription}
            type='warning'
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}
      </>
    </Form>
  );
};

export default LineList;
