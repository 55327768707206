/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Form, Select, Col, Input, Divider } from 'antd';
import { METHOD_GET } from '../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  TERRESTRE_VALUE_ID,
  MARITIMO_VALUE_ID,
  AEREO_VALUE_ID,
  PORT_CATALOG_ID,
  CANADA_VALUE_ID,
} from '../../../../helpers/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import useWindowDimensions from '../../../../hooks/useWindowdimensions';

const RuteTab = (props) => {
  const { disableAllInputs, service, type, setLoadingForm, form , isRfq} = props;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [ports, setPorts] = useState([]);
  // const [airports, setAirports] = useState([]);
  const [countriesDestiny, setCountriesDestiny] = useState([]);
  const [statesDestiny, setStatesDestiny] = useState([]);
  const [citiesDestiny, setCitiesDestiny] = useState([]);
  const [portsDestiny, setPortsDestiny] = useState([]);
  // const [airportsDestiny, setAirportsDestiny] = useState([]);
  const [countrySelected, setCountrySelected] = useState(0);
  const [countrySelectedDestiny, setCountrySelectedDestiny] = useState(0);
  const suppliers = [{ value: '123', label: 'op123' }];
  const token = getAccessTokenApi();
  const { width } = useWindowDimensions();

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCountries(calogsValues);
          setCountriesDestiny(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    if (type) {
      getCatalogValueHandle(
        STATE_CATALOG_ID,
        setStates,
        setLoadingForm,
        form.getFieldValue('OriginCountry')
      );
      getCatalogValueHandle(
        STATE_CATALOG_ID,
        setStatesDestiny,
        setLoadingForm,
        form.getFieldValue('DestinyCountry')
      );
      getCatalogValueHandle(
        CITY_CATALOG_ID,
        setCities,
        setLoadingForm,
        form.getFieldValue('OriginState')
      );
      getCatalogValueHandle(
        CITY_CATALOG_ID,
        setCitiesDestiny,
        setLoadingForm,
        form.getFieldValue('DestinyState')
      );
      getCatalogValueHandle(
        PORT_CATALOG_ID,
        setPorts,
        setLoadingForm,
        form.getFieldValue('OriginPort')
      );
      getCatalogValueHandle(
        PORT_CATALOG_ID,
        setPortsDestiny,
        setLoadingForm,
        form.getFieldValue('DestinyPort')
      );
    }

    //   getStateCatalogHandle();
  }, []);

  // const getCatalogValueHandle = (catalogId, setState, relationalId) => {
  // 	setLoadingForm(true);
  // 	const config = {
  // 		method: METHOD_GET,
  // 		url: `${API_URL_CATALOG_VALUES}/GetAll/${catalogId}/${relationalId}`,
  // 		data: null,
  // 		token,
  // 		// showNotification: true
  //   }
  //   fetchApi(config).then(response =>{
  // 		if(response){
  // 			 let calogsValues = response.result;

  // 			 if(calogsValues){
  // 				  calogsValues.map((cat, index)=> {
  // 						cat["key"] = index;
  // 						cat["label"] = cat.description;
  // 						cat["value"] = cat.id;
  // 				  })
  // 			 }
  // 			 setState(calogsValues);
  // 			//  setStates(calogsValues);
  // 		}
  // 		setLoadingForm(false);
  //   }
  //   ).catch(err => {
  // 		console.log(err);
  //   })
  // }

  // const getStateCatalogHandle = () => {
  // 	getCatalogValueHandle( STATE_CATALOG_ID, setStates);
  // }

  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        OriginZipCode: '',
        OriginState: '',
        OriginCity: '',
        OriginAddress: '',
        OriginPort: '',
        OriginAirport: '',
        // DestinyZipCode: '',
        // DestinyState: '',
        // DestinyCity: '',
        // DestinyAddress: '',
        // DestinyAirport: '',
        // DestinyPort: '',
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
  };

  const onChangeCountryDestiny = (value) => {
    if (value !== countrySelectedDestiny) {
      form.setFieldsValue({
        DestinyZipCode: '',
        DestinyState: '',
        DestinyCity: '',
        DestinyAddress: '',
        DestinyAirport: '',
        DestinyPort: '',
      });
    }
    setCountrySelectedDestiny(value);
    getCatalogValueHandle(
      STATE_CATALOG_ID,
      setStatesDestiny,
      setLoadingForm,
      value
    );
  };

  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    form.setFieldsValue({
      OriginZipCode: '',
      OriginAddress: '',
      OriginCity: '',
      OriginPort: '',
      OriginAirport: '',
  });
  };

  const onChangeStateDestiny = (value) => {
    getCatalogValueHandle(
      CITY_CATALOG_ID,
      setCitiesDestiny,
      setLoadingForm,
      value
    );
    form.setFieldsValue({
      DestinyZipCode: '',
      DestinyCity: '',
      DestinyAirport: '',
      DestinyPort: '',
      DestinyAddress: '',
    });
  };

  return (
    <div className='rute-tab'>
      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Origen</Divider>

      {/*{service === TERRESTRE_VALUE_ID && <Row gutter={[10,0]}>
					<Col xs={24} md={12} lg={8}>
						<Form.Item
							label="Código postal Origen:"
							name="cpInput"
							hasFeedback
							rules={[
								{
									required: true,
									// len:5,
									type:'integer',
									message:
									'Por favor ingrese un código postal',
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										console.log(value);
											if (
												value &&
												value.toString().length === 5
											) {
											setValidatedCPOrigin(true);
												return Promise.resolve();
											}
											setValidatedCPOrigin(false);

											return Promise.reject(
												'Por favor ingrese un código postal válido'
											);
									},
								}),
							]}
							>
							<InputNumber disabled={disableAllInputs} maxLength={5} minLength={5}  placeholder=" Código postal" style={{width: "100%"}} />
						</Form.Item>
					</Col>
					<Col xs={24} md={6} lg={12}>
						<Button type='primary' disabled={!validatedCPOrigin}>Buscar</Button>
					</Col>
				</Row>} */}

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='País:'
            name='OriginCountry'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRfq}
              // disabled={disableAllInputs || service === TERRESTRE_VALUE_ID}
              placeholder='Selecciona una opción'
              onChange={onChangeCountryOrigin}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                minWidth: width > 480 ? 220 : '100%',
                width: width > 480 ? 220 : '100%',
              }}
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Estado:'
            name='OriginState'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRfq}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChangeState}
              style={{
                minWidth: width > 480 ? 220 : '100%',
                width: width > 480 ? 220 : '100%',
              }}
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Ciudad:'
            name='OriginCity'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRfq}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                minWidth: width > 480 ? 220 : '100%',
                width: width > 480 ? 220 : '100%',
              }}
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Código postal:'
              name='OriginZipCode'
              rules={[
                {
                  required: true,
                  // len:5,
                  // type: 'integer',
                  message: 'Por favor ingrese un código postal',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    // console.log(getFieldValue('OriginCountry'));
                    // console.log(value);
                    // console.log(isNaN(value));
                    if (getFieldValue('OriginCountry') === CANADA_VALUE_ID) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value &&
                        value.toString().length === 5 &&
                        !isNaN(value)
                      ) {
                        // setValidatedCPOrigin(true);
                        return Promise.resolve();
                      }
                    }

                    // setValidatedCPOrigin(false);

                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              {/* <InputNumber
                disabled={disableAllInputs}
                maxLength={5}
                minLength={5}
                placeholder=' Código postal'
                style={{ minWidth: 150, width: 150 }}
              /> */}
              <Input
                disabled={disableAllInputs || isRfq}
                style={{ minWidth: 150, width: 150 }}
              />
            </Form.Item>
          </Col>
        )}
        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Puerto:'
              name='OriginPort'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {ports.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Aeropuerto:'
              name='OriginAirport'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {suppliers.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={12} lg={24}>
            <Form.Item
              label='Dirección:'
              name='OriginAddress'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs || isRfq} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Destino</Divider>

      {/* {service === TERRESTRE_VALUE_ID && <Row gutter={[10,0]}>
						<Col xs={24} md={12} lg={8}>
							<Form.Item
								label="Código postal Destino:"
								name="cpOutput"
								hasFeedback
								rules={[
									{
										required: true,
										// len:5,
										type:'integer',
										message:
										'Por favor ingrese un código postal',
									},
									({ getFieldValue }) => ({
										validator(rule, value) {
											console.log(value);
											 if (
												  value &&
												  value.toString().length === 5
											 ) {
												setValidatedCPDestination(true);
												  return Promise.resolve();
											 }
											 setValidatedCPDestination(false);

											 return Promise.reject(
												  'Por favor ingrese un código postal válido'
											 );
										},
								  }),
								]}
								>
								<InputNumber disabled={disableAllInputs} maxLength={5} minLength={5}  placeholder=" Código postal" style={{width: "100%"}} />
							</Form.Item>
						</Col>
						<Col xs={24} md={6} lg={12}>
							<Button type='primary' disabled={!validatedCPDestination}>Buscar</Button>
						</Col>
					</Row>} */}

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='País:'
            name='DestinyCountry'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRfq}
              placeholder='Selecciona una opción'
              onChange={onChangeCountryDestiny}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                minWidth: width > 480 ? 220 : '100%',
                width: width > 480 ? 220 : '100%',
              }}
            >
              {countriesDestiny.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Estado:'
            name='DestinyState'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRfq}
              // disabled={disableAllInputs || service === TERRESTRE_VALUE_ID}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChangeStateDestiny}
              style={{
                minWidth: width > 480 ? 220 : '100%',
                width: width > 480 ? 220 : '100%',
              }}
            >
              {statesDestiny.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Ciudad:'
            name='DestinyCity'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs || isRfq}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{
                minWidth: width > 480 ? 220 : '100%',
                width: width > 480 ? 220 : '100%',
              }}
            >
              {citiesDestiny.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Código postal:'
              name='DestinyZipCode'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un código postal',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('DestinyCountry') === CANADA_VALUE_ID) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value &&
                        value.toString().length === 5 &&
                        !isNaN(value)
                      ) {
                        // setValidatedCPOrigin(true);
                        return Promise.resolve();
                      }
                    }

                    // setValidatedCPOrigin(false);

                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                disabled={disableAllInputs || isRfq}
                style={{ minWidth: 150, width: 150 }}
              />
            </Form.Item>
          </Col>
        )}

        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Puerto:'
              name='DestinyPort'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {portsDestiny.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Aeropuerto:'
              name='DestinyAirport'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {suppliers.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={12} lg={24}>
            <Form.Item
              label='Dirección:'
              name='DestinyAddress'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs || isRfq} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RuteTab;
