import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Select,
  Checkbox,
  Tabs,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { registerClientApi } from '../../../../../api/clients';
import { getCompanyIdApi } from '../../../../../api/company';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { getUsersApi } from '../../../../../api/users';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
} from '../../../../../helpers/constants';
import { fetchApi } from '../../../../../helpers/fetch';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import Location from './Location/Location';
import Catalog from './Catalog/Catalog';

const AddClientForm = ({ setModalIsVisible, setReloadClients }) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const companyId = getCompanyIdApi();
  const [financiales, setFinanciales] = useState([]);
  const [operadores, setOperadores] = useState([]);
  const token = getAccessTokenApi();
  const [countrySelected, setCountrySelected] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { TabPane } = Tabs;

  useEffect(() => {
    if (loadingForm) {
      getUsersApi(token)
        .then((response) => {
          if (response) {
            let financials = [];
            let operaciones = [];
            response.result.forEach((element, index) => {
              if (element.roles.includes('Financial')) {
                financials.push(element);
              }
            });
            response.result.forEach((element, index) => {
              if (element.roles.includes('Operaciones')) {
                operaciones.push(element);
              }
            });
            let supp = financials.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            setFinanciales(supp);
            let opp = operaciones.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            setOperadores(opp);
          }
          setLoadingForm(false);
        })
        .catch((err) => {});
    }
  }, [token, loadingForm]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        state: '',
        city: '',
        address: '',
        zipCode: '',
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
  };
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
  };
  const addClientFunction = async (values) => {
    // console.log('hey');
    setLoadingForm(true);
    let data = values;
    data['parentCustomerId'] = '';
    data['level'] = 0;
    data['company'] = companyId;
    const response = await registerClientApi(token, data);

    if (response === undefined) {
      notification['error']({
        message: 'Ocurrió un error, Inténtelomas tarde',
      });
      return false;
    }
    if (response.statusCode === 401) {
      notification['error']({
        message: 'Usuario no autorizado.',
      });
      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1500);
      return false;
    }
    if (response.statusCode === 200) {
      setModalIsVisible(false);
      setReloadClients(true);
      notification['success']({
        message: 'Cliente agregado con éxito',
      });
    } else {
      let messageError = 'Ocurrió un error, Inténtelootra vez';
      if (response.statusCode === 409) {
        messageError = response.description;
      }
      if (response.statusCode === 400) {
        messageError = response.description;
      }
      notification['error']({
        message: messageError,
      });
    }
    setLoadingForm(false);
  };

  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (!values.country || !values.city || !values.state || !values.address) {
      notification['error']({
        message: 'La dirección del cliente es requerida.',
        description: 'Diríjase a la pestaña de Dirección',
      });
    }
  };

  return (
    <Spin spinning={loadingForm} tip='Cargando...'>
      <Form
        name='add-promo'
        layout='vertical'
        form={form}
        onFinish={addClientFunction}
        onFinishFailed={errorFields}
        initialValues={{ active: true }}
      >
        <div className='add-client-form'>
          <Tabs defaultActiveKey='1' type='card'>
            <TabPane tab='Detalle' key='infoBasic'>
              <BasicInfo financiales={financiales} operadores={operadores} />
            </TabPane>

            <TabPane tab='Dirección' key='location' forceRender>
              <Location
                onChangeCountryOrigin={onChangeCountryOrigin}
                countries={countries}
                onChangeState={onChangeState}
                states={states}
                cities={cities}
              />
            </TabPane>
            <TabPane tab='Cátalogos' key='catalog'>
              <Catalog />
            </TabPane>
          </Tabs>
          <Row>
            <Col span={24}>
              <Form.Item wrapperCol={{ offset: 9, span: 12 }}>
                <Button htmlType='submit' className='btn-enviar' block>
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddClientForm;

function BasicInfo({ financiales, operadores }) {
  return (
    <Row gutter={16}>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Nombre de cliente:'
          name='name'
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un nombre',
            },
          ]}
        >
          <Input placeholder='Ingresa tu información' />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Id de cliente:'
          name='id'
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un Id',
            },
          ]}
        >
          <Input placeholder='Ingresa tu información' />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Número de cliente:'
          name='customerNum'
          rules={[
            {
              required: false,
              message: 'Por favor ingrese un número',
            },
          ]}
        >
          <Input placeholder='Ingresa tu información' />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label='RFC:' name='fiscalID'>
          <Input placeholder='Ingresa tu información' />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Correo electrónico:'
          name='emailAddress'
          rules={[
            {
              required: false,
              message: 'Por favor ingrese un email',
            },
          ]}
        >
          <Input type={'email'} placeholder='Ingresa tu información' />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label={
            <span>
              Teléfono:&nbsp;
              <Tooltip title='El número telefónico debe contener 10 dígitos'>
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          name='phoneNumber'
          rules={[
            {
              required: false,
              message: 'Por favor ingresa número telefónico',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || value.toString().length === 10) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'Por favor ingresa número telefónico a 10 dígitos'
                );
              },
            }),
          ]}
        >
          <Input placeholder='Ingresa tu información' />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Usuario estatus:'
          name='active'
          valuePropName='checked'
        >
          <Checkbox>Activo</Checkbox>
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Financiero:'
          name='financialId'
          rules={[
            {
              message: 'Por favor seleccione un financiero',
            },
          ]}
        >
          <Select placeholder='Selecciona una opción' allowClear={true}>
            {financiales.map((f) => (
              <Select.Option key={f.value} value={f.value}>
                {f.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Operador:'
          name='operacionesId'
          rules={[
            {
              message: 'Por favor seleccione un operador',
            },
          ]}
        >
          <Select
            placeholder='Selecciona una opción 
                                '
            allowClear={true}
          >
            {operadores.map((o) => (
              <Select.Option key={o.value} value={o.value}>
                {o.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
