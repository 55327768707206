import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography, Button, Spin, Select } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Contact from '../../../../components/Admin/Configuration/Contact';
import { getAllSuppliers } from '../../../../api/supplier';
import { getAccessTokenApi } from '../../../../api/auth';

export default function ContactPage() {
    const [reloadSuppliers, setReloadSuppliers] = useState(true);
    const [suppliers, setSuppliers] = useState([]);
    const [supplierSelected, setSupplierSelected] = useState('');
    useEffect(() => {
        if (reloadSuppliers) {
            const token = getAccessTokenApi();
            getAllSuppliers(token).then((response) => {
                if (response) {
                    let arraySuppliers = response.result.map((s) => ({
                        value: s.supplierId,
                        label: s.name,
                        company: s.company,
                    }));
                    setSuppliers(arraySuppliers);
                }
                setReloadSuppliers(false);
            });
        }
    }, [reloadSuppliers]);
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <Spin spinning={reloadSuppliers} tip="Cargando...">
                <div className="billing__container">
                    <Select
                        style={{ width: 250, marginBottom: 20 }}
                        placeholder="Selecciona una cliente"
                        defaultValue={suppliers[0]}
                        defaultActiveFirstOption={true}
                        onChange={(e) => setSupplierSelected(e)}
                    >
                        {suppliers.map((c) => (
                            <Select.Option key={c.value} value={c.value}>
                                {c.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Contact idSupplier={supplierSelected} />
                </div>
            </Spin>
        </Layout>
    );
}

function HeaderTop() {
    let history = useHistory();
    const { Title } = Typography;
    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={23} className="header-top__left">
                    <Row>
                        <Col xs={20}>
                            <Title level={3}>Contacto</Title>
                        </Col>

                        <Col xs={2}>
                            <Button
                                type="link"
                                onClick={() => history.goBack()}
                            >
                                <ArrowLeftOutlined /> Atrás
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
