import React from 'react';
import { Col, Layout, Row, Typography, Button } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CompanyDataDetail from '../../../../components/Admin/Configuration/CompanyDataDetail';

export default function CompanyData() {
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <div className="billing__container">
                <CompanyDataDetail />
            </div>
        </Layout>
    );
}

function HeaderTop() {
    let history = useHistory();
    const { Title } = Typography;
    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={23} className="header-top__left">
                    <Row>
                        <Col xs={20}>
                            <Title level={3}>Datos de la compañia</Title>
                        </Col>

                        <Col xs={2}>
                            <Button
                                type="link"
                                onClick={() => history.goBack()}
                            >
                                <ArrowLeftOutlined /> Atrás
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
