import React, { useState, useEffect } from 'react';
import { Button, Modal as ModalAntd, Space, Table, Tooltip, Form } from 'antd';
import {
    ReloadOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    ExclamationCircleOutlined,
    EditOutlined,
} from '@ant-design/icons';
import AddContainerYard from '../SuppliersContainerYard/AddContainerYard';
import Modal from '../../../General/Modal';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import { METHOD_DELETE, METHOD_GET } from '../../../../utils/constants';
import { API_URL_SUPPLIER_CONTACTS } from '../../../../helpers/urls';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import EditContainerYard from '../SuppliersContainerYard/EditContainerYard';

const SupplierContainerYard = ({supplier}) => {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [reloadContainersYard, setReloadContainersYard] = useState(true);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;
    const { confirm } = ModalAntd;
    const token = getAccessTokenApi();
    let { supplierId } = useParams();
    const [containersYard, setContainerYard] = useState([]);

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        const config = {
            method: METHOD_GET,
            url: `Supplier/${supplierId}/CYs`,
            data: null,
            token,
        };
        fetchApi(config)
            .then((response) => {
                if (response) {
                    let supContacts = response.result;

                    if (supContacts) {
                        supContacts.map((cat, index) => {
                            cat['key'] = index;
                        });
                    }
                    setContainerYard(supContacts);
                }
                setReloadContainersYard(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reloadContainersYard, supplierId, token]);

    const showModal = (type, containerYard) => {
        if (type === 'Add') {
            setModalIsVisible(true);
            setTitleModal(`Agregar patio proveedor ${supplier.name}`);
            setContextModal(
                <AddContainerYard
                    setModalIsVisible={setModalIsVisible}
                    setReloadContainersYard={setReloadContainersYard}
                    supplierId={supplierId}
                ></AddContainerYard>
            );
        } else if (type === 'Edit') {
            setModalIsVisible(true);
            setTitleModal(`Editar patio ${supplier.name}`);
            setContextModal(
                <EditContainerYard
                    setModalIsVisible={setModalIsVisible}
                    setReloadContainersYard={setReloadContainersYard}
                    containerYard={containerYard}
                ></EditContainerYard>
            );
        } else if (type === 'Watch') {
            setModalIsVisible(true);
            setTitleModal('Contacto #123');
            setContextModal(
                <AddContainerYard
                    setModalIsVisible={setModalIsVisible}
                    setReloadContainersYard={setReloadContainersYard}
                    supplierId={supplierId}
                    type="watch"
                ></AddContainerYard>
            );
        }
    };

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            width: 100,
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            width: 100,
            align: 'center',
        },
        {
            title: 'País',
            dataIndex: 'country',
            key: 'country',
            width: 100,
            align: 'center',
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
            width: 100,
            align: 'center',
        },
        {
            title: 'Ciudad',
            dataIndex: 'city',
            key: 'city',
            width: 100,
            align: 'center',
        },
        {
            title: 'Acción',
            key: 'action',
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="Editar">
                        <Button
                            onClick={() => showModal('Edit', record.id)}
                            type="primary"
                        >
                            <EditOutlined />
                        </Button>
                    </Tooltip>

                    <Tooltip title="Eliminar">
                        <Button
                            danger
                            type="primary"
                            onClick={() => handleDeleteContainerYard(record.id)}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            ),
            align: 'center',
        },
    ];

    const handleDeleteContainerYard = (id) => {
        confirm({
            title: 'Eliminar patio',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estás seguro que deseas eliminar el patio seleccionado?',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteContainerYard(id);
            },
            onCancel() {},
        });
    };

    const deleteContainerYard = async (id) => {
        const config = {
            method: METHOD_DELETE,
            url: `CY/${id}`,
            data: null,
            token,
            showNotification: true,
        };
        const result = await fetchApi(config);

        if (result) {
            if (result.statusCode === 200) {
                setReloadContainersYard(true);
            }
        }
    };

    return (
        <div>
            <Button
                className="btn-download"
                onClick={() => showModal('Add')}
                icon={<PlusCircleOutlined />}
            >
                Agregar
            </Button>
            {/* <Button
                disabled={!hasSelected}
                className="btn-delete"
                onClick={(e) => handleDeleteContact(rowSelection)}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button> */}
            <Button
                className="btn-download"
                icon={<ReloadOutlined />}
                onClick={(e) => setReloadContainersYard(true)}
            >
                Refrescar
            </Button>
            {selectedRowKeys.length > 0 && (
                <p>Seleccionados: {selectedRowKeys.length} elementos</p>
            )}
            {/* <Button className="btn-download" onClick={() => showModal('Edit')}>
                Clientes
            </Button> */}
            <Table
                // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                dataSource={containersYard}
                columns={columns}
                bordered
                size="middle"
                scroll={{ x: 1200 }}
                // setSelectedRowKeys
                className="table-striped-rows"
                // rowSelection={rowSelection}
                loading={reloadContainersYard}
            />

            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </div>
    );
};

export default SupplierContainerYard;
