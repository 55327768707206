import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Row, Col, Spin} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { METHOD_POST } from '../../../../utils/constants';
import { API_URL_CATALOG } from '../../../../helpers/urls';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';

import './AddCatalogForm.scss';

const AddCatalogForm = ({setReloadCatalogs, setModalIsVisible}) => {
	const [loading, setLoading] = useState(false);
	const token = getAccessTokenApi();
	const [form] = Form.useForm();

	

	const createCatalogIdhandle = (text) => {
		const arrayText = text.split(' ');
		let catalogId = '';
		arrayText.forEach((element) => {
			 if (element !== '') {
				catalogId = catalogId + element;
			 }
		});

		return catalogId.toLocaleUpperCase();
  };

	const onFinish = async(values) => {
		
		if(!values.FieldsArray){
			values.FieldsArray = [];  
		}
		if(values.status === true){
			values.status = 1;
		} else {
			values.status = 0;
		}

		// if(values.hasRelation === true){
		// 	values.hasRelation = 1;
		// } else {
		// 	values.hasRelation = 0;
		// }

		values.CatalogId = createCatalogIdhandle(values.CatalogId);
		
        const config = {
            method: METHOD_POST,
            url: `${API_URL_CATALOG}`,
            data: values,
            token,
				showNotification: true
        }
        const result = await fetchApi(config);

        if(result){
            if( result.statusCode === 200 ){
					// form.resetFields();
					setModalIsVisible(false);
					setReloadCatalogs(true);
					setLoading(true);
            }
        }

        setLoading(false);
 };

    return (
      <div className='add-catalog-form'>
			<Spin spinning={loading} tip="Cargando...">

         <Form
				name="AddCatalog"
				form={form}
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={{ status: true }}
				onFinish={onFinish}
				// autoComplete="off"
				>
				<Form.Item
					label="Catálogo Id"
					name="CatalogId"
					rules={[{ required: true, message: 'Por favor ingresa un Catálogo ID' }]}
					>
					<Input />
				</Form.Item>

				<Form.Item
					label="Nombre del catálogo"
					name="name"
					rules={[{ max:80, required: true, message: 'Por favor ingresa un nombre' }]}
					>
					<Input maxLength={80} />
				</Form.Item>

				{/* <Row>
					<Col xs={10} md={8}>
						<Form.Item name="status" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
							<Checkbox>Activo</Checkbox>
						</Form.Item>
					</Col>
					<Col xs={10} md={16}>
						<Form.Item name="hasRelation" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
							<Checkbox>Necesita campo relacional</Checkbox>
						</Form.Item>
					</Col>
				</Row> */}

				<Form.Item name="status" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
					<Checkbox>Activo</Checkbox>
				</Form.Item>

				<Form.List
					name="FieldsArray"
					// initialValue={["Perro", "GAto", "MAnzana", "Pegsj"]}
					rules={[
						{
							validator: async (_, names) => {
								// if (!names || names.length < 2) {
									// 	return Promise.reject(new Error('At least 2 passengers'));
									// }
								},
							},
						]}
						>
					{(fields, { add, remove }, { errors }) => (
						<>
							{fields.map((field, index) => (
								<Form.Item
								// {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
								label={`Campo ${index+1}`}
								required={false}
								key={field.key}
								// wrapperCol={{ offset: 8, span: 16 }}
								>
									<Form.Item
										{...field}
										validateTrigger={['onChange', 'onBlur']}
										rules={[
											{
												required: true,
												whitespace: false,
												message: "Por favor ingresa un campo",
											},
										]}
										noStyle
										>
										<Input placeholder="Ingresa un campo" style={{ width: '60%' }} />
									</Form.Item>

									{fields.length >= 0 ? (
										<MinusCircleOutlined
										className="dynamic-delete-button"
										onClick={() => remove(field.name)}
										/>
										) : null}
								</Form.Item>
								)
								)}
									
							<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
								<Button
									type="dashed"
									onClick={() => add()}
									style={{ width: '60%' }}
									icon={<PlusOutlined />}
									>
									Agregar un campo
								</Button>
								{/* <Button
									type="dashed"
									onClick={() => {
										add('The head item', 0);
									}}
									style={{ width: '60%', marginTop: '20px' }}
									icon={<PlusOutlined />}
									>
									Add field at head
								</Button> */}
								<Form.ErrorList errors={errors} />
							</Form.Item>
						</>
					)}
					</Form.List>


				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit">
						Enviar
					</Button>
				</Form.Item>
			</Form>
			</Spin>
		</div>
    )
}

export default AddCatalogForm;
