import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import { getFileInvoice } from '../../../../api/billing';
import { DownloadOutlined } from '@ant-design/icons';
const FileForm = ({ setModalIsVisible, type, reference }) => {
    const token = getAccessTokenApi();
    const [dataUrl, setDataUrl] = useState([]);
    const [typeFile, setTypeFile] = useState([]);

    useEffect(() => {
        let data = {};
        if (type === 'pdf' || type === 'xml') {
            data = {
                reference: reference,
                documentType: 'APInvoice',
                extensionFilter: [type],
            };
        } else if (type === 'cfdi') {
            data = {
                reference: reference,
                documentType: 'APInvoiceAdd',
            };
        }

        getFileInvoice(token, data).then((response) => {
            if (response.statusCode === 200) {
                setDataUrl(response?.result[0]?.stringFileContent);
                setTypeFile(response?.result[0]?.extension);
            }
        });
    }, [token, reference, type]);

    return (
        <div>
            {dataUrl !== undefined && (
                <Button
                    icon={<DownloadOutlined />}
                    className="btn-download"
                    href={'data:image/png;base64,' + dataUrl}
                    download={'Archivo_facturacion.' + typeFile}
                >
                    Descargar archivo
                </Button>
            )}
            {dataUrl === undefined && <p>No hay archivo</p>}
        </div>
    );
};

export default FileForm;
