import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal as ModalAntd, Table, Tooltip } from 'antd';
import {
    ExclamationCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    ReloadOutlined,
    EyeOutlined,
 } from '@ant-design/icons';
import Modal from '../../../General/Modal';
import { formatMoney } from '../../../../utils/general';
import { METHOD_DELETE, METHOD_GET } from '../../../../utils/constants';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';
import { API_URL_QUOTE } from '../../../../helpers/urls';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SERVICES_CATALOG_ID, TYPE_OF_EQUIPMENT_CATALOG_ID, TYPE_OF_LOAD_CATALOG_ID, TYPE_OF_SERVICE_CATALOG_ID, UNIT_TYPE_CATALOG_ID } from '../../../../helpers/constants';
import { getCatalogValueHandle } from '../../../../helpers/handlerFunctionApi';
import LineForm from '../AddQuoteForm/LineForm/LineForm';

const QuoteLinesTable = () => {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const hasSelected = selectedRowKeys.length > 0;
    const { confirm } = ModalAntd;
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const token = getAccessTokenApi();
    const [reloadTable, setReloadTable] = useState(false);
    const [lines, setLines] = useState([]);
    const textModal = '¿Estás seguro que deseas borrar esta linea';
    let { quoteId } = useParams();
    const [filterServiceType, setFilterServiceType] = useState([]);
    const [serviceType, setServiceType] = useState([]);
    const [service, setService] = useState([]);
    const [typeOfLoad, setTypeOfLoad] = useState([]);
    const [filtertypeOfLoad, setFilterTypeOfLoad] = useState([]);
    const [typeOfEquipment, setTypeOfEquipment] = useState([]);
    const [filterTypeOfEquipment, setFilterTypeOfEquipment] = useState([]);
    const [filterService, setFilterService] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };
    useEffect(() => {
        getCatalogValueHandle(
           TYPE_OF_SERVICE_CATALOG_ID,
           setServiceType,
           setIsLoading,
           0
        );
        getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
        getCatalogValueHandle(TYPE_OF_LOAD_CATALOG_ID, setTypeOfLoad, setIsLoading, 0);
        getCatalogValueHandle(UNIT_TYPE_CATALOG_ID, setTypeOfEquipment, setIsLoading, 0);
     }, []);

    useEffect(() => {
        let filters = [];
        typeOfLoad.forEach((element) => {
           const json = { text: element.label, value: element.label };
           filters.push(json);
        });
        setFilterTypeOfLoad(filters);
     }, [typeOfLoad]);

     useEffect(() => {
      let filters = [];
      typeOfEquipment.forEach((element) => {
         const json = { text: element.label, value: element.label };
         filters.push(json);
      });
      setFilterTypeOfEquipment(filters);
      }, [typeOfEquipment]);

     useEffect(() => {
        let filters = [];
        serviceType.forEach((element) => {
           const json = { text: element.label, value: element.label };
           filters.push(json);
        });
        setFilterServiceType(filters);
     }, [serviceType]);
  
     useEffect(() => {
        let filters = [];
        service.forEach((element) => {
           const json = { text: element.label, value: element.label };
           filters.push(json);
        });
        setFilterService(filters);
     }, [service]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        setIsLoadingTable(true);
        const config = {
           method: METHOD_GET,
           url: `${API_URL_QUOTE}/${quoteId}/quotelines`,
           data: null,
           token,
           showNotificationError: true,
        };
        fetchApi(config)
           .then((response) => {
               // console.log(response,"response lines");
              if (response.statusCode === 200) {
                 const allLines = response.result;
                 allLines.map((line) => (line['key'] = line.id));
                 setLines(allLines);
              }
              setIsLoadingTable(false);
              setReloadTable(false);
           })
           .catch((err) => {
              console.log(err);
              setIsLoadingTable(false);
              setReloadTable(false);
           });
     }, [reloadTable]);

    const showModal = (type, line) => {
    //    if (type === 'Edit') {
    //         setModalIsVisible(true);
    //         setTitleModal(`Editar cotización #${quote}`);
    //         setContextModal(
    //             <AddQuoteForm
    //                 type={type}
    //                 setModalIsVisible={setModalIsVisible}
    //                 quoteId={quote}
    //                 // setReloadQuote={setReloadQuote}
    //             ></AddQuoteForm>
    //         );
    //     } else if (type === 'Watch') {
            // console.log(line);
            setModalIsVisible(true);
            setTitleModal(`Cotización #${quoteId} linea ${line.lineNumber}`);
            setContextModal(
                <LineForm
                    type="watch"
                    setModalIsVisible={setModalIsVisible}
                    quoteId={quoteId}
                    // setReloadQuote={setReloadQuote}
                ></LineForm>
            );
        // }
    };

    const columns = [
        {
            title: 'No.Línea',
            dataIndex: 'lineNumber',
            key: 'lineNumber',
            width: 80,
            align: 'center',
        },
        {
         title: 'Tarifa',
         dataIndex: 'rateId',
         key: 'rateId',
         width: 100,
         // fixed: 'left',
         align: 'center',
        },
        {
            title: 'Servicio',
            dataIndex: 'service',
            key: 'service',
            width: 100,
            //   fixed: 'left',
            filters: filterService,
            onFilter: (value, record) => record.service === value,
         },
         {
            title: 'Tipo de servicio',
            dataIndex: 'typeOfService',
            key: 'typeOfService',
            width: 100,
            // fixed: 'left',
            align: 'center',
            filters: filterServiceType,
            onFilter: (value, record) => record.typeOfService === value,
         },
         {
            title: 'Tipo de carga',
            dataIndex: 'typeOfLoad',
            key: 'typeOfLoad',
            width: 100,
            // fixed: 'left',
            align: 'center',
            filters: filtertypeOfLoad,
            onFilter: (value, record) => record.typeOfLoad === value,
         },
         {
            title: 'Tipo de unidad',
            dataIndex: 'unit',
            key: 'unit',
            width: 100,
            // fixed: 'left',
            align: 'center',
            filters: filterTypeOfEquipment,
         },
         {
            title: 'Origen',
            children: [
               {
                  title: 'País',
                  dataIndex: 'originCountry',
                  key: 'originCountry',
                  width: 100,
                  align: 'center',
               },
               {
                  title: 'Ciudad',
                  dataIndex: 'originCity',
                  key: 'originCity',
                  width: 100,
                  align: 'center',
               },
               {
                  title: 'CP',
                  dataIndex: 'originZipCode',
                  key: 'originZipCode',
                  width: 100,
                  align: 'center',
               },
               // {
               //    title: 'Puerto',
               //    dataIndex: 'originPort',
               //    key: 'originPort',
               //    width: 100,
               //    align: 'center',
               // },
               // {
               //    title: 'Aeropuerto',
               //    dataIndex: 'originAirport',
               //    key: 'originAirport',
               //    width: 100,
               //    align: 'center',
               // },
               {
                  title: 'Dirección',
                  dataIndex: 'originAddress',
                  key: 'originAddress',
                  width: 100,
                  align: 'center',
               },
            ],
         },
         {
            title: 'Destino',
            children: [
               {
                  title: 'País',
                  dataIndex: 'destinyCountry',
                  key: 'destinyCountry',
                  width: 100,
                  align: 'center',
               },
               {
                  title: 'Ciudad',
                  dataIndex: 'destinyCity',
                  key: 'destinyCity',
                  width: 100,
                  align: 'center',
               },
               {
                  title: 'CP',
                  dataIndex: 'destinyZipCode',
                  key: 'destinyZipCode',
                  width: 100,
                  align: 'center',
               },
               // {
               //    title: 'Puerto',
               //    dataIndex: 'destinyPort',
               //    key: 'destinyPort',
               //    width: 100,
               //    align: 'center',
               // },
               // {
               //    title: 'Aeropuerto',
               //    dataIndex: 'destinyAirport',
               //    key: 'destinyAirport',
               //    width: 100,
               //    align: 'center',
               // },
               {
                  title: 'Dirección',
                  dataIndex: 'destinyAddress',
                  key: 'destinyAddress',
                  align: 'center',
                  width: 100,
               },
            ],
         },
         {
            title: 'Venta',
            dataIndex: 'sale',
            key: 'sale',
            width: 100,
            align: 'center',
            render: (sale) => <span>${formatMoney(sale)}</span>,
         },
         // {
         //    title: 'Costo',
         //    dataIndex: 'cost',
         //    key: 'cost',
         //    width: 100,
         //    align: 'center',
         //    render: (cost) => <span>{formatMoney(cost)}</span>,
         // },
         // {
         //    title: 'Precio unitario',
         //    dataIndex: 'unitPrice',
         //    key: 'unitPrice',
         //    width: 100,
         //    // fixed: 'right',
         //    align: 'center',
         //    render: (unitPrice) => <span>{`$ ${formatMoney(unitPrice)}`}</span>,
         // },
    ];

    const deleteQuote = async (id) => {
        const config = {
           method: METHOD_DELETE,
           url: `${API_URL_QUOTE}/${id}`,
           data: null,
           token,
           showNotification: true,
        };
        const result = await fetchApi(config);
  
        if (result) {
           if (result.statusCode === 200) {
              setReloadTable(true);
              setSelectedRowKeys([]);
           }
        }
     };
  
     const showDeleteConfirm = (id, description = '') => {
        confirm({
           title: `${textModal}?`,
           icon: <ExclamationCircleOutlined />,
           content: 'No podrá deshacer esta acción después..',
           okText: 'Si',
           okType: 'danger',
           cancelText: 'No',
           onOk() {
              deleteQuote(id);
           },
           onCancel() {},
        });
     };

    return (
        <div>
            <Button
                disabled={!hasSelected}
                // disabled={selectedRowKeys.length !== 1}
                className="btn-delete"
                onClick={() => showDeleteConfirm(selectedRowKeys[0])}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button>
            <Button className="btn-download" icon={<ReloadOutlined />} onClick={() => setReloadTable(true)}>
                Refrescar
            </Button>
            {selectedRowKeys.length > 0 && (
                <p>Seleccionados: {selectedRowKeys.length} elementos</p>
            )}
            {/* <Button className="btn-download" onClick={() => showModal('Edit')}>
                Clientes
            </Button> */}
            <Table
                // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                dataSource={lines}
                columns={columns}
                bordered
                size="middle"
                scroll={{ x: 1200 }}
                setSelectedRowKeys
                className="table-striped-rows"
                rowSelection={rowSelection}
                loading={isLoadingTable || isLoading}
            />

            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </div>
    );
};

export default QuoteLinesTable;
