/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Spin,
  Form,
  Alert,
  Col,
  Button,
  Tabs,
  notification,
  Modal as ModalAntd,
  Tooltip,
} from 'antd';
import { ClearOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import BasicPricingTab from './BasicPricingTab';
import RuteTab from './RuteTab/RuteTab';
import DimensionsTable from '../Quote/AddQuoteForm/DimensionsTable/DimensionsTable';
import {
  AEREO_VALUE_ID,
  MARITIMO_VALUE_ID,
  TERRESTRE_VALUE_ID,
} from '../../../helpers/constants';
import { METHOD_GET, METHOD_POST, METHOD_PUT } from '../../../utils/constants';
import { API_URL_RATE, API_URL_RFQ } from '../../../helpers/urls';
import { fetchApi } from '../../../helpers/fetch';
import { getAccessTokenApi } from '../../../api/auth';
import useUser from '../../../hooks/useUser';

import './AddPricingForm.scss';

const AddEditPricingForm = (props) => {
  const { type, setModalIsVisible, rateId, setReloadTable, isRfq } = props;
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [service, setService] = useState(TERRESTRE_VALUE_ID);
  const [validatedCPOrigin, setValidatedCPOrigin] = useState(false);
  const [validatedCPDestination, setValidatedCPDestination] = useState(false);
  const [disableAllInputs, setDisableAllInputs] = useState(
    type === 'watch' ? true : false
  );
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isRFQ, setIsRFQ] = useState(false);
  const [rateApi, setRateApi] = useState(null);
  const [keyTabActive, setKeyTabActive] = useState('BasicPricingTab');
  const [showAllValues, setShowAllValues] = useState(true);
  const [pieces, setPieces] = useState([]);
  const [filterSupplier, setFilterSupplier] = useState({
    service: TERRESTRE_VALUE_ID,
  });
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const { TabPane } = Tabs;
  const { person } = useUser();

  useEffect(() => {
    //edit or watch
    if (rateId) {
      setLoadingForm(true);

      let config = {};
      config = {
        method: METHOD_GET,
        url: `${API_URL_RATE}/${rateId}`,
        data: null,
        token,
        showNotificationError: true,
      };
      if (isRfq) {
        config = {
          method: METHOD_GET,
          url: `${API_URL_RFQ}/${rateId}`,
          data: null,
          token,
          showNotificationError: true,
        };
      }
      fetchApi(config)
        .then((response) => {
          // console.log(response, 'response get');
          if (response.statusCode === 200) {
            let result = [];
            let userName = '';
            let supplairName = '';
            if (!isRfq) {
              result = response.result[0].rate;
              userName = response.result[0].createdBy;
              supplairName = response.result[0].supplierName;
            } else {
              result = response.result.rfq;
              userName = response.result.createdBy;
              setPieces(response.result.pieces);
            }
            // console.log(result);
            setRateApi(result);
            const rate = {
              id: result.id,
              RateId: result.rateId,
              validity:
                result.startDate !== null
                  ? [moment.unix(result.startDate), moment.unix(result.endDate)]
                  : null,
              StartDate: result.startDate,
              EndDate: result.endDate,
              IsCompleted: result.isCompleted,
              service: result.service,
              TypeOfService: result.typeOfService,
              HazMat: result.hazMat,
              HazMatDescription: result.hazMatDescription,
              Operation: result.operation !== 0 ? result.operation : null,
              EquipmentType:
                result.equipmentType !== 0 ? result.equipmentType : null,
              TypeOfLoad: result.typeOfLoad,
              OriginCountry: result.originCountry,
              OriginZipCode: result.originZipCode,
              OriginState: result.originState,
              OriginCity: result.originCity,
              OriginAddress: result.originAddress,
              DestinyCountry: result.destinyCountry,
              DestinyZipCode: result.destinyZipCode,
              DestinyState: result.destinyState,
              DestinyCity: result.destinyCity,
              DestinyAddress: result.destinyAddress,
              UnitType: result.unitType,
              UnitPrice: result.unitPrice,
              Comments: result.comments,
              Currency: result.currency !== 0 ? result.currency : null,
              IsRFQ: result.isRFQ,
              OriginAirport: result.originAirport,
              OriginPort: result.originPort,
              DestinyAirport: result.destinyAirport,
              DestinyPort: result.destinyPort,
              Cost: result.cost,
              Unit: result.unit,
              Packaging: result.packaging !== 0 ? result.packaging : null,
              CreatedBy: result.createdBy,
              LastUpdatedBy: result.lastUpdatedBy,
              CreatedAt: result.createdAt,
              LastUpdatedAt: result.lastUpdatedAt,
              SupplierId: result.supplierId,
              ContactId: result.contactId,
              supplier: supplairName,
              MeasurementUnit:
                result.measurementUnit !== 0 ? result.measurementUnit : null,
              user: userName,
            };
            form.setFieldsValue(rate);
            setService(result.service);
            setIsRFQ(result.isRFQ);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoadingForm(false);
    } else {
      form.setFieldsValue({
        service: TERRESTRE_VALUE_ID,
        user: person.userName,
      });
    }
  }, []);

  const addPricingFunction = async (values) => {
    // setLoadingForm(true);
    values['StartDate'] = moment(values.validity[0]).unix();
    values['EndDate'] = moment(values.validity[1]).unix();

    if (rateId) {
      values['id'] = rateId;

      if (rateApi.isCompleted) {
        notification['error']({
          description: 'No puede modificar esta tarifa',
        });

        return;
      }
    }

    const continueValidation = validationRuteField(values);
    if (values['service'] === 0) {
      notification['error']({
        description: 'Agregue un servicio',
      });
      return;
    }
    if (!continueValidation) {
      return;
    }
    let config = {};
    config = {
      method: rateId ? METHOD_PUT : METHOD_POST,
      url: `${API_URL_RATE}${rateId ? '/' + rateId : ''}`,
      data: values,
      token,
      showNotification: true,
    };
    if (isRfq) {
      config = {
        method: METHOD_PUT,
        url: `${API_URL_RFQ}/${rateId}`,
        data: values,
        token,
        showNotificationError: true,
      };
    }
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response edit rfq');
        setLoadingForm(false);
        if (response) {
          if (response.statusCode === 200) {
            setReloadTable(true);
            setModalIsVisible(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const errorFields = ({ values, errorFields, outOfDate }) => {
    // console.log(errorFields);
    if (service === TERRESTRE_VALUE_ID) {
      if (
        !values.OriginCountry ||
        !values.OriginZipCode ||
        !values.OriginState ||
        !values.OriginCity ||
        !values.OriginAddress ||
        !values.DestinyCountry ||
        !values.DestinyZipCode ||
        !values.DestinyState ||
        !values.DestinyCity ||
        !values.DestinyAddress
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else if (service === MARITIMO_VALUE_ID) {
      if (
        !values.OriginCountry ||
        !values.OriginState ||
        !values.OriginCity ||
        !values.OriginPort ||
        !values.DestinyCountry ||
        !values.DestinyState ||
        !values.DestinyCity ||
        !values.DestinyPort
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } else if (service === AEREO_VALUE_ID) {
      if (
        !values.OriginCountry ||
        !values.OriginState ||
        !values.OriginCity ||
        !values.OriginAirport ||
        !values.DestinyCountry ||
        !values.DestinyState ||
        !values.DestinyCity ||
        !values.DestinyAirport
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    }

    if (
      !values.supplier ||
      !values.ContactId ||
      // !values.StartDate ||
      // !values.EndDate ||
      // !values.IsCompleted ||
      !values.service ||
      !values.TypeOfService ||
      // !values.HazMat ||
      // !values.HazMatDescription ||
      !values.Operation ||
      // !values.EquipmentType ||
      !values.TypeOfLoad ||
      !values.ContactId ||
      !values.validity ||
      !values.UnitPrice ||
      !values.MeasurementUnit
    ) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete todos los datos de la sección "Detalle"'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const validationRuteField = (values) => {
    let pass = true;
    if (service === TERRESTRE_VALUE_ID) {
      if (
        !values.OriginCountry ||
        !values.OriginZipCode ||
        !values.OriginState ||
        !values.OriginCity ||
        !values.OriginAddress ||
        !values.DestinyCountry ||
        !values.DestinyZipCode ||
        !values.DestinyState ||
        !values.DestinyCity ||
        !values.DestinyAddress
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        pass = false;
      }
    } else if (service === MARITIMO_VALUE_ID) {
      if (
        !values.OriginCountry ||
        !values.OriginState ||
        !values.OriginCity ||
        !values.OriginPort ||
        !values.DestinyCountry ||
        !values.DestinyState ||
        !values.DestinyCity ||
        !values.DestinyPort
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        pass = false;
      }
    } else if (service === AEREO_VALUE_ID) {
      if (
        !values.OriginCountry ||
        !values.OriginState ||
        !values.OriginCity ||
        !values.OriginAirport ||
        !values.DestinyCountry ||
        !values.DestinyState ||
        !values.DestinyCity ||
        !values.DestinyAirport
      ) {
        setTextType('Datos incompletos');
        setTextDescription('Complete todos los datos de la sección "Ruta"');
        setShowAlert(true);

        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        pass = false;
      }
    }
    return pass;
  };

  function callback(key) {
    setKeyTabActive(key);
  }

  function clearFilterRate() {
    setFilterSupplier({});
    const rateEmpty = {
      service: null,
      TypeOfService: null,
      HazMat: null,
      HazMatDescription: null,
      Operation: null,
      EquipmentType: null,
      TypeOfLoad: null,
      UnitType: null,
      Unit: null,
      Packaging: null,
      ContactId: null,
      supplier: null,
      MeasurementUnit: null,
    };
    form.setFieldsValue(rateEmpty);
    setShowAllValues(true);
  }

  function showConfirm() {
    confirm({
      title: 'Estás seguro que deseas limpiar las opciones',
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta opción',
      okText: 'Si, limpiar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        clearFilterRate();
      },
    });
  }

  return (
    <Spin spinning={loadingForm || loadingCatalog} tip='Cargando...'>
      <Form
        name='add-promo'
        layout='vertical'
        form={form}
        onFinish={addPricingFunction}
        onFinishFailed={errorFields}
      >
        <Tabs
          defaultActiveKey={'BasicPricingTab'}
          activeKey={keyTabActive}
          type='card'
          size={'middle'}
          onChange={callback}
        >
          <TabPane tab='Detalle' key='BasicPricingTab'>
            <BasicPricingTab
              disableAllInputs={disableAllInputs}
              service={service}
              setService={setService}
              setLoadingForm={setLoadingForm}
              form={form}
              rate={rateApi}
              setLoadingCatalog={setLoadingCatalog}
              setShowAllValues={setShowAllValues}
              showAllValues={showAllValues}
              setFilterSupplier={setFilterSupplier}
              filterSupplier={filterSupplier}
              isRFQ={isRFQ}
            />
          </TabPane>

          <TabPane tab='Ruta' key='RuteTab' forceRender>
            <RuteTab
              disableAllInputs={disableAllInputs}
              service={service}
              validatedCPOrigin={validatedCPOrigin}
              setValidatedCPOrigin={setValidatedCPOrigin}
              validatedCPDestination={validatedCPDestination}
              setValidatedCPDestination={setValidatedCPDestination}
              setLoadingForm={setLoadingForm}
              form={form}
              type={type}
              isRfq={isRfq}
            />
          </TabPane>

          {isRFQ && (
            <TabPane tab='Piezas y dimensiones' key='DimensionsTable'>
              <DimensionsTable
                disableAllInputs={false}
                pieces={pieces}
                type={'rfq'}
              />
            </TabPane>
          )}
        </Tabs>

        {showAlert && (
          <Alert
            message={textType}
            description={textDescription}
            type='warning'
            showIcon
            style={{ marginBottom: 20 }}
          />
        )}

        {type !== 'watch' && (
          <Row style={{ justifyContent: 'space-center' }}>
            {!isRFQ && (
              <Col xs={24} md={6}>
                <Form.Item>
                <Tooltip title='Los campos que se limpian son los que sirven para filtrar el proovedor'>
                  <Button
                    style={{ margin: 0 }}
                    className='btn-download'
                    block
                    size='small'
                    icon={<ClearOutlined />}
                    onClick={showConfirm}
                    disabled={rateApi?.isCompleted}
                  >
                    Limpiar opciones
                  </Button>
                  </Tooltip>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} md={6}>
              <Form.Item>
                <Button
                  style={{ margin: 0 }}
                  htmlType='submit'
                  className='btn-enviar'
                  block
                  disabled={rateApi?.isCompleted}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Spin>
  );
};

export default AddEditPricingForm;
