import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Tag, Input, Space, Tooltip } from 'antd';
import {
    FileExcelFilled,
    FilePdfFilled,
    FileAddOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    PaperClipOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import { formatMoney } from '../../../../utils/general';
import Modal from '../../../General/Modal';
import AddDocumentsForm from '../AddDocumentsForm';
import {
    ROL_CUSTOMER,
    ROL_FINANCIAL,
    ROL_MANAGER,
    ROL_OPERACIONES,
} from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';

export default function OperationTable({
    operations,
    reloadOperations,
    loadingData,
    dateRange,
    setReloadOperations,
}) {
    const [operationsData, setOperationsData] = useState(operations);
    const [operationsExport, setOperationsExport] = useState(operations);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [showAllColumns, setShowAllColumns] = useState(false);

    let history = useHistory();
    const { person } = useUser();

    useEffect(() => {
        setOperationsExport(operations);
    }, [operations]);

    useEffect(() => {
        setFilteredInfo({});
    }, [dateRange]);

    useEffect(() => {
        operations.forEach((op, index) => {
            op['key'] = index;
        });

        setOperationsData(operations);
    }, [operations]);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => {handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex);}}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
        setOperationsExport(extra.currentDataSource);
    };

    // console.log(operations, 'operations');

    const columns = [
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference',
            ...getColumnSearchProps('reference'),
            width: 150,
            render: (reference, operation) => (
                <Button
                    // type="primary"
                    className="button-detail"
                    onClick={() =>
                        history.push(`/operaciones/detalle/${operation.id}`)
                    }
                >
                    {reference}
                </Button>
            ),
            ellipsis: true,
        },
        {
            title: 'Emisión',
            dataIndex: 'operationDate',
            key: 'operationDate',
            width: 120,
            render: (operationDate) => (
                <>{moment.utc(operationDate).local().format('DD/MM/YYYY')}</>
            ),
            ellipsis: true,
        },
        {
            title: 'Referencia cte.	',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            width: 150,
            ...getColumnSearchProps('purchaseOrder'),
            ellipsis: true,
        },
        {
            title: 'Operación',
            dataIndex: 'serviceId',
            key: 'serviceId',
            width: 150,
            ...getColumnSearchProps('serviceId'),
            ellipsis: true,
        },

        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            filters: [
                { text: 'En proceso', value: 'En proceso' },
                { text: 'Terminado', value: 'Terminado' },
                // { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.status || null,
            width: 120,
            onFilter: (value, record) => record.status.includes(value),
            render: (status) => (
                <Tag color={status === 'En proceso' ? 'orange' : 'green'}>
                    {status.toUpperCase()}
                </Tag>
            ),
            ellipsis: true,
        },
        {
            title: 'Documentos',
            dataIndex: 'description',
            key: '3',
            width: 120,
            render: (statusText, record) => (
                <>
                    <Button
                        type="primary"
                        size="small"
                        icon={<FileAddOutlined />}
                        onClick={() => {
                            addDocumentModal(record.id);
                        }}
                    ></Button>
                    <Button
                        type="primary"
                        ghost
                        size="small"
                        icon={<PaperClipOutlined />}
                        onClick={() => {
                            history.push(
                                `/operaciones/detalles/documentos/${record.id}`
                            );
                        }}
                        style={{ marginLeft: 15 }}
                    ></Button>
                </>
            ),
            ellipsis: true,
        },
        {
            title: 'Aclaraciones',
            key: 'hasOpenNote',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 120,
            ellipsis: true,
            filters: [
                { text: 'Nuevo', value: 'new' },
                { text: 'Abierto', value: 'open' },
                { text: 'Cerrado', value: 'close' },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => {
                //cerrada : hasOpenNote === false && noteid !== null
                if (value === 'new') {
                    return (
                        (!record.hasOpenNote && record.noteId === 0) ||
                        (!record.hasOpenNote && record.noteId !== 0)
                    );
                }
                if (value === 'open') {
                    return record.hasOpenNote && record.notes !== 0;
                }
                if (value === 'close') {
                    return !record.hasOpenNote && record.notes !== null;
                }
            },
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.notes !== null && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.notes}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                            >
                                {invoice.notes}
                            </Tag>
                        </Tooltip>
                    )}
                    {[ROL_CUSTOMER].some((r) => person.roles.includes(r)) &&
                        !hasOpenNote && (
                            <Tooltip placement="topLeft" title={'Nueva'}>
                                <Tag
                                    onClick={() =>
                                        history.push(
                                            `/aclaraciones/nueva/cp/${invoice.operationId}`
                                        )
                                    }
                                    color={'green'}
                                >
                                    <PlusCircleOutlined />
                                </Tag>
                            </Tooltip>
                        )}
                </>
            ),
        },
    ];
    const columnsAll = [
        {
            title: 'Cliente ID',
            dataIndex: 'customerId',
            key: 'customerId',
            ...getColumnSearchProps('customerId'),
            width: 150,
            render: (customerId) => <p>{customerId}</p>,
            ellipsis: true,
        },
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference',
            ...getColumnSearchProps('reference'),
            width: 150,
            render: (reference, operation) => (
                <Button
                    // type="primary"
                    className="button-detail"
                    onClick={() =>
                        history.push(`/operaciones/detalle/${operation.id}`)
                    }
                >
                    {reference}
                </Button>
            ),
            ellipsis: true,
        },
        {
            title: 'Emisión',
            dataIndex: 'operationDate',
            key: 'operationDate',
            width: 120,
            render: (operationDate) => (
                <>{moment.utc(operationDate).local().format('DD/MM/YYYY')}</>
            ),
            ellipsis: true,
        },
        {
            title: 'Referencia cte.	',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            width: 150,
            ...getColumnSearchProps('purchaseOrder'),
            ellipsis: true,
        },
        {
            title: 'Operación',
            dataIndex: 'serviceId',
            key: 'serviceId',
            width: 150,
            ...getColumnSearchProps('serviceId'),
            ellipsis: true,
        },

        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            // ...getColumnSearchProps('status'),
            filters: [
                { text: 'En proceso', value: 'En proceso' },
                { text: 'Terminado', value: 'Terminado' },
                // { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.status || null,
            width: 120,
            onFilter: (value, record) => record.status.includes(value),
            render: (status) => (
                <Tag color={status === 'En proceso' ? 'orange' : 'green'}>
                    {status.toUpperCase()}
                </Tag>
            ),
            ellipsis: true,
        },
        {
            title: 'Documentos',
            dataIndex: 'description',
            key: '3',
            width: 120,
            render: (statusText, record) => (
                <>
                    <Button
                        type="primary"
                        size="small"
                        icon={<FileAddOutlined />}
                        onClick={() => {
                            addDocumentModal(record.id);
                        }}
                    ></Button>
                    <Button
                        type="primary"
                        ghost
                        size="small"
                        icon={<PaperClipOutlined />}
                        onClick={() => {
                            history.push(
                                `/operaciones/detalles/documentos/${record.id}`
                            );
                        }}
                        style={{ marginLeft: 15 }}
                    ></Button>
                </>
            ),
            ellipsis: true,
        },
        {
            title: 'Aclaraciones',
            key: 'hasOpenNote',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 120,
            ellipsis: true,
            filters: [
                { text: 'Nuevo', value: 'new' },
                { text: 'Abierto', value: 'open' },
                { text: 'Cerrado', value: 'close' },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => {
                //cerrada : hasOpenNote === false && noteid !== null
                if (value === 'new') {
                    return (
                        (!record.hasOpenNote && record.noteId === 0) ||
                        (!record.hasOpenNote && record.noteId !== 0)
                    );
                }
                if (value === 'open') {
                    return record.hasOpenNote && record.notes !== 0;
                }
                if (value === 'close') {
                    return !record.hasOpenNote && record.notes !== null;
                }
            },
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.notes !== null && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.notes}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                            >
                                {invoice.notes}
                            </Tag>
                        </Tooltip>
                    )}
                    {[ROL_CUSTOMER].some((r) => person.roles.includes(r)) &&
                        !hasOpenNote && (
                            <Tooltip placement="topLeft" title={'Nueva'}>
                                <Tag
                                    onClick={() =>
                                        history.push(
                                            `/aclaraciones/nueva/cp/${invoice.operationId}`
                                        )
                                    }
                                    color={'green'}
                                >
                                    <PlusCircleOutlined />
                                </Tag>
                            </Tooltip>
                        )}
                </>
            ),
        },
    ];

    useEffect(() => {
        // console.log(ROL_MANAGER, ROL_FINANCIAL, ROL_SUPPLIER);
        [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
            person.roles.includes(r)
        ) && setShowAllColumns(true);
        // setColumns([newColumn,...columns])
    }, [person.roles]);
    const generateExcel = (entries) => {
        const filename = `Operaciones${moment().format('L')}.xlsx`;
        let fecha = moment().format('LLL');
        const ws_name = 'Hoja1';
        let wb = {
            SheetNames: [],
            Sheets: {},
        };
        let ws = {};
        let renInicial = 4;
        var mstrTitulo = `Operaciones del ${dateRange.startDate} al: ${dateRange.endDate}`;

        if (
            [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
                person.roles.includes(r)
            )
        ) {
            ws = {
                A1: { v: mstrTitulo, t: 's' },
                E1: { v: fecha, t: 's' },

                A3: { v: 'Cliente ID', t: 's' },
                B3: { v: 'Referencia', t: 's' },
                C3: { v: 'Emisión', t: 's' },
                D3: { v: 'Referencia cte.', t: 's' },
                E3: { v: 'Operación', t: 's' },
                F3: { v: 'Estatus', t: 's' },
            };

            for (let i in entries) {
                const {
                    customerId,
                    reference,
                    operationDate,
                    purchaseOrder,
                    serviceId,
                    status,
                } = entries[i];
                ws['A' + renInicial] = {
                    v: customerId,
                    t: 's',
                };
                ws['B' + renInicial] = {
                    v: reference,
                    t: 's',
                };
                ws['C' + renInicial] = {
                    v: moment.utc(operationDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['D' + renInicial] = {
                    v: purchaseOrder,
                    t: 's',
                };
                ws['E' + renInicial] = { v: serviceId, t: 's' };
                ws['F' + renInicial] = { v: status, t: 's' };
                renInicial++;
            }
        } else {
            ws = {
                A1: { v: mstrTitulo, t: 's' },
                E1: { v: fecha, t: 's' },

                A3: { v: 'Referencia', t: 's' },
                B3: { v: 'Emisión', t: 's' },
                C3: { v: 'Referencia cte.', t: 's' },
                D3: { v: 'Operación', t: 's' },
                E3: { v: 'Estatus', t: 's' },
            };

            for (let i in entries) {
                const {
                    reference,
                    operationDate,
                    purchaseOrder,
                    serviceId,
                    status,
                } = entries[i];
                ws['A' + renInicial] = {
                    v: reference,
                    t: 's',
                };
                ws['B' + renInicial] = {
                    v: moment.utc(operationDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['C' + renInicial] = {
                    v: purchaseOrder,
                    t: 's',
                };
                ws['D' + renInicial] = { v: serviceId, t: 's' };
                ws['E' + renInicial] = { v: status, t: 's' };
                renInicial++;
            }
        }

        // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
        // ws['!cols'] = wscols;
        ws['!ref'] = XLSX.utils.encode_range({
            s: { c: 0, r: 0 },
            e: { c: 15, r: renInicial },
        });
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;
        XLSX.writeFile(wb, filename, { cellStyles: true });
    };

    const exportPdf = (bills, startDate, endDate) => {
        var mstrTitulo = `Operaciones del ${dateRange.startDate} al: ${dateRange.endDate}`;

        // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
        //     .unix(startDate)
        //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
        var mstrPdf = `Operaciones${moment().format('L')}.pdf`;

        var registros = [];
        if (
            [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
                person.roles.includes(r)
            )
        ) {
            bills.forEach((element) => {
                registros.push([
                    element.customerId,
                    element.reference,
                    moment
                        .utc(element.operationDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    element.purchaseOrder,
                    element.serviceId,
                    element.status,
                ]);
            });
        } else {
            bills.forEach((element) => {
                registros.push([
                    element.reference,
                    moment
                        .utc(element.operationDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    element.purchaseOrder,
                    element.serviceId,
                    element.status,
                ]);
            });
        }

        let fecha = moment().format('LLL');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 220;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(-10, -10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        if (
            [ROL_MANAGER, ROL_FINANCIAL, ROL_OPERACIONES].some((r) =>
                person.roles.includes(r)
            )
        ) {
            doc.autoTable({
                head: [
                    [
                        'Cliente ID',
                        'Referencia',
                        'Emisión',
                        'Referencia cte.',
                        'Operación',
                        'Estatus',
                    ],
                ],
                didDrawPage: pageContent,
                theme: 'grid',
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    fillColor: [49, 101, 120],
                    textColor: [255, 255, 255],
                },
                body: registros,
                columnStyles: {
                    text: {
                        cellWidth: 'wrap',
                    },
                },
            });
        } else {
            doc.autoTable({
                head: [
                    [
                        'Referencia',
                        'Emisión',
                        'Referencia cte.',
                        'Operación',
                        'Estatus',
                    ],
                ],
                didDrawPage: pageContent,
                theme: 'grid',
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    fillColor: [49, 101, 120],
                    textColor: [255, 255, 255],
                },
                body: registros,
                columnStyles: {
                    text: {
                        cellWidth: 'wrap',
                    },
                },
            });
        }

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };

    const addDocumentModal = (id) => {
        setTitleModal(`Agregar documentos - ${id}`);
        setModalIsVisible(true);
        setContextModal(
            <AddDocumentsForm
                setModalIsVisible={setModalIsVisible}
                operationId={id}
                setReloadDocuments={false}
                setReloadOperations={setReloadOperations}
            ></AddDocumentsForm>
        );
    };

    return (
        <>
            <Table
                onChange={handleChange}
                columns={showAllColumns ? columnsAll : columns}
                dataSource={operationsData}
                scroll={{ x: 600 }}
                // pagination={{
                //     pageSize: 10,
                // }}
                loading={loadingData}
            />

            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        icon={<FilePdfFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => exportPdf(operationsExport)}
                        disabled={operationsExport.length === 0}
                    >
                        Descargar Pdf
                    </Button>
                    <Button
                        icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => generateExcel(operationsExport)}
                        disabled={operationsExport.length === 0}
                    >
                        Descargar Excel
                    </Button>
                </Col>
            </Row>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
            >
                {contextModal}
            </Modal>
        </>
    );
}
