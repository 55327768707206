import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Spin,
    Form,
    Divider,
    Button,
    notification,
    Typography,
} from 'antd';
import { getCompanyIdApi } from '../../../../api/company';
import TextArea from 'antd/lib/input/TextArea';
import { addCommentApi, getCommentsApi } from '../../../../api/operations';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { ROL_MANAGER, ROL_OPERACIONES } from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
const AddCommentForm = ({ setModalIsVisible, documentId }) => {
    const [loadingForm, setLoadingForm] = useState(true);
    const [reloadComments, setReloadComments] = useState(true);
    const [comments, setComments] = useState([]);
    const [form] = Form.useForm();
    const companyId = getCompanyIdApi();
    const token = getAccessTokenApi();
    const { Title } = Typography;
    const { person } = useUser();

    useEffect(() => {
        if (reloadComments) {
            getCommentsApi(token, documentId)
                .then((response) => {
                    if (response) {
                        response.result.forEach((element, index) => {
                            element['key'] = index;
                        });
                        setComments(response.result);
                        setReloadComments(false);
                    }
                })
                .catch((err) => {})
                .finally(() => setLoadingForm(false));
        }
    }, []);

    const addCommentFunction = async (values) => {
        setLoadingForm(true);
        let data = values;

        // data['company'] = companyId;
        data['documentId'] = documentId;

        // console.log(values, 'values');
        const response = await addCommentApi(token, values);
        // console.log(response, 'response');
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelomas tarde',
            });
            setLoadingForm(false);
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            setModalIsVisible(false);
            notification['success']({
                message: 'Comentario agregado con éxito',
            });
            return;
        }
        if (response.statusCode === 400) {
            notification['error']({
                message: response.description,
            });
            setLoadingForm(false);
            return;
        } else {
            let messageError = 'Ocurrió un error, Inténtelootra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.statusCode === 400) {
                messageError = response.description;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="add-comment"
                layout="vertical"
                form={form}
                onFinish={addCommentFunction}
            >
                <div>
                    <Row xs={24} className="table-company">
                        <Col xs={20}>
                            {comments.length === 0 && <p>No hay comentarios</p>}
                            {comments.length !== 0 && (
                                <>
                                    <Title level={5}>Comentarios:</Title>
                                    {comments?.map((c, index) => (
                                        <Row key={index}>
                                            <p
                                                style={{
                                                    textTransform: 'capitalize',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {c.createdBy}:
                                            </p>
                                            <p>{c.text}</p>
                                        </Row>
                                    ))}
                                </>
                            )}
                            {[ROL_OPERACIONES].some((r) =>
                                person.roles.includes(r)
                            ) && (
                                <>
                                    <Divider />
                                    <Form.Item
                                        label="Agregar comentarios:"
                                        name="text"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Por favor ingrese comentario',
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            placeholder="Ingresa tu información"
                                            style={{ height: 150 }}
                                        />
                                    </Form.Item>

                                    <Row>
                                        <Col span={24}>
                                            <Form.Item>
                                                <Button
                                                    htmlType="submit"
                                                    className="btn-enviar"
                                                    block
                                                >
                                                    Guardar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default AddCommentForm;
