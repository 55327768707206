import React, { useEffect, useState } from 'react';
import { Button, Col, Layout, notification, Row, Typography, Spin } from 'antd';
import OperationDetail from '../../../../components/PortalClients/Operations/OperationDetail';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../api/auth';
import { getOperationDetailsInfo } from '../../../../api/operations';
import { messageErrorGeneral } from '../../../../utils/feedbackMessages';

export default function OperationDetailPage() {
    const { id } = useParams();
    const token = getAccessTokenApi();
    const [infoOperation, setInfoOperation] = useState(null);
    const [laodingData, setLaodingData] = useState(false);

    useEffect(() => {
        if (token && id) {
            const data = {
                operationId: id,
            };
            setLaodingData(true);
            getOperationDetailsInfo(token, data).then((response) => {
                // console.log(response, 'get aclaraciones');
                // console.log(response);
                if (response) {
                    if (response.statusCode === 409) {
                        notification['error']({
                            message: response.message,
                        });

                        setTimeout(() => {
                            window.location.href = '/operaciones';
                        }, 1500);
                    }

                    if (response.statusCode === 200) {
                        const result = response.result;
                        setInfoOperation(result[0]);
                    }
                } else {
                    notification['error']({
                        message: messageErrorGeneral(),
                    });

                    setTimeout(() => {
                        window.location.href = '/operaciones';
                    }, 1500);
                }
                setLaodingData(false);
            });
        }
    }, [token, id]);

    return (
        <Layout>
            <Spin spinning={laodingData} size="large">
                <GreetingsTop />

                <HeaderTop />

                <div className="billing__container">
                    {infoOperation && (
                        <OperationDetail infoOperation={infoOperation} />
                    )}
                </div>
            </Spin>
        </Layout>
    );
}
function HeaderTop() {
    const { Title } = Typography;
    let history = useHistory();

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={19} className="header-top__left">
                    <Title level={3}>Detalle de la operación</Title>
                </Col>
                <Col xs={1}>
                    <Button type="link" onClick={() => history.goBack()}>
                        <ArrowLeftOutlined /> Atrás
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
