import React, { useEffect, useState } from 'react';

import { Layout, Row, Col, Typography } from 'antd';
import queryString from 'query-string';
import logoPlanB from '../../assets/img/png/logo_planb.png';
import logoGPMET from '../../assets/img/png/logoGPMET.png';
import ResetPasswordForm from '../../components/General/ResetPasswordForm';

// import InstallPWA from '../../components/General/InstallPWA';

export default function ResetPassword(props) {
    const { Content } = Layout;
    const { Paragraph, Title } = Typography;
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        let params = queryString.parse(props.location.search);
        setUser(params)
        return () => {
            
        }
    }, [props.location.search])

    return (
        <Layout className="login">
            <Content className="login__content" style={{ padding: 20 }}>
                <div className="login__content-banner animate__animated  animate__slideInDown">
                    <Row className="login-container">
                        <Col span={24}>
                            <img
                                src={logoGPMET}
                                alt="logoGPMET"
                                style={{ width: 200 }}
                            />
                            <p
                                style={{
                                    fontWeight: 300,
                                    lineHeight: 1.4,
                                    fontSize: 18,
                                    textAlign: 'center',
                                    marginTop: 20,
                                }}
                            >
                                {' '}
                                Reinicie sus contraseñas
                            </p>
                        </Col>
                    </Row>
                    <ResetPasswordForm user={user}/>
                    <Row className="login-container">
                        <Col>
                            <Paragraph>Desarrollado por:</Paragraph>
                            <img
                                src={logoPlanB}
                                alt="logo_planb"
                                style={{ width: 100 }}
                            />
                        </Col>
                    </Row>
                </div>

                {/* <InstallPWA login /> */}
            </Content>
        </Layout>
    );
}
