import React, { useEffect, useState } from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import SuppliersContainerYard from '../../../../components/Admin/Suppliers/SuppliersContainerYard/SupplierContainerYard';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getAccessTokenApi } from '../../../../api/auth';
import { METHOD_GET } from '../../../../utils/constants';
import { fetchApi } from '../../../../helpers/fetch';

export default function SuplierContainerYard() {
    let { supplierId } = useParams();
    const token = getAccessTokenApi();
    const [supplier, setSupplier] = useState({});

    useEffect(() => {
        const config = {
            method: METHOD_GET,
            url: `Supplier/${supplierId}`,
            data: null,
            token,
        };
        fetchApi(config)
            .then((response) => {
                // console.log(response, 'response sup');
                if (response) {
                    let supp = response.result;
                    setSupplier(supp);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [supplierId, token]);
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop supplier={supplier}/>

            <div className="clients__container">
                <SuppliersContainerYard supplier={supplier}/>
            </div>
        </Layout>
    );
}

function HeaderTop({supplier}) {
    const { Title } = Typography;
    let history = useHistory();

    return (
        <div className="clients__container">
            <Row xs={23} className="header-top">
                <Col xs={19} className="header-top__left">
                    <Title level={3}>Patio {supplier.name}</Title>
                </Col>
                <Col xs={1}>
                    <Button type="link" onClick={() => history.goBack()}>
                        <ArrowLeftOutlined /> Atrás
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
