import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Layout,
    Card,
    Calendar,
    notification,
    Badge,
    Divider,
    Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import ChartAmount from '../../components/Admin/ChartAmount/ChartAmount';
import TableHome from '../../components/Admin/TableHome/TableHome';
import GreetingsTop from '../../components/Admin/GreetingsTop/GreetingsTop';
import './Admin.scss';
import { getAccessTokenApi, logout } from '../../api/auth';
import {
    getBalanceSummaryApi,
    getPaymentComplementApi,
    getNoticesApi,
    getSummaryApi,
} from '../../api/billing';
import { formatMoney } from '../../utils/general';
import { messageError } from '../../utils/general';
import moment from 'moment';
import {
    ROL_MANAGER,
    ROL_FINANCIAL,
    ROL_SUPPLIER,
    ROL_CUSTOMER,
} from '../../utils/constants';
import useUser from '../../hooks/useUser';
import useStatusFilter from '../../hooks/useStatusFilter';
import AdminClient from '../PortalCliente/DashboardCustomer';
import CardsHome from '../../components/Logistic/CardsHome/CardsHome';

const Admin = () => {
    const [balanceSummary, setBalanceSumary] = useState({});
    const [paymentComplement, setPaymentComplement] = useState([]);
    const [notices, setNotices] = useState([]);
    const [summary, setSummary] = useState([]);
    const token = getAccessTokenApi();
    const [date, setDate] = useState([moment().subtract(7, 'days'), moment()]);
    const [monthDate, setMonthDate] = useState(null);
    const [loadindSaldos, setLoadindSaldos] = useState(true);
    const { person } = useUser();

    useEffect(() => {
        setLoadindSaldos(true);
        const dateBalance = [moment().startOf('month'), moment().endOf('month')]; 
        let dateSent = {
            startDate: moment
                .utc(dateBalance[0].hour(0).minute(0))
                .format('YYYY-MM-DD HH:mm'),
            endDate: moment
                .utc(dateBalance[1].hour(23).minute(59))
                .format('YYYY-MM-DD HH:mm'),
        };
        const filters = `?StartDate=${dateSent.startDate}&EndDate=${dateSent.endDate}`;
        
        getBalanceSummaryApi(token, filters).then((response) => {
            // console.log(response, 'balanceSummary');
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    const result = response.result ? response.result : {};
                    setBalanceSumary(result);
                }
            }
            setLoadindSaldos(false);
            
        });
        filterDate(date);
        getNoticesApi(token).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    let result = response.result.slice(0,5);
                    result.forEach((element, index) => {
                        element.key = index;
                    });
                    setNotices(result);
                }
            }
        });
        getPaymentComplementApi(token).then((response) => {
            // console.log(response, 'complementp');
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    let result = response.result.slice(0,5);
                    result.forEach((element, index) => {
                        element.key = index;
                    });
                    setPaymentComplement(result);
                }
            }
        });
    }, []);

    function filterDate(date, dateString) {
        if (date) {
            // setFilteredInfo({});
            // setSortedInfo({});
            let date2 = {
                startDate: moment
                    .utc(date[0].hour(0).minute(0))
                    .format('YYYY-MM-DD HH:mm'),
                endDate: moment
                    .utc(date[1].hour(23).minute(59))
                    .format('YYYY-MM-DD HH:mm'),
            };
            setMonthDate(date[0].format('M'));

            let filters = `?pIniDate=${date2.startDate}&pEndDate=${date2.endDate}`;
            // console.log(filters);
            getSummaryApi(token, filters).then((response) => {
                // console.log(response, 'response tabla');
                if (response === undefined) {
                    const msg = messageError();

                    notification['error']({
                        message: msg,
                    });
                    return;
                }
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                    return;
                } else if (response?.statusCode === 200) {
                    response.result.forEach((element, index) => {
                        element['key'] = index;
                    });
                    setSummary(response.result);
                }
            });
        }
    }

    function onPanelChange(value, mode) {
        console.log(value, mode);
    }

    function onDateChangeCalendar(date) {
        console.log(date);
    }

    function getListData(value) {
        let listData;

        // console.log(value.year());
        switch (value.date()) {
            case 8:
                listData = [
                    { type: 'warning', content: 'Tu cuenta esta por expirar' },
                    { type: 'success', content: 'Cuentas pagadas' },
                ];
                break;
            case 10:
                listData = [
                    { type: 'warning', content: 'Tu cuenta esta por expirar' },
                    { type: 'success', content: 'Cuentas pagadas' },
                    { type: 'error', content: 'Tienes cuentas pendientes' },
                ];
                break;
            case 15:
                listData = [
                    { type: 'warning', content: 'Tu cuenta esta por expirar' },
                    { type: 'success', content: 'Cuentas pagadas' },
                    { type: 'error', content: 'Tienes cuentas pendientes' },
                ];
                break;
            default:
        }
        return listData || [];
    }

    function dateCellRender(value) {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content} />
                    </li>
                ))}
            </ul>
        );
    }

    function getMonthData(value) {
        if (value.month() === 8) {
            return 'alerta';
        }
    }

    function monthCellRender(value) {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    }

    if([ROL_CUSTOMER].some((r) => person.roles.includes(r))){
        return <AdminClient />
    }

    return (
        <Layout className="admin">
            {/* <Row className="admin-top">
                <Col md={12} xs={24} className="admin-top__left">
                    <Title level={2}>¡Hola Gilberto!</Title>
                </Col>
                <Col md={12} xs={24} className="admin-top__right">
                    <p>{nowDate}</p>
                </Col>
            </Row>
            <Divider className="top-devider" /> */}

            <GreetingsTop />
            <CardsHome></CardsHome>
            {/* <div className="admin__container">
                <CardAmount balanceSummary={balanceSummary} loadindSaldos={loadindSaldos} />

                <ChartAmount
                    summary={summary}
                    filterDate={filterDate}
                    monthDate={monthDate}
                />
            </div> */}

            <Row className="admin__row">
                {/* <Col xs={24}>
                    <div className="admin__container" style={{textAlign: 'center'}}>
                    <Title level={4} style={{ color: 'gray' }}>
                        AVISOS
                    </Title>
                    </div>
                </Col> */}
                {/* <Col md={24} lg={12} xxl={12}>
                    <div className="admin__container">
                        <TableHome
                            title="AVISOS | FACTURAS Y NOTAS DE CRÉDITO"
                            dataTable={notices}
                        />
                    </div>
                </Col>
                <Col md={24} lg={12} xxl={12}>
                    <div className="admin__container">
                        <TableHome
                            dataTable={paymentComplement}
                            title="AVISOS | COMPLEMENTOS DE PAGO"
                        />
                    </div>
                </Col>
                <Col md={24} lg={24} xxl={24} style={{ textAlign: 'right' }}> */}
                    {/* <Divider> 
                        <Title level={4} style={{ color: 'gray' }}>
                            CALENDARIO
                        </Title>
                    </Divider> */}
                    {/*PARTE 2 CALENDARIO*/}
                    {/* <div className="admin__container">
                        <div className="site-calendar-demo-card">
                            <Calendar
                                fullscreen={true}
                                onPanelChange={onPanelChange}
                                onChange={onDateChangeCalendar}
                                dateCellRender={dateCellRender}
                                monthCellRender={monthCellRender}
                            />
                        </div>
                    </div> */}
                {/* </Col> */}
            </Row>
        </Layout>
    );
};

export default Admin;

function CardAmount({ balanceSummary, loadindSaldos }) {
    const { person } = useUser();
    const { statusFilter, setStatusFilter } = useStatusFilter();
    let history = useHistory();

    const gotoBill = (status) => {
        if(!loadindSaldos){
            const data = {
                type: status,
                dateStart: balanceSummary.startDate ? moment.utc(balanceSummary.startDate).local().format('YYYY-MM-DD') : "",
                dateEnd: balanceSummary.endDate ? moment.utc(balanceSummary.endDate).local().format('YYYY-MM-DD') : ""
            }
            setStatusFilter(data);
            history.push('/facturacion');
        }
    };

    return (
        <>
            {[ROL_MANAGER, ROL_FINANCIAL].some((r) =>
                person.roles.includes(r)
            ) && (
                <Row className="card-amount ">
                    <Col md={24} xs={24} sm={24} lg={8}>
                        <Card
                            className="animate__animated  animate__zoomIn"
                            onClick={() => {
                                gotoBill('Total');
                            }}
                        >
                            <Row>
                                <Col xs={6}>
                                    <div className="card-amount__circule green ">
                                        <span>$</span>
                                    </div>
                                </Col>
                                <Col xs={18}>
                                    <p className="card-amount__price green">
                                        ${' '}
                                        {formatMoney(
                                            balanceSummary?.totalBalance
                                        )}{' '}
                                        PESOS
                                    </p>
                                    <p className="card-amount__pays">
                                        Saldo Total
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col md={24} xs={24} sm={24} lg={8}>
                        <Card
                            onClick={() => {
                                gotoBill('Vigente');
                            }}
                            className="animate__animated  animate__zoomIn"
                        >
                            <Row>
                                <Col xs={6}>
                                    <div className="card-amount__circule orange">
                                        <span>$</span>
                                    </div>
                                </Col>
                                <Col xs={18}>
                                    <p className="card-amount__price orange">
                                        ${' '}
                                        {formatMoney(
                                            balanceSummary?.currentBalance
                                        )}{' '}
                                        {balanceSummary?.currencyId}
                                    </p>
                                    <p className="card-amount__pays">
                                        Saldo Vigente
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col md={24} xs={24} sm={24} lg={8}>
                        <Card
                            onClick={() => {
                                gotoBill('Vencido');
                            }}
                            className="animate__animated  animate__zoomIn"
                        >
                            <Row>
                                <Col xs={6}>
                                    <div className="card-amount__circule red">
                                        <span>$</span>
                                    </div>
                                </Col>
                                <Col xs={18}>
                                    <p className="card-amount__price red">
                                        ${' '}
                                        {formatMoney(
                                            balanceSummary?.dueBalance
                                        )}{' '}
                                        {balanceSummary?.currencyId}
                                    </p>
                                    <p className="card-amount__pays">
                                        Saldo Vencido
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )}
            {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
                <Row className="card-amount ">
                    <Col md={24} xs={24} sm={24} lg={8}>
                        <Card
                            onClick={() => {
                                gotoBill('Pagado');
                            }}
                            className="animate__animated  animate__zoomIn"
                        >
                            <Row>
                                <Col xs={6}>
                                    <div className="card-amount__circule orange">
                                        <span>$</span>
                                    </div>
                                </Col>
                                <Col xs={18}>
                                    <p className="card-amount__price orange">
                                        ${' '}
                                        {formatMoney(
                                            balanceSummary?.totalBalancePaid
                                        )}{' '}
                                        {balanceSummary?.currencyId}
                                    </p>
                                    <p className="card-amount__pays">Pagos</p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col md={24} xs={24} sm={24} lg={8}>
                        <Card
                            onClick={() => {
                                gotoBill('Vigente');
                            }}
                            className="animate__animated  animate__zoomIn"
                        >
                            <Row>
                                <Col xs={6}>
                                    <div className="card-amount__circule red">
                                        <span>$</span>
                                    </div>
                                </Col>
                                <Col xs={18}>
                                    <p className="card-amount__price red">
                                        ${' '}
                                        {formatMoney(
                                            balanceSummary?.scheduledTotalToBePaid
                                        )}{' '}
                                        {balanceSummary?.currencyId}
                                    </p>
                                    <p className="card-amount__pays">
                                        Pagos Programados
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    );
}
