import { Button, Table, Modal as ModalAntd, Col, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Modal from '../../../../General/Modal';
import AddDimensionsForm from '../AddDimensionsForm/AddDimensionsForm';
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

const DimensionsTable = ({
  disableAllInputs,
  pieces,
  setPieces,
  linesData,
  setLinesData,
  saveKey,
  linea,
  type,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [reloadTable, setReloadTable] = useState(false);
  const { confirm } = ModalAntd;
  useEffect(() => {
    setReloadTable(false);
  }, []);
  const columns = [
    {
      title: 'Piezas',
      dataIndex: 'piecesNumber',
      key: 'piecesNumber',
    },
    {
      title: 'Embalaje',
      dataIndex: 'packagingLabel',
      key: 'packagingLabel',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Largo',
      dataIndex: 'length',
      key: 'length',
    },
    {
      title: 'Ancho',
      dataIndex: 'width',
      key: 'width',
    },
    {
      title: 'Alto',
      dataIndex: 'height',
      key: 'height',
    },
    {
      title: 'Peso(kg)',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'CBM',
      dataIndex: 'cbm',
      key: 'cbm',
    },
    {
      title: 'Total peso(kg)',
      dataIndex: 'totalWeight',
      key: 'totalWeight',
    },
    {
      title: 'Total CBM',
      dataIndex: 'totalCBM',
      key: 'totalCBM',
    },
    {
      title: 'Aciones',
      key: 'acc',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (dimensionRow) => (
        <>
        {type !== 'rfq' && (
        <>
          <Tooltip title='Editar'>
            <Button
              style={{ marginLeft: 2 }}
              type='primary'
              shape='default'
              icon={<EditOutlined />}
              disabled={disableAllInputs}
              onClick={() => showModal('Edit', dimensionRow)}
            />
          </Tooltip>
          {/* <Tooltip title="Eliminar">
                        <Button style={{marginLeft: 10}} type="primary" danger shape="default" icon={<DeleteOutlined /> } />
                    </Tooltip> */}
          <Tooltip title='Eliminar'>
            <Button
              style={{ marginLeft: 10 }}
              type='primary'
              ghost
              shape='default'
              disabled={disableAllInputs}
              icon={<DeleteOutlined />}
              onClick={() => handleDeletRow(dimensionRow)}
            />
          </Tooltip>
        </>
        )}
        </>
      ),
    },
  ];
  const showModal = (type, dimensionRow) => {
    setModalIsVisible(true);
    setTitleModal(type === 'Add' ? 'Agregar piezas' : 'Editar piezas');
    setContextModal(
      <AddDimensionsForm
        setModalIsVisible={setModalIsVisible}
        setPieces={setPieces}
        pieces={pieces}
        linesData={linesData}
        setLinesData={setLinesData}
        saveKey={saveKey}
        type={type}
        dimensionRow={dimensionRow}
        linea={linea}
      ></AddDimensionsForm>
    );
  };

  const handleDeletRow = (dimensionRow) => {
    confirm({
      title: 'Eliminar linea de piezas',
      icon: <ExclamationCircleOutlined />,
      content:
        '¿Estás seguro que deseas borrar la linea de piezas seleccionada?, no podrá recuperarla después',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        if (saveKey !== 'nl') {
          const parsekey = parseInt(saveKey);
          const { pieces } = linesData[parsekey];
          let copy = [...pieces];

          const found = copy.findIndex((_, i) => i === dimensionRow.key);
          copy.splice(found, 1);
          setPieces(copy);

          const linesDataCopy = [...linesData];
          linesDataCopy[parsekey].pieces = copy;
          setLinesData(linesDataCopy);
        } else {
          let copy = [...pieces];
          const found = copy.findIndex((_, i) => i === dimensionRow.key);
          copy.splice(found, 1);
          setPieces(copy);
        }
      },
      onCancel() {},
    });
  };
  return (
    <div>
      <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {type !== 'rfq' && (
          <Button
          onClick={() => showModal('Add')}
          type='primary'
          style={{
            marginBottom: 16,
          }}
          disabled={disableAllInputs}
        >
          Agregar piezas
        </Button>
        )}
      </Col>

      <Table
        // onChange={handleChange}
        columns={columns}
        dataSource={pieces?.map((p, i) => ({ ...p, key: i }))}
        // scroll={{ x: 500 }}
        bordered
        size='middle'
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className='table-striped-rows'
        loading={reloadTable}
        // loading={reloadClients}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default DimensionsTable;
