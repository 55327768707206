import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { QuotePdf } from './QuotePdf';
import useUser from '../../../../hooks/useUser';
import { METHOD_GET } from '../../../../utils/constants';
import { API_URL_QUOTE_PDF } from '../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';

const ViewPdf = () => {
  const { quoteId } = useParams();
  const { person } = useUser();
  const token = getAccessTokenApi();
  const [quotePdf, setQuotePdf] = useState(null);
  let history = useHistory();

  useEffect(() => {
    const generatePdfHandle = async (id) => {
      const config = {
        method: METHOD_GET,
        url: `${API_URL_QUOTE_PDF(id)}`,
        data: null,
        token,
        // showNotification: true,
      };
      const response = await fetchApi(config);

      if (response?.statusCode === 200) {
        setQuotePdf(response.result);
      }
    };
    generatePdfHandle(quoteId);
  }, [quoteId, token]);

  return (
    <>
      {/* <HeaderTop /> */}
      <Button type='link' onClick={() => history.push('/cotizacion')}>
        <ArrowLeftOutlined /> Atrás
      </Button>
      <PDFViewer width={'100%'} height={'100%'}>
        <QuotePdf user={person} quote={quotePdf} />
      </PDFViewer>
    </>
  );
};

export default ViewPdf;

// function HeaderTop() {
//   const { Title } = Typography;
//   let history = useHistory();
//   let { quoteId } = useParams();

//   return (
//     <div className='clients__container'>
//       <Row xs={23} className='header-top'>
//         <Col xs={19} className='header-top__left'>
//           <Title level={3}>Lineas Cotización {quoteId}</Title>
//         </Col>
//         <Col xs={1}>
//           <Button type='link' onClick={() => history.push('/cotizacion')}>
//             <ArrowLeftOutlined /> Atrás
//           </Button>
//         </Col>
//       </Row>
//     </div>
//   );
// }
