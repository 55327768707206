import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal as ModalAntd,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import AddQuoteForm from '../Quote/AddQuoteForm';
import Modal from '../../General/Modal';
import { useHistory } from 'react-router';
import { METHOD_DELETE, METHOD_GET } from '../../../utils/constants';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import { API_URL_QUOTE } from '../../../helpers/urls';
import moment from 'moment';

const Quote = ({ pickQuote, dateRange }) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const history = useHistory();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const token = getAccessTokenApi();
  const [reloadTable, setReloadTable] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const textModal = '¿Estás seguro que deseas borrar esta cotización';

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setIsLoadingTable(true);
    let filters = `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;

    const config = {
      method: METHOD_GET,
      url: `${API_URL_QUOTE}${filters}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allQuotes = response.result;
          allQuotes.map((quote) => (quote['key'] = quote.id));
          if (pickQuote) {
            const completeQuotes = allQuotes.filter(
              (quote) => quote.isCompleted
            );
            setQuotes(completeQuotes);
          } else {
            setQuotes(allQuotes);
          }
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, dateRange]);

  const showModal = (type, quote) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar cotización');
      setContextModal(
        <AddQuoteForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          setReloadTable={setReloadTable}
        ></AddQuoteForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar cotización #${quote}`);
      setContextModal(
        <AddQuoteForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          quoteId={quote}
          setReloadTable={setReloadTable}
        ></AddQuoteForm>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`Cotización #${quote}`);
      setContextModal(
        <AddQuoteForm
          type='watch'
          setModalIsVisible={setModalIsVisible}
          quoteId={quote}
          // setReloadQuote={setReloadQuote}
        ></AddQuoteForm>
      );
    }
  };

  const columns = [
    {
      title: 'No. / Id cotización',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      align: 'center',
      render: (id, quote) => (
        <span>{quote.quoteIdStr ? quote.quoteIdStr : id}</span>
      ),
      // fixed: 'left',
    },
    {
      title: 'Cliente',
      dataIndex: 'customerId',
      key: 'customerId',
      width: 100,
      align: 'center',
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      align: 'center',
      render: (status) => (
        <Tag color={status === 'active' ? 'green' : 'red'}>
          {status === 'active' ? 'Activa' : 'Inactiva'}
        </Tag>
      ),
    },
    {
      title: 'Vigencia',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (endDate) => <span>{`${moment.unix(endDate).format('L')}`}</span>,
    },
    {
      title: 'Completa',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      width: 80,
      align: 'center',
      filters: [
        {
          text: 'Completada',
          value: true,
        },
        { text: 'Borrador', value: false },
      ],
      onFilter: (value, record) => record.isCompleted === value,
      render: (isCompleted) => (
        <Tag
          icon={isCompleted ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          color={isCompleted ? 'green' : 'red'}
        >
          {isCompleted ? 'Completa' : 'Incompleta'}
        </Tag>
      ),
    },
    // {
    //     title: 'Usuario',
    //     dataIndex: 'createdBy',
    //     key: 'createdBy',
    //     width: 100,
    //     align: 'center',
    // },
    {
      title: 'Líneas',
      dataIndex: 'quoteLines',
      key: 'quoteLines',
      width: 30,
      align: 'center',
      fixed: 'right',

      render: (quoteLines, quote) => (
        <>
          <Tooltip title='Líneas'>
            <Button
              style={{ marginLeft: 2 }}
              type='primary'
              shape='default'
              onClick={() => history.push(`/lineas/cotizacion/${quote.id}`)}
            >
              {quoteLines.length}
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Acciones',
      key: 'quote',
      fixed: 'right',
      width: 50,
      align: 'center',
      render: (quote) => (
        <>
          {quote.isCompleted !== true && (
            <Tooltip title='Editar'>
              <Button
                style={{ marginLeft: 2 }}
                type='primary'
                shape='default'
                icon={<EditOutlined />}
                onClick={() => showModal('Edit', quote.id)}
              />
            </Tooltip>
          )}

          {/* <Tooltip title="Eliminar">
                        <Button style={{marginLeft: 10}} type="primary" danger shape="default" icon={<DeleteOutlined /> } />
                    </Tooltip> */}
          <Tooltip title='Ver más información'>
            <Button
              style={{ marginLeft: 10 }}
              type='primary'
              ghost
              shape='default'
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', quote.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const deleteQuote = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_QUOTE}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
        setSelectedRowKeys([]);
      }
    }
  };

  const showDeleteConfirm = (id, description = '') => {
    confirm({
      title: `${textModal}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteQuote(id);
      },
      onCancel() {},
    });
  };

  return (
    <div>
      <Button
        className='btn-download'
        onClick={() => history.push('/cotizacion/nueva')}
        icon={<PlusCircleOutlined />}
      >
        Agregar
      </Button>
      <Button
        // disabled={!hasSelected}
        disabled={selectedRowKeys.length !== 1}
        className='btn-delete'
        style={{ marginBottom: 10 }}
        onClick={() => showDeleteConfirm(selectedRowKeys[0])}
        icon={<DeleteOutlined />}
      >
        Eliminar
      </Button>
      <Button
        className='btn-download'
        icon={<ReloadOutlined />}
        onClick={() => setReloadTable(true)}
      >
        Refrescar
      </Button>
      {selectedRowKeys.length > 0 && (
        <p>Seleccionados: {selectedRowKeys.length} elementos</p>
      )}
      {/* <Button className="btn-download" onClick={() => showModal('Edit')}>
                Clientes
            </Button> */}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={quotes}
        columns={columns}
        bordered
        size='middle'
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className='table-striped-rows'
        rowSelection={rowSelection}
        loading={isLoadingTable || reloadTable}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default Quote;
