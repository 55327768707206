import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';

const Location = ({
  onChangeCountryOrigin,
  countries,
  onChangeState,
  states,
  cities,
  disableAllInputs,
}) => {
  return (
    <div className='location-client'>
      <Row gutter={16} className='table-company'>
        <Col xs={24} md={12} lg={8}>
          <Form.Item label='País' name='country'>
            <Select
              placeholder='Selecciona una opción'
              onChange={onChangeCountryOrigin}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Form.Item label='Estado:' name='state'>
            <Select
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChangeState}
              disabled={disableAllInputs}
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Form.Item label='Ciudad:' name='city'>
            <Select
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={disableAllInputs}
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={16}>
          <Form.Item label='Dirección:' name='address'>
            <Input
              placeholder='Ingresa tu información'
              disabled={disableAllInputs}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12} lg={8}>
          <Form.Item label='CP:' name='zipCode'>
            <Input
              placeholder='Ingresa tu información'
              disabled={disableAllInputs}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Location;
