/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
   Row,
   Col,
   Table,
   Button,
   notification,
   Spin,
   Input,
   Space,
   Modal as ModalAntd,
   Checkbox,
   Tooltip,
   Tag,
} from 'antd';
import { SearchOutlined, TeamOutlined, EyeOutlined } from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';
import { getCustomersFinancialApi } from '../../../../api/financial';
import useUser from '../../../../hooks/useUser';
import { deleteClientApi, getAllCustomersApi } from '../../../../api/clients';
import { ROL_MANAGER } from '../../../../utils/constants';
import AddClientForm from '../../../Admin/Administration/ClientsTable/AddClientForm/AddClientForm';
import EditClientForm from '../../../Admin/Administration/ClientsTable/EditClientForm/EditClientForm';
import Modal from '../../../General/Modal';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { messageErrorGeneral } from '../../../../utils/general';

export default function CustomersTable(props) {
   const { pickCustomer, setClientSelectedQoute, setModalIsVisibleQuote } =
      props;
   const [reloadCustomers, setReloadCustomers] = useState(true);
   const [usersTable, setUsersTable] = useState([]);
   const [loadingForm, setLoadingForm] = useState(false);
   const [filteredInfo, setFilteredInfo] = useState({});
   const [searchText, setSearchText] = useState('');
   const [searchedColumn, setSearchedColumn] = useState('');
   const [modalIsVisible, setModalIsVisible] = useState(false);
   const [titleModal, setTitleModal] = useState(false);
   const [contextModal, setContextModal] = useState(null);
   const [clientSelected, SetClientSelected] = useState(null);
   const { confirm } = ModalAntd;
   const history = useHistory();
   const { person } = useUser();

   useEffect(() => {
      if (reloadCustomers) {
         const token = getAccessTokenApi();
         setLoadingForm(true);
         if (person?.roles.includes(ROL_MANAGER)) {
            getAllCustomersApi(token).then((response) => {
               if (response) {
                  if (response.statusCode === 200) {
                     response.result.forEach((element, index) => {
                        element['key'] = index;
                     });
                     setUsersTable(response.result);
                  }
               } else {
                  if (response?.statusCode === 401) {
                     notification['error']({
                        message: 'Usuario no autorizado',
                     });

                     setTimeout(() => {
                        logout();
                        window.location.reload();
                     }, 1500);
                     return;
                  }

                  notification['error']({
                     message: messageErrorGeneral(),
                  });
               }
               setReloadCustomers(false);
               setLoadingForm(false);
            });
         } else {
            getCustomersFinancialApi(token, person.idUser).then((response) => {
               if (response) {
                  if (response.statusCode === 401) {
                     notification['error']({
                        message: 'Usuario no autorizado',
                     });

                     setTimeout(() => {
                        logout();
                        window.location.reload();
                     }, 1500);
                     return;
                  }
                  if (response.statusCode === 200) {
                     response.result.forEach((element, index) => {
                        element['key'] = index;
                     });
                     setUsersTable(response.result);
                  }
                  setReloadCustomers(false);
                  setLoadingForm(false);
               } else {
                  notification['error']({
                     message: messageErrorGeneral(),
                  });
                  setReloadCustomers(false);
                  setLoadingForm(false);
               }
            });
         }
      }
   }, [reloadCustomers]);

   const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
      }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={(node) => {
                  // this.searchInput = node;
               }}
               placeholder={`Buscar`}
               value={selectedKeys[0]}
               onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
               }
               onPressEnter={() =>
                  handleSearch(selectedKeys, confirm, dataIndex)
               }
               style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type='primary'
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size='small'
                  style={{ width: 90 }}
               >
                  Buscar
               </Button>
               <Button
                  onClick={() => {
                     handleReset(clearFilters);
                     handleSearch(selectedKeys, confirm, dataIndex);
                  }}
                  size='small'
                  style={{ width: 90 }}
               >
                  Limpiar
               </Button>
            </Space>
         </div>
      ),
      filterIcon: (filtered) => (
         <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex]
                 .toString()
                 .toLowerCase()
                 .includes(value.toLowerCase())
            : '',
      filteredValue: filteredInfo[dataIndex] || null,
      onFilterDropdownVisibleChange: (visible) => {},
      render: (text) =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });
   const checkClient = (id, client) => {
      // console.log(client);
      if (clientSelected === id) {
         SetClientSelected(null);
         if (pickCustomer) {
            setClientSelectedQoute(null);
         }
      } else {
         SetClientSelected(id);
         if (pickCustomer) {
            setClientSelectedQoute(client);
         }
      }
   };
   const columns = [
      {
         title: 'Selecciona',
         width: 100,
         dataIndex: 'id',
         key: 'company',
         render: (id, client) => (
            <Checkbox
               // disabled={!clientsTable?.find((st) => st.id === id)?.active}
               onChange={(val) => checkClient(id, client)}
               checked={clientSelected === id}
               className='button-detail'
            ></Checkbox>
         ),
      },
      {
         title: 'Id cliente',
         width: 150,
         dataIndex: 'id',
         key: 'id',
         ...getColumnSearchProps('id'),
         render: (id) => id?.split('-')[0],
         ellipsis: true,
      },
      {
         title: 'Nombre(s)',
         dataIndex: 'name',
         key: 'name',
         ...getColumnSearchProps('name'),
         width: 250,
         ellipsis: true,
      },
      {
         title: 'Estatus',
         dataIndex: 'active',
         key: 'active',
         align: 'center',
         width: 100,
         // filters: [
         //    {
         //       text: 'Activo',
         //       value: true,
         //    },
         //    { text: 'Inactivo', value: false },
         // ],
         // onFilter: (value, record) => record.active == value,
         ellipsis: true,
         render: (active) => (
            <Tag color={active ? 'green' : 'red'}>
               {active ? 'ACTIVO' : 'INACTIVO'}
            </Tag>
         ),
      },
      {
         title: 'RFC',
         dataIndex: 'fiscalID',
         key: 'fiscalID',
         ...getColumnSearchProps('fiscalID'),
         width: 150,
         ellipsis: true,
      },
      {
         title: 'Contactos',
         key: 'contacts',
         fixed: 'right',
         ellipsis: true,
         width: pickCustomer ? 0 : 80,
         align: 'center',
         render: (contact, contactId) => (
            <>
               <Tooltip title='Contactos'>
                  <Button
                     style={{ marginLeft: 2 }}
                     type='primary'
                     shape='default'
                     icon={<TeamOutlined />}
                     onClick={() =>
                        history.push(`/clientes/contactos/${contact.id}`)
                     }
                     disabled={pickCustomer}
                  />
               </Tooltip>
            </>
         ),
      },
      {
         title: 'Ver',
         key: 'sup',
         fixed: 'right',
         width: pickCustomer ? 0 : 50,
         align: 'center',
         render: (client) => (
            <>
               <Tooltip title='Ver más información'>
                  <Button
                     style={{ marginLeft: 10 }}
                     type='primary'
                     ghost
                     shape='default'
                     icon={<EyeOutlined />}
                     onClick={() => showModal('Watch', client)}
                  />
               </Tooltip>
            </>
         ),
      },
   ];

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
   };

   const handleChange = (pagination, filters, sorter, extra) => {
      setFilteredInfo(filters);
   };

   const showModal = (type, client) => {
      if (type === 'Add') {
         setModalIsVisible(true);
         setTitleModal('Agregar cliente');
         setContextModal(
            <AddClientForm
               setModalIsVisible={setModalIsVisible}
               setReloadClients={setReloadCustomers}
            ></AddClientForm>
         );
      } else if (type === 'Edit') {
         if (clientSelected !== null) {
            setModalIsVisible(true);

            setTitleModal('Editar cliente');
            setContextModal(
               <EditClientForm
                  setModalIsVisible={setModalIsVisible}
                  setReloadClients={setReloadCustomers}
                  idClient={clientSelected}
               ></EditClientForm>
            );
         } else if (clientSelected === null) {
            notification['error']({
               message: 'Debes seleccionar un cliente',
            });
         }
      } else if (type === 'Watch') {
         setModalIsVisible(true);
         setTitleModal(`Cliente ${client.name}`);
         setContextModal(
            <EditClientForm
               setModalIsVisible={setModalIsVisible}
               setReloadSuppliers={setReloadCustomers}
               idClient={client.id}
               type='watch'
            ></EditClientForm>
         );
      }
   };
   const handleDeleteClient = (id) => {
      if (clientSelected !== null) {
         confirm({
            title: 'Desactivar cliente',
            icon: <ExclamationCircleOutlined />,
            content:
               '¿Estás seguro que deseas desactivar el cliente seleccionado?, no podrá reactivarse después',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
               deleteClient(id);
            },
            onCancel() {},
         });
      } else {
         notification['error']({
            message: 'Debes seleccionar un usuario a desactivar',
         });
      }
   };

   const deleteClient = async (id) => {
      const accessToken = getAccessTokenApi();
      const result = await deleteClientApi(accessToken, id);
      if (result?.statusCode === 409) {
         notification['error']({
            message: result.description,
         });
      }
      if (result?.statusCode === 405) {
         notification['error']({
            message: result.description,
         });
      }
      if (result?.statusCode === 404) {
         notification['error']({
            message: result.description,
         });
      }
      if (result?.statusCode === 200) {
         notification['success']({
            message: 'Usuario desactivado exitosamente.',
         });
         SetClientSelected(undefined);
      }
      setReloadCustomers(true);
   };

   return (
      <>
         <Spin spinning={loadingForm} tip='Cargando...'>
            {!pickCustomer && (
               <Row>
                  <Col span={24}>
                     <Button
                        className='btn-download'
                        onClick={() => showModal('Add')}
                     >
                        Agregar
                     </Button>

                     <Button
                        className='btn-download'
                        onClick={() => showModal('Edit')}
                     >
                        Editar
                     </Button>
                     <Button
                        className='btn-delete'
                        onClick={() => handleDeleteClient(clientSelected)}
                     >
                        Desactivar
                     </Button>
                  </Col>
               </Row>
            )}
            <Table
               onChange={handleChange}
               columns={columns}
               dataSource={usersTable}
               loading={reloadCustomers}
               scroll={{ x: 500 }}
               // pagination={{ defaultPageSize: 5 }}
               
            />
            {pickCustomer && (
               <div className='footer-table'>
               <Button
                  onClick={() => setModalIsVisibleQuote(false)}
                  type='primary'
               >
                  Aceptar
               </Button>
               </div>
            )}
            <Modal
               title={titleModal}
               visible={modalIsVisible}
               setIsVisible={setModalIsVisible}
               width={1000}
            >
               {contextModal}
            </Modal>
         </Spin>
      </>
   );
}
