import React, { useEffect, useState } from 'react';
import {
    Table,
    Row,
    Col,
    Checkbox,
    Input,
    Form,
    Spin,
    Button,
    notification,
} from 'antd';
import { FileAddOutlined, PaperClipOutlined } from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import { useParams, useHistory } from 'react-router-dom';
import {
    getClarificationDetailApi,
    getClarificationApi,
    editClarificationApi,
} from '../../../../api/clarifications';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import Modal from '../../../General/Modal';
import AddCommentForm from '../AddCommentForm/AddCommentForm';
import useUser from '../../../../hooks/useUser';
import { ROL_FINANCIAL, ROL_SUPPLIER } from '../../../../utils/constants';
import AddDocumentsForm from '../AddDocumentsForm';

import './ClarificationDetail.scss';

export default function ClarificationDetail() {
    const [loadingForm, setLoadingForm] = useState(false);
    let { idAclaracion } = useParams();
    const token = getAccessTokenApi();
    const [statusClarification, setStatusClarification] = useState(true);
    const [comments, setComments] = useState(null);
    const [idClarification, setIdClarification] = useState(null);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [form] = Form.useForm();
    const { person } = useUser();
    let history = useHistory();

    useEffect(() => {
        if (token && idAclaracion) {
            getClarificationApi(token, idAclaracion).then((response) => {
                // console.log(response, 'get aclaraciones');
                if (response?.statusCode === 200) {
                    // setClarificationsTable(response.result);
                    setStatusClarification(response.result.status);
                    form.setFieldsValue({
                        reference: response.result.reference,
                        Folio: response.result.id,
                        Description: response.result.description,
                        Title: response.result.title,
                        StatusV: !response.result.status,
                    });
                    setIdClarification(response.result.id);
                }
            });
            getClarificationDetailApi(token, idAclaracion).then((response) => {
                // console.log(response, 'comentarios');
                if (response?.statusCode === 200) {
                    response?.result.forEach((element, index) => {
                        element['key'] = index;
                    });

                    let comentarios = response.result.sort(
                        (a, b) =>
                            new Date(b.creationDate) - new Date(a.creationDate)
                    );
                    setComments(comentarios);
                }
            });
        }
    }, [token, idAclaracion, statusClarification, form, modalIsVisible]);

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'creationDate',
            key: 'age',
            render: (creationDate) => (
                <>{moment(creationDate).format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Comentarios',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Nombre',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Documentos',
            dataIndex: 'description',
            key: '3',
            //width: 50,
            render: (statusText, record) => (
                <>
                    <Button
                        type="primary"
                        icon={<FileAddOutlined />}
                        size="small"
                        onClick={() => {
                            addDocumentModal(record.id);
                        }}
                    ></Button>
                    <Button
                        type="primary"
                        ghost
                        size="small"
                        icon={<PaperClipOutlined />}
                        onClick={() => {
                            history.push(`/aclaraciones/archivos/${record.id}`);
                        }}
                        style={{ marginLeft: 15 }}
                    ></Button>
                </>
            ),
        },
    ];

    const addDocumentModal = (id) => {
        setTitleModal(`Agregar archivos del comentario`);
        setModalIsVisible(true);
        setContextModal(
            <AddDocumentsForm
                setModalIsVisible={setModalIsVisible}
                noteId={id}
            ></AddDocumentsForm>
        );
    };

    const sendClarification = async (values) => {
        // console.log(values);
        setLoadingForm(true);
        let data = values;
        data['id'] = values.Folio;
        data['source'] = 'Invoice';
        data['status'] = !values.StatusV;

        const response = await editClarificationApi(token, data, values.Folio);
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            setLoadingForm(false);

            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            setLoadingForm(false);

            return false;
        }
        if (response.statusCode === 200) {
            notification['success']({
                message: 'Aclaración editada con éxito',
            });
            setStatusClarification(response.result.status);
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.status === 400) {
                messageError = response.errors;
            }
            notification['error']({
                message: messageError,
            });
        }
        setLoadingForm(false);
    };
    const showModal = (id) => {
        setModalIsVisible(true);
        setTitleModal('Agregar comentario');
        setContextModal(
            <AddCommentForm
                setModalIsVisible={setModalIsVisible}
                idClarification={id}
            ></AddCommentForm>
        );
    };

    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="add-clarification"
                layout="vertical"
                form={form}
                onFinish={sendClarification}
            >
                <Row xs={24}>
                    <Col xs={12} md={8} lg={4}>
                        <Form.Item
                            label="Referencia o factura"
                            name="reference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese referencia o factura',
                                },
                            ]}
                        >
                            <Input disabled placeholder="4232" />
                        </Form.Item>
                    </Col>
                    <Col xs={8} md={8} lg={4} style={{ marginLeft: 10 }}>
                        <Form.Item
                            label="Folio"
                            name="Folio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese folio',
                                },
                            ]}
                        >
                            <Input disabled placeholder="4232" />
                        </Form.Item>
                    </Col>
                    <Row
                        xs={2}
                        style={{
                            marginLeft: 0,
                        }}
                    >
                        {statusClarification && (
                            <>
                                {' '}
                                <div id="circulo"></div> <p>Abierto</p>
                            </>
                        )}
                        {!statusClarification && (
                            <>
                                {' '}
                                <div id="circulo-false"></div> <p>Cerrado</p>
                            </>
                        )}
                    </Row>
                </Row>
                <Row>
                    <Col md={10} lg={12} xs={24}>
                        <Form.Item
                            label="Summary/Titulo de la Aclaración"
                            name="Title"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un Summary/Titulo de la Aclaración',
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label="Descripción"
                            name="Description"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese una descripción',
                                },
                            ]}
                        >
                            <TextArea
                                disabled
                                placeholder="Ingrese su descripción máximo 300 caracteres"
                                maxLength={300}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row xs={24}>
                    <Col xs={12}>
                        <Form.Item
                            label="Referencia o factura"
                            name="reference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese referencia o factura',
                                },
                            ]}
                        >
                            <Input disabled placeholder="4232" />
                        </Form.Item>
                    </Col>
                    <Col xs={8} style={{ marginLeft: 10 }}>
                        <Form.Item
                            label="Folio"
                            name="Folio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese folio',
                                },
                            ]}
                        >
                            <Input disabled placeholder="4232" />
                        </Form.Item>
                    </Col>
                    <Row
                        xs={2}
                        style={{
                            marginLeft: 0,
                        }}
                    >
                        {statusClarification && (
                            <>
                                {' '}
                                <div id="circulo"></div> <p>Abierto</p>
                            </>
                        )}
                        {!statusClarification && (
                            <>
                                {' '}
                                <div id="circulo-false"></div> <p>Cerrado</p>
                            </>
                        )}
                    </Row>
                </Row>
                <Col xs={21}>
                    <Form.Item
                        label="Descripción"
                        name="Description"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese una descripción',
                            },
                        ]}
                    >
                        <TextArea
                            disabled
                            placeholder="Ingrese su descripción máximo 150 caracteres"
                            maxLength={150}
                        />
                    </Form.Item>
                </Col> */}
                <Row>
                    {[ROL_FINANCIAL].some((r) => person.roles.includes(r)) && (
                        <>
                            <p>Cerrar aclaración</p>
                            <Form.Item
                                style={{ marginBottom: 20 }}
                                name="StatusV"
                                layout="horizontal"
                                valuePropName="checked"
                            >
                                <Checkbox style={{ marginLeft: 10 }}></Checkbox>
                            </Form.Item>

                            <Col md={6} xs={24}>
                                <Form.Item>
                                    <Button
                                        htmlType="submit"
                                        className="btn-enviar"
                                        block
                                    >
                                        Guardar
                                    </Button>
                                </Form.Item>

                                {/* <Button className="btn-cancelar">Cancelar</Button> */}
                            </Col>
                        </>
                    )}

                    {statusClarification === true && (
                        <Col md={6} xs={24}>
                            <Form.Item>
                                <Button
                                    className="btn-enviar"
                                    block
                                    onClick={() => showModal(idClarification)}
                                    style={{ marginLeft: 0 }}
                                >
                                    Agregar comentario
                                </Button>
                            </Form.Item>

                            {/* <Button className="btn-cancelar">Cancelar</Button> */}
                        </Col>
                    )}
                </Row>
            </Form>
            <div>
                {comments !== null && (
                    <Table
                        columns={columns}
                        dataSource={comments}
                        scroll={{ x: 500 }}
                        pagination={{ defaultPageSize: 5 }}
                    />
                )}
            </div>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </Spin>
    );
}
