import React from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import ClientsTable from '../../../../components/Admin/Administration/ClientsTable';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './Clients.scss';

export default function Clients() {
   return (
      <Layout>
         <GreetingsTop />

         <HeaderTop />

         <div className='clients__container'>
            <ClientsTable />
         </div>
      </Layout>
   );
}

function HeaderTop() {
   const { Title } = Typography;
   let history = useHistory();

   return (
      <div className='clients__container'>
         <Row xs={23} className='header-top'>
            <Col xs={19} className='header-top__left'>
               <Title level={3}>Clientes</Title>
            </Col>
            <Col xs={1}>
               <Button type='link' onClick={() => history.goBack()}>
                  <ArrowLeftOutlined /> Atrás
               </Button>
            </Col>
         </Row>
      </div>
   );
}
