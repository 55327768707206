import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Form, Spin, Button, Tag, notification } from 'antd';
import { DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AddDocumentsForm from '../AddDocumentsForm';
import Modal from '../../../General/Modal';
import './OperationDetails.scss';
import { formatMoney } from '../../../../utils/general';
import FileProducts from '../../../Admin/FileProducts/FileProducts';
import { getDocumentsInfoApi } from '../../../../api/operations';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { ROL_LAYOUT } from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import useStatusFilter from '../../../../hooks/useStatusFilter';

export default function OperationDetail({ infoOperation }) {
    const [loadingForm, setLoadingForm] = useState(false);
    let history = useHistory();
    const [form] = Form.useForm();
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [dataTable, setDataTable] = useState(infoOperation.details);
    const [documents, setDocuments] = useState([]);
    const [reloadDocuments, setReloadDocuments] = useState(false)
    const { person } = useUser();

    useEffect(() => {
        infoOperation.details.forEach((info, index) => {
            info['key'] = index;
            setDataTable(dataTable);
        });
    }, [infoOperation]);

    useEffect(() => {
        const token = getAccessTokenApi();
        const data = {
            reference: infoOperation.id,
            documentType: 'Evidence',
        };
        getDocumentsInfoApi(token, data).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                if (response.statusCode === 200) {
                    const result = response.result;
                    setDocuments(result);
                    setReloadDocuments(false)
                }
            }
        });
    }, [reloadDocuments]);

    const columns = [
        {
            title: 'No. de parte',
            dataIndex: 'partNum',
            key: 'partNum',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Cantidad',
            dataIndex: 'operationQuantity',
            key: 'operationQuantity',
        },
        {
            title: 'Unidad',
            dataIndex: 'unitQuantity',
            key: 'unitQuantity',
            render: (unitQuantity) => (
                <p>{unitQuantity ? unitQuantity : '-'}</p>
            ),
        },
        {
            title: 'Precio',
            dataIndex: 'price',
            key: 'price',
            render: (unitQuantity) => <p>{`$ ${formatMoney(unitQuantity)}`}</p>,
        },
        {
            title: 'Moneda',
            dataIndex: 'currencyId',
            key: 'currencyId',
        },
    ];

    const showModal = (id) => {
        setTitleModal('Agregar documentos');
        setModalIsVisible(true);
        setContextModal(
            <AddDocumentsForm
                setModalIsVisible={setModalIsVisible}
                operationId={id}
                setReloadDocuments={setReloadDocuments}
            ></AddDocumentsForm>
        );
    };

    const showModalFile = () => {
        setModalIsVisible(true);
        setTitleModal('Subir Layout - Detalle de la operación');
        setContextModal(
            <FileProducts setIsVisible={setModalIsVisible} type="" />
        );
    };
    const sendClarification = async (values) => {};
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                name="add-promo"
                layout="vertical"
                form={form}
                onFinish={sendClarification}
            >
                <Row xs={24} style={{ marginBottom: 10 }}>
                    <Col
                        xs={24}
                        md={6}
                        className="border-line-"
                        style={{ marginRight: 100, marginBottom: 10 }}
                    >
                        <Row xs={24} className="border-line-bottom">
                            <Col xs={12} className="container-reference-gray">
                                <p>
                                    <strong>Referencia</strong>{' '}
                                </p>
                            </Col>
                            <Col xs={12} className="container-reference">
                                <p>{infoOperation.reference}</p>
                            </Col>
                        </Row>
                        <Row xs={24} className="border-line-bottom">
                            <Col xs={12} className="container-reference-gray">
                                <p>Fecha de emisión</p>
                            </Col>
                            <Col xs={12} className="container-reference">
                                <p>
                                    {moment
                                        .utc(infoOperation.operationDate)
                                        .local()
                                        .format('DD/MM/YYYY')}
                                </p>
                            </Col>
                        </Row>
                        {/* <Row xs={24}>
                            <Col xs={12} className="container-reference-gray">
                                <p>Estatus</p>
                            </Col>
                            <Col xs={12} className="container-reference">
                                <p>
                                    {
                                        <Tag
                                            color={
                                                infoOperation.status ===
                                                'En proceso'
                                                    ? 'orange'
                                                    : 'green'
                                            }
                                        >
                                            {infoOperation.status.toUpperCase()}
                                        </Tag>
                                    }
                                </p>
                            </Col>
                        </Row> */}
                    </Col>
                    <Col xs={24} md={6} className="border-line-top">
                        <Row xs={24} className="border-line-bottom">
                            <Col xs={12} className="container-reference-gray">
                                <p>
                                    <strong>Total unidades</strong>
                                </p>
                            </Col>
                            <Col xs={12} className="container-reference">
                                <p>{infoOperation.sumQuantity}</p>
                            </Col>
                        </Row>
                        <Row xs={24}>
                            <Col xs={12} className="container-reference-gray">
                                <p>Estatus</p>
                            </Col>
                            <Col xs={12} className="container-reference">
                                <p>
                                    {
                                        <Tag
                                            color={
                                                infoOperation.status ===
                                                'En proceso'
                                                    ? 'orange'
                                                    : 'green'
                                            }
                                        >
                                            {infoOperation.status.toUpperCase()}
                                        </Tag>
                                    }
                                </p>
                            </Col>
                        </Row>
                        {/* <Row xs={24} className="border-line-bottom">
                            <Col xs={12} className="container-reference-gray">
                                <p></p>
                            </Col>
                            <Col xs={12} className="container-reference">
                                <p></p>
                            </Col>
                        </Row> */}
                    </Col>
                   
                </Row>
            </Form>
            <div>
                <Table
                    columns={columns}
                    dataSource={dataTable}
                    scroll={{ x: 800 }}
                    // pagination={{ defaultPageSize: 5 }}
                />
                <Row>
                    <Col span={24}>
                        <Button
                            className="btn-download"
                            onClick={() =>
                                history.push(
                                    `/operaciones/detalles/documentos/${infoOperation.id}`
                                )
                            }
                        >
                            Ver documentos ({documents.length})
                        </Button>
                        <Button
                            className="btn-download"
                            icon={<DownloadOutlined />}
                            onClick={() => showModal(infoOperation.id)}
                        >
                            Agregar documento
                        </Button>

                        {[ROL_LAYOUT].some((r) => person.roles.includes(r)) && (
                            <Button
                                className="btn-download"
                                icon={<FileExcelOutlined />}
                                onClick={() => showModalFile()}
                            >
                                Subir Excel
                            </Button>
                        )}
                    </Col>
                </Row>
            </div>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
            >
                {contextModal}
            </Modal>
        </Spin>
    );
}
