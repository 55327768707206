import React, { useState } from 'react';
import {
    Table,
    Tag,
    Space,
    Button,
    Tooltip,
    Row,
    Col,
    Modal as ModalAnt,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import EditValuesForm from '../../../Admin/Catalog/EditValuesForm';
import Modal from '../../../General/Modal';
import { METHOD_DELETE } from '../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../helpers/urls';
import { getAccessTokenApi } from '../../../../api/auth';
import { fetchApi } from '../../../../helpers/fetch';

const ModuleTable = (props) => {
    const { data = [], loading, setReloadCatalogValues, catalog } = props;
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const { confirm } = ModalAnt;
    const token = getAccessTokenApi();

    const textModal = '¿Estás seguro que deseas borrar este campo';

    const deleteFieldValue = async (id) => {
        const config = {
            method: METHOD_DELETE,
            url: `${API_URL_CATALOG_VALUES}/${id}`,
            data: null,
            token,
            showNotification: true,
        };
        const result = await fetchApi(config);

        if (result) {
            if (result.statusCode === 200) {
                setReloadCatalogValues(true);
            }
        }
    };

    const showDeleteConfirm = (id, description) => {
        confirm({
            title: `${textModal} ${description} ?`,
            icon: <ExclamationCircleOutlined />,
            content: 'No podrá deshacer esta acción después..',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteFieldValue(id);
            },
            onCancel() {},
        });
    };

    const columns = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            align: 'center',
        },
        {
            title: 'Fecha de creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => <span>{moment.unix(createdAt).format('L')}</span>,
            align: 'center',
        },
        {
            title: 'Creador por',
            key: 'createdByName',
            dataIndex: 'createdByName',
            render: (createdByName) => (
                <Tag color="blue">{createdByName.toUpperCase()}</Tag>
            ),
            align: 'center',
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title="Editar">
                        <Button
                            onClick={() => showModal('Edit', record)}
                            type="primary"
                        >
                            <EditOutlined />
                        </Button>
                    </Tooltip>

                    <Tooltip title="Eliminar">
                        <Button
                            danger
                            type="primary"
                            onClick={() =>
                                showDeleteConfirm(record.id, record.description)
                            }
                        >
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            ),
            align: 'center',
        },
    ];

    const showModal = (type, record) => {
        if (type === 'Edit') {
            catalog.fieldsArray.forEach((element) => {
                const findField = record.fieldValues.find(
                    (field) => field.name === element
                );
                if (findField === undefined) {
                    record.fieldValues.push({
                        name: element,
                        value: '',
                    });
                }
            });
            setModalIsVisible(true);
            setTitleModal('Editar valores');
            setContextModal(
                <EditValuesForm
                    catalog={catalog}
                    setModalIsVisible={setModalIsVisible}
                    valueField={record}
                    setReloadCatalogValues={setReloadCatalogValues}
                />
            );
        }
    };

    return (
        <div className="billing__container">
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                scroll={{ x: 1200 }}
                expandable={{
                    expandedRowRender: (record) => {
                        return <ExpandedInfo record={record} />;
                    },
                    rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
            />
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                // width={1000}
            >
                {contextModal}
            </Modal>
        </div>
    );
};

export default ModuleTable;

function ExpandedInfo({ record }) {
    return (
        <>
            <h4>Más Valores:</h4>
            <Row>
                {record.fieldValues.map((field, index) => (
                    <Col md={6} key={index}>
                        <p>{`${field.name.toUpperCase()} : ${
                            field.value ? field.value : '-'
                        }`}</p>
                    </Col>
                ))}
            </Row>
        </>
    );
}
