import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Row, Col, Tooltip, Select } from 'antd';
import { METHOD_GET, METHOD_PUT } from '../../../../../utils/constants';
import {
    API_URL_CATALOG_VALUES, API_URL_CUSTOMER_CONTACTS,
    // API_URL_CUSTOMER_CONTACTS,
} from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../../api/auth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ROLES_CONTACTO_CATALOG_ID } from '../../../../../helpers/constants';

const EditCustomerContact = ({
    setModalIsVisible,
    setReloadContacts,
    contactId,
}) => {
    const [loading, setLoading] = useState(false);
    const token = getAccessTokenApi();
    const [form] = Form.useForm();
    const [serviceCatalog, setServiceCatalog] = useState([]);
    const [editCustomerContact, setEditCustomerContact] = useState([]);
    useEffect(() => {
        setLoading(true);
        const config = {
            method: METHOD_GET,
            url: `${API_URL_CATALOG_VALUES}/GetAll/${ROLES_CONTACTO_CATALOG_ID}`,
            data: null,
            token,
            // showNotification: true
        };
        fetchApi(config)
            .then((response) => {
                if (response) {
                    let calogsValues = response.result;

                    if (calogsValues) {
                        calogsValues.map((cat, index) => {
                            cat['key'] = index;
                            cat['label'] = cat.description;
                            cat['value'] = cat.id;
                        });
                    }
                    setServiceCatalog(calogsValues);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
            // getCatalogValueHandle( ROLES_CONTACTO_CATALOG_ID, setStates, setLoadingForm, form.getFieldValue("country"));

    }, []);

    useEffect(() => {
        setLoading(true);
        const config = {
            method: METHOD_GET,
            url: `${API_URL_CUSTOMER_CONTACTS}/${contactId}`,
            data: null,
            token,
            // showNotification: true
        };
        fetchApi(config)
            .then((response) => {
                setEditCustomerContact([
                    {
                        name: ['name'],
                        value: response.result[0].name,
                    },
                    {
                        name: ['lastName'],
                        value: response.result[0].lastName,
                    },
                    {
                        name: ['mothersLastName'],
                        value: response.result[0].mothersLastName,
                    },
                    {
                        name: ['phone'],
                        value: response.result[0].phone,
                    },
                    {
                        name: ['cellPhone1'],
                        value: response.result[0].cellPhone1,
                    },
                    {
                        name: ['cellPhone2'],
                        value: response.result[0].cellPhone2,
                    },
                    {
                        name: ['email'],
                        value: response.result[0].email,
                    },
                    {
                        name: ['rolesArray'],
                        value:
                            response.result[0].roles !== ''
                                ? response.result[0].roles
                                : null,
                    },
                ]);
            })
            .catch((err) => {
                console.log(err);
            });

        //   getStateCatalogHandle();
    }, [contactId, token]);

    const onFinish = async (values) => {
        setLoading(true);
        values['id'] = contactId;
        if (!isNaN(values.rolesArray)) {
        values['rolesArray'] = [values.rolesArray.toString()];
        }else{delete values.rolesArray}
        const config = {
            method: METHOD_PUT,
            url: `${API_URL_CUSTOMER_CONTACTS}/${contactId}`,
            data: values,
            token,
            showNotification: true,
        };
        const result = await fetchApi(config);
        if (result) {
            if (result.statusCode === 200) {
                // form.resetFields();
                setModalIsVisible(false);
                setReloadContacts(true);
            }
        }
        setLoading(false);
    };

    return (
        <div className="add-catalog-form">
            <Spin spinning={loading} tip="Cargando...">
                <Form
                    name="add-promo"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    fields={editCustomerContact}
                    initialValues={{ active: true }}
                >
                    <div>
                        <Row xs={24} className="table-company">
                            <Col xs={20}>
                                <Form.Item
                                    label="Nombre:"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor ingrese nombre',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Ingresa tu información" />
                                </Form.Item>
                                <Form.Item
                                    label="Apellido paterno:"
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor ingrese apellido paterno',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Ingresa tu información" />
                                </Form.Item>
                                <Form.Item
                                    label="Apellido materno:"
                                    name="mothersLastName"
                                >
                                    <Input placeholder="Ingresa tu información" />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <span>
                                            Teléfono casa/oficina:&nbsp;
                                            <Tooltip title="El número telefónico debe contener 10 dígitos">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="phone"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (
                                                    !value ||
                                                    value.toString().length ===
                                                        10
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    'Por favor ingresa número telefónico a 10 dígitos'
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Ingresa tu información" />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span>
                                            Celular 1:&nbsp;
                                            <Tooltip title="El número telefónico debe contener 10 dígitos">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="cellPhone1"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor ingresa número telefónico',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (
                                                    !value ||
                                                    value.toString().length ===
                                                        10
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    'Por favor ingresa número telefónico a 10 dígitos'
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Ingresa tu información" />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span>
                                            Celular 2:&nbsp;
                                            <Tooltip title="El número telefónico debe contener 10 dígitos">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="cellPhone2"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (
                                                    !value ||
                                                    value.toString().length ===
                                                        10
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    'Por favor ingresa número telefónico a 10 dígitos'
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Ingresa tu información" />
                                </Form.Item>
                                <Form.Item
                                    label="Correo electrónico:"
                                    name="email"
                                >
                                    <Input
                                        type={'email'}
                                        placeholder="Ingresa tu información"
                                    />
                                </Form.Item>
                                <Form.Item label="Rol:" name="rolesArray"
                                 rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese rol',
                                    },
                                ]}>
                                    <Select
                                        placeholder="Selecciona una opción"
                                        allowClear
                                        // onChange={(value) => setService(value)}
                                    >
                                        {serviceCatalog.map((s) => (
                                            <Select.Option
                                                key={s.value}
                                                value={s.value}
                                            >
                                                {s.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button
                                                htmlType="submit"
                                                className="btn-enviar"
                                                block
                                            >
                                                Guardar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Spin>
        </div>
    );
};

export default EditCustomerContact;
