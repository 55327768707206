import React, { useEffect, useState } from 'react';
import {
   Row,
   Col,
   Spin,
   Form,
   Input,
   Button,
   notification,
   Modal as ModalAntd,
   Tabs,
   Tooltip,
   Checkbox,
   Select,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getClientApi, editClientApi } from '../../../../../api/clients';
import { logout } from '../../../../../api/auth';
import { getCompanyIdApi } from '../../../../../api/company';
import {
   ExclamationCircleOutlined,
   QuestionCircleOutlined,
} from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import {
   CITY_CATALOG_ID,
   COUNTRY_CATALOG_ID,
   OPERACIONES_CATALOG_ID,
   STATE_CATALOG_ID,
} from '../../../../../helpers/constants';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import Location from '../AddClientForm/Location/Location';
import Catalog from '../AddClientForm/Catalog/Catalog';

const EditClientForm = ({
   setModalIsVisible,
   setReloadClients,
   idClient,
   type,
}) => {
   const [loadingForm, setLoadingForm] = useState(true);
   const [form] = Form.useForm();
   const [editClientForm, setEditClientForm] = useState([]);
   const companyId = getCompanyIdApi();
   const token = getAccessTokenApi();
   const { confirm } = ModalAntd;
   const { TabPane } = Tabs;
   const [financiales, setFinanciales] = useState([]);
   const [operadores, setOperadores] = useState([]);
   const [states, setStates] = useState([]);
   const [cities, setCities] = useState([]);
   const [countrySelected, setCountrySelected] = useState(0);
   const [countries, setCountries] = useState([]);
   const disableAllInputs = type === 'watch' ? true : false;

   useEffect(() => {
      if (loadingForm) {
         getUsersApi(token)
            .then((response) => {
               if (response) {
                  let financials = [];
                  let operations = [];
                  response.result.forEach((element, index) => {
                     if (element.roles.includes('Financial')) {
                        financials.push(element);
                     }
                  });
                  response.result.forEach((element, index) => {
                     if (element.roles.includes('Operaciones')) {
                        operations.push(element);
                     }
                  });
                  let supp = financials.map((f) => ({
                     value: f.id,
                     label: f.userName,
                  }));
                  setFinanciales(supp);
                  let op = operations.map((f) => ({
                     value: f.id,
                     label: f.userName,
                  }));
                  setOperadores(op);
               }
               setLoadingForm(false);
            })
            .catch((err) => {});
      }
   }, [token, loadingForm]);
   useEffect(() => {
      setLoadingForm(true);
      const config = {
         method: METHOD_GET,
         url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
         data: null,
         token,
         // showNotification: true
      };
      fetchApi(config)
         .then((response) => {
            if (response) {
               let calogsValues = response.result;

               if (calogsValues) {
                  calogsValues.map((cat, index) => {
                     cat['key'] = index;
                     cat['label'] = cat.description;
                     cat['value'] = cat.id;
                  });
               }
               setCountries(calogsValues);
            }
            setLoadingForm(false);
         })
         .catch((err) => {
            console.log(err);
         });
      getCatalogValueHandle(
         STATE_CATALOG_ID,
         setStates,
         setLoadingForm,
         form.getFieldValue('country')
      );
      getCatalogValueHandle(
         CITY_CATALOG_ID,
         setCities,
         setLoadingForm,
         form.getFieldValue('state')
      );
      //   getStateCatalogHandle();
   }, [form, token]);
   const onChangeCountryOrigin = (value) => {
      if (value !== countrySelected) {
         form.setFieldsValue({
            OriginZipcode: '',
            OriginState: '',
            OriginCity: '',
            OriginAddress: '',
            OriginPort: '',
            OriginPort: '',
            OriginAirport: '',
            // DestinyZipcode: '',
            // DestinyState: '',
            // DestinyCity: '',
            // DestinyAddress: '',
            // DestinyAirport: '',
            // DestinyPort: '',
         });
      }
      setCountrySelected(value);
      getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
   };
   const onChangeState = (value) => {
      getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
   };

   useEffect(() => {
      getClientApi(token, idClient).then((response) => {
         setEditClientForm([
            {
               name: ['active'],
               value: response.result.active,
            },
            {
               name: ['name'],
               value: response.result.name,
            },
            {
               name: ['clientNum'],
               value: response.result.clientNum,
            },
            {
               name: ['id'],
               value: response.result.id,
            },
            {
               name: ['address'],
               value: response.result.address,
            },
            {
               name: ['city'],
               value: response.result.city !== 0 ? response.result.city : null,
            },
            {
               name: ['state'],
               value:
                  response.result.state !== 0 ? response.result.state : null,
            },
            {
               name: ['zipCode'],
               value: response.result.zipCode,
            },
            {
               name: ['country'],
               value:
                  response.result.country !== 0
                     ? response.result.country
                     : null,
            },
            {
               name: ['emailAddress'],
               value:
                  response.result.emailAddress !== ''
                     ? response.result.emailAddress
                     : null,
            },
            {
               name: ['contactName'],
               value: response.result.contactName,
            },
            {
               name: ['fiscalID'],
               value: response.result.fiscalID,
            },
            {
               name: ['phoneNumber'],
               value: response.result.phoneNumber,
            },
            {
               name: ['financialId'],
               value: response.result.financialId,
            },
            {
               name: ['operacionesId'],
               value: response.result.operacionesId,
            },
            {
               name: ['currency'],
               value:
                  response.result.currency !== 0
                     ? response.result.currency
                     : null,
            },
            {
               name: ['paymentTerms'],
               value:
                  response.result.paymentTerms !== 0
                     ? response.result.paymentTerms
                     : null,
            },
         ]);
      });
      // return () => {
      //     cleanup
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [idClient]);

   const handleEditClient = (values) => {
      confirm({
         title: 'Editar cliente',
         icon: <ExclamationCircleOutlined />,
         content: '¿Estás seguro que deseas guardar datos modificados?',
         okText: 'Si',
         okType: 'danger',
         cancelText: 'No',
         onOk() {
            editClientFunction(values);
         },
         onCancel() {},
      });
   };

   const editClientFunction = async (values) => {
      setLoadingForm(true);
      let data = values;
      data['ParentCustomerId'] = '';
      data['level'] = 0;
      data['company'] = companyId;
      let response = null;
      try {
         response = await editClientApi(token, data, idClient);
      } catch (error) {
      } finally {
         setLoadingForm(false);
      }
      // console.log(response, 'response edit');
      if (response === undefined) {
         notification['error']({
            message: 'Ocurrió un error, Inténtelomas tarde',
         });
         return false;
      }
      if (response.statusCode === 400) {
         notification['error']({
            message: response.description,
         });
         return false;
      }
      if (response.statusCode === 401) {
         notification['error']({
            message: 'Usuario no autorizado.',
         });
         setTimeout(() => {
            logout();
            window.location.reload();
         }, 1500);
         return false;
      }
      if (response.statusCode === 200) {
         notification['success']({
            message: 'Cliente editado con éxito',
         });
         setModalIsVisible(false);
      } else {
         let messageError = 'Ocurrió un error, Inténtelootra vez';
         if (response.statusCode === 409) {
            messageError = response.description;
         }
         if (response.statusCode === 400) {
            messageError = response.description;
         }
         notification['error']({
            message: messageError,
         });
      }
      setReloadClients(true);

      setLoadingForm(false);
   };
   return (
      <Spin spinning={loadingForm} tip='Cargando...'>
         <Form
            name='add-promo'
            layout='vertical'
            form={form}
            onFinish={handleEditClient}
            fields={editClientForm}
            onFieldsChange={(changedFields, allFields) => {
               setEditClientForm(allFields);
            }}
         >
            <div>
               <Tabs defaultActiveKey='1' type='card'>
                  <TabPane tab='Detalle' key='infoBasic'>
                     <BasicInfo
                        financiales={financiales}
                        operadores={operadores}
                        disableAllInputs={disableAllInputs}
                     />
                  </TabPane>

                  <TabPane tab='Ubicación' key='location'>
                     <Location
                        onChangeCountryOrigin={onChangeCountryOrigin}
                        countries={countries}
                        onChangeState={onChangeState}
                        states={states}
                        cities={cities}
                        disableAllInputs={disableAllInputs}
                     />
                  </TabPane>
                  <TabPane tab='Cátalogos' key='catalog'>
                     <Catalog disableAllInputs={disableAllInputs} />
                  </TabPane>
               </Tabs>

               {type !== 'watch' && (
                  <Row>
                     <Col span={24}>
                        <Form.Item wrapperCol={{ offset: 9, span: 12 }}>
                           <Button
                              htmlType='submit'
                              className='btn-enviar'
                              block
                           >
                              Guardar
                           </Button>
                        </Form.Item>
                     </Col>
                  </Row>
               )}
            </div>
         </Form>
      </Spin>
   );
};

export default EditClientForm;

function BasicInfo({ financiales, operadores, disableAllInputs }) {
   return (
      <Row gutter={16}>
         <Col xs={24} md={12} lg={8}>
            <Form.Item
               label='Nombre de cliente:'
               name='name'
               rules={[
                  {
                     required: true,
                     message: 'Por favor ingrese un nombre',
                  },
               ]}
            >
               <Input
                  placeholder='Ingresa tu información'
                  disabled={disableAllInputs}
               />
            </Form.Item>
         </Col>
         <Col xs={24} md={12} lg={8}>
            <Form.Item
               label='Id de cliente:'
               name='id'
               rules={[
                  {
                     required: true,
                     message: 'Por favor ingrese un Id',
                  },
               ]}
            >
               <Input disabled placeholder='Ingresa tu información' />
            </Form.Item>
         </Col>

         <Col xs={24} md={12} lg={8}>
            <Form.Item
               label='Número de cliente:'
               name='customerNum'
               rules={[
                  {
                     required: false,
                     message: 'Por favor ingrese un número',
                  },
               ]}
            >
               <Input
                  placeholder='Ingresa tu información'
                  disabled={disableAllInputs}
               />
            </Form.Item>
         </Col>

         <Col xs={24} md={12} lg={8}>
            <Form.Item label='RFC:' name='fiscalID'>
               <Input
                  placeholder='Ingresa tu información'
                  disabled={disableAllInputs}
               />
            </Form.Item>
         </Col>
         <Col xs={24} md={12} lg={8}>
            <Form.Item
               label='Correo electrónico:'
               name='emailAddress'
               rules={[
                  {
                     required: false,
                     message: 'Por favor ingrese un email',
                  },
               ]}
            >
               <Input
                  type={'email'}
                  placeholder='Ingresa tu información'
                  disabled={disableAllInputs}
               />
            </Form.Item>
         </Col>
         <Col xs={24} md={12} lg={8}>
            <Form.Item
               label={
                  <span>
                     Teléfono:&nbsp;
                     <Tooltip title='El número telefónico debe contener 10 dígitos'>
                        <QuestionCircleOutlined />
                     </Tooltip>
                  </span>
               }
               name='phoneNumber'
               rules={[
                  {
                     required: false,
                     message: 'Por favor ingresa número telefónico',
                  },
                  ({ getFieldValue }) => ({
                     validator(rule, value) {
                        if (!value || value.toString().length === 10) {
                           return Promise.resolve();
                        }

                        return Promise.reject(
                           'Por favor ingresa número telefónico a 10 dígitos'
                        );
                     },
                  }),
               ]}
            >
               <Input
                  placeholder='Ingresa tu información'
                  disabled={disableAllInputs}
               />
            </Form.Item>
         </Col>

         <Col xs={24} md={12} lg={8}>
            <Form.Item
               label='Usuario estatus:'
               name='active'
               valuePropName='checked'
            >
               <Checkbox disabled={disableAllInputs}>Activo</Checkbox>
            </Form.Item>
         </Col>
         {financiales && (
            <Col xs={24} md={12} lg={8}>
               <Form.Item
                  label='Financiero:'
                  name='financialId'
                  rules={[
                     {
                        message: 'Por favor seleccione un financiero',
                     },
                  ]}
               >
                  <Select
                     placeholder='Selecciona una opción'
                     allowClear={true}
                     disabled={disableAllInputs}
                  >
                     {financiales.map((f) => (
                        <Select.Option key={f.value} value={f.value}>
                           {f.label}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            </Col>
         )}
         {operadores && (
            <Col xs={24} md={12} lg={8}>
               <Form.Item
                  label='Operador:'
                  name='operacionesId'
                  rules={[
                     {
                        message: 'Por favor seleccione un operador',
                     },
                  ]}
               >
                  <Select
                     placeholder='Selecciona una opción '
                     allowClear={true}
                     disabled={disableAllInputs}
                  >
                     {operadores.map((o) => (
                        <Select.Option key={o.value} value={o.value}>
                           {o.label}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
            </Col>
         )}
      </Row>
   );
}
