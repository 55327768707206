import React from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import AddQuoteForm from '../../../../components/Logistic/Quote/AddQuoteForm';
import {
    EditOutlined,
    VerticalAlignBottomOutlined,
    SaveOutlined,
    DeleteOutlined,
    FileAddOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router';

export default function AddQuotePage() {
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop />

            <div className="billing__container">
                <AddQuoteForm />
            </div>
        </Layout>
    );
}

function HeaderTop() {
    const { Title } = Typography;
    const history = useHistory();

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    <Title level={3}>Agregar cotización</Title>
                </Col>

                <Col xs={24} md={11}>
                    <Row
                        style={{
                            marginBottom: 30,
                            justifyContent: 'right',
                        }}
                    >
                        <Button type="link" onClick={() => history.goBack()}>
                            <ArrowLeftOutlined /> Atrás
                        </Button>
                        {/* <Button
                            icon={<EditOutlined />}
                            className="btn-quote"
                        ></Button>
                        <Button
                            icon={<VerticalAlignBottomOutlined />}
                            className="btn-quote"
                        ></Button>
                        <Button
                            icon={<SaveOutlined />}
                            className="btn-quote"
                        ></Button>
                        <Button
                            icon={<FileAddOutlined />}
                            className="btn-quote"
                        ></Button>
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            type="primary"
                            className="btn-quote"
                        ></Button> */}
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
