import React from 'react';
import { Col, Row, Tabs } from 'antd';
import NewLine from './NewLine/NewLine';
import LineList from './LineList/LineList';

const LineForm = (props) => {
  const {
    linesForm,
    newLineForm,
    handleSaveLine,
    changeTab,
    disableAllInputs,
    showModal,
    linesData,
    handleDeleteLine,
    pieces,
    setPieces,
    setLinesData,
    saveKey,
    onChangeFilterSupplier,
    supplierSelected,
    setFilterSupplier,
    showAllValuesNL,
    setShowAllValuesNL,
    rateSelected,
    setRateSelected,
    type,
    clearSupplier,
    setSupplierSelected,
    clearIsRFQ,
    setClearIsRFQ,
  } = props;
  const { TabPane } = Tabs;

  return (
    <div>
      <div>
        <Row>
          <Col md={24}>
            <Tabs
              defaultActiveKey={type !== 'watch' ? 'nl' : linesData[0]}
              type='card'
              size='small'
              onTabClick={changeTab}
            >
              {type !== 'watch' && (
                <TabPane tab={'Nueva línea'} key={'nl'}>
                  <NewLine
                    disableAllInputs={disableAllInputs}
                    showModal={showModal}
                    newLineForm={newLineForm}
                    pieces={pieces}
                    setPieces={setPieces}
                    saveKey={saveKey}
                    onChangeFilterSupplier={onChangeFilterSupplier}
                    supplierSelected={supplierSelected}
                    setFilterSupplier={setFilterSupplier}
                    showAllValuesNL={showAllValuesNL}
                    setShowAllValuesNL={setShowAllValuesNL}
                    rateSelected={rateSelected}
                    setRateSelected={setRateSelected}
                    clearSupplier={clearSupplier}
                    clearIsRFQ={clearIsRFQ}
                    setClearIsRFQ={setClearIsRFQ}
                    handleSaveLine={handleSaveLine}
                    linesData={linesData}
                  />
                </TabPane>
              )}
              {linesData?.map((linea, index) => (
                <TabPane
                  tab={
                    linea?.LineNumber ? linea?.LineNumber : linea?.lineNumber
                  }
                  key={index}
                >
                  <LineList
                    linea={linea}
                    disableAllInputs={disableAllInputs}
                    showModal={showModal}
                    linesForm={linesForm}
                    handleDeleteLine={handleDeleteLine}
                    pieces={pieces}
                    setPieces={setPieces}
                    linesData={linesData}
                    setLinesData={setLinesData}
                    saveKey={saveKey}
                    onChangeFilterSupplier={onChangeFilterSupplier}
                    setFilterSupplier={setFilterSupplier}
                    supplierSelectedLine={supplierSelected}
                    setSupplierSelected={setSupplierSelected}
                    type={type}
                    handleSaveLine={handleSaveLine}
                  />
                </TabPane>
              ))}
            </Tabs>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default LineForm;
