import React, { useState, useEffect } from 'react';
import { Row, Form, Select, Col, Input, Divider, InputNumber } from 'antd';
import { METHOD_GET } from '../../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../../helpers/urls';
import {
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  STATE_CATALOG_ID,
  TERRESTRE_VALUE_ID,
  MARITIMO_VALUE_ID,
  AEREO_VALUE_ID,
  PORT_CATALOG_ID,
  CANADA_VALUE_ID,
} from '../../../../../../helpers/constants';
import { fetchApi } from '../../../../../../helpers/fetch';
import { getCatalogValueHandle } from '../../../../../../helpers/handlerFunctionApi';
import useWindowDimensions from '../../../../../../hooks/useWindowdimensions';
import { getAccessTokenApi } from '../../../../../../api/auth';

const Rute = (props) => {
  const {
    disableAllInputs,
    service,
    setLoadingForm,
    form,
    type = null,
  } = props;
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [ports, setPorts] = useState([]);
  // const [airports, setAirports] = useState([]);
  const [countriesDestiny, setCountriesDestiny] = useState([]);
  const [statesDestiny, setStatesDestiny] = useState([]);
  const [citiesDestiny, setCitiesDestiny] = useState([]);
  const [portsDestiny, setPortsDestiny] = useState([]);
  // const [airportsDestiny, setAirportsDestiny] = useState([]);
  const [countrySelected, setCountrySelected] = useState(0);
  const [countrySelectedDestiny, setCountrySelectedDestiny] = useState(0);
  const suppliers = [{ value: '123', label: 'op123' }];
  const token = getAccessTokenApi();
  const { width } = useWindowDimensions();
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
              return cat;
            });
          }
          setCountries(calogsValues);
          setCountriesDestiny(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });

    if (type) {
      getCatalogValueHandle(
        STATE_CATALOG_ID,
        setStates,
        setLoadingForm,
        form.getFieldValue('originCountry')
      );
      getCatalogValueHandle(
        STATE_CATALOG_ID,
        setStatesDestiny,
        setLoadingForm,
        form.getFieldValue('destinyCountry')
      );
      getCatalogValueHandle(
        CITY_CATALOG_ID,
        setCities,
        setLoadingForm,
        form.getFieldValue('originState')
      );
      getCatalogValueHandle(
        CITY_CATALOG_ID,
        setCitiesDestiny,
        setLoadingForm,
        form.getFieldValue('destinyState')
      );
      getCatalogValueHandle(
        PORT_CATALOG_ID,
        setPorts,
        setLoadingForm,
        form.getFieldValue('originPort')
      );
      getCatalogValueHandle(
        PORT_CATALOG_ID,
        setPortsDestiny,
        setLoadingForm,
        form.getFieldValue('destinyPort')
      );
    }

    //   getStateCatalogHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        originZipCode: '',
        originState: '',
        originCity: '',
        originAddress: '',
        originPort: '',
        originAirport: '',
      });
      setCities([]);
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
  };

  const onChangeCountryDestiny = (value) => {
    if (value !== countrySelectedDestiny) {
      form.setFieldsValue({
        destinyZipCode: '',
        destinyState: '',
        destinyCity: '',
        destinyAddress: '',
        destinyAirport: '',
        destinyPort: '',
      });
      setCitiesDestiny([]);
    }
    setCountrySelectedDestiny(value);
    getCatalogValueHandle(
      STATE_CATALOG_ID,
      setStatesDestiny,
      setLoadingForm,
      value
    );
  };

  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    form.setFieldsValue({
        originZipCode: '',
        originAddress: '',
        originCity: '',
        originPort: '',
        originAirport: '',
    });
  };

  const onChangeStateDestiny = (value) => {
    getCatalogValueHandle(
      CITY_CATALOG_ID,
      setCitiesDestiny,
      setLoadingForm,
      value
    );
    form.setFieldsValue({
      destinyZipCode: '',
      destinyCity: '',
      destinyAirport: '',
      destinyPort: '',
      destinyAddress: '',
    });
  };

  return (
    <div className='rute-tab'>
      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Origen</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='País:'
            name='originCountry'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder='Selecciona una opción'
              onChange={onChangeCountryOrigin}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // style={{
              //   minWidth: width > 480 ? 220 : '100%',
              //   width: width > 480 ? 220 : '100%',
              // }}
            >
              {countries.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Estado:'
            name='originState'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChangeState}
              // style={{
              //   minWidth: width > 480 ? 220 : '100%',
              //   width: width > 480 ? 220 : '100%',
              // }}
            >
              {states.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Ciudad:'
            name='originCity'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // style={{
              //   minWidth: width > 480 ? 220 : '100%',
              //   width: width > 480 ? 220 : '100%',
              // }}
            >
              {cities.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Código postal:'
              name='originZipCode'
              rules={[
                // {
                //   required: true,
                //   message: 'Por favor ingrese un código postal',
                // },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('originCountry') === CANADA_VALUE_ID) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value &&
                        value.toString().length === 5 &&
                        !isNaN(value)
                      ) {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={5}
                // minLength={5}
                disabled={disableAllInputs}
                // style={{ minWidth: 150, width: 150 }}
              />
            </Form.Item>
          </Col>
        )}

        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Puerto:'
              name='originPort'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                // style={{
                //   minWidth: width > 480 ? 220 : '100%',
                //   width: width > 480 ? 220 : '100%',
                // }}
              >
                {ports.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Aeropuerto:'
              name='originAirport'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                // style={{
                //   minWidth: width > 480 ? 220 : '100%',
                //   width: width > 480 ? 220 : '100%',
                // }}
              >
                {suppliers.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label='Dirección:'
              name='originAddress'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs} />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Divider style={{ marginTop: 0, marginBottom: 0 }}>Destino</Divider>

      <Row gutter={[16, 16]}>
        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='País:'
            name='destinyCountry'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un país',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder='Selecciona una opción'
              onChange={onChangeCountryDestiny}
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // style={{
              //   minWidth: width > 480 ? 220 : '100%',
              //   width: width > 480 ? 220 : '100%',
              // }}
            >
              {countriesDestiny.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Estado:'
            name='destinyState'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese un estado',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              // disabled={disableAllInputs || service === TERRESTRE_VALUE_ID}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={onChangeStateDestiny}
              // style={{
              //   minWidth: width > 480 ? 220 : '100%',
              //   width: width > 480 ? 220 : '100%',
              // }}
            >
              {statesDestiny.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12} sm={12} lg={6}>
          <Form.Item
            label='Ciudad:'
            name='destinyCity'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese una ciudad',
              },
            ]}
          >
            <Select
              disabled={disableAllInputs}
              placeholder='Selecciona una opción'
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              // style={{
              //   minWidth: width > 480 ? 220 : '100%',
              //   width: width > 480 ? 220 : '100%',
              // }}
            >
              {citiesDestiny.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Código postal:'
              name='destinyZipCode'
              rules={[
                // {
                //   required: true,
                //   message: 'Por favor ingrese un código postal',
                // },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('destinyCountry') === CANADA_VALUE_ID) {
                      if (
                        value &&
                        value.toString().length >= 3 &&
                        value.toString().length <= 6
                      ) {
                        return Promise.resolve();
                      }
                    } else {
                      if (
                        value &&
                        value.toString().length === 5 &&
                        !isNaN(value)
                      ) {
                        return Promise.resolve();
                      }
                    }
                    return Promise.reject(
                      'Por favor ingrese un código postal válido'
                    );
                  },
                }),
              ]}
            >
              <Input
                maxLength={5}
                // minLength={5}
                disabled={disableAllInputs}
                // style={{ minWidth: 150, width: 150 }}
              />
            </Form.Item>
          </Col>
        )}
        {service === MARITIMO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Puerto:'
              name='destinyPort'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un puerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {portsDestiny.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === AEREO_VALUE_ID && (
          <Col xs={12} sm={12} lg={6}>
            <Form.Item
              label='Aeropuerto:'
              name='destinyAirport'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese un aeropuerto',
                },
              ]}
            >
              <Select
                placeholder='Selecciona una opción'
                style={{
                  minWidth: width > 480 ? 220 : '100%',
                  width: width > 480 ? 220 : '100%',
                }}
              >
                {suppliers.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {service === TERRESTRE_VALUE_ID && (
          <Col xs={24} sm={24} lg={24}>
            <Form.Item
              label='Dirección:'
              name='destinyAddress'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una dirección',
                },
              ]}
            >
              <Input disabled={disableAllInputs} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Rute;
