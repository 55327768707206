import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Row, Space, Typography, notification } from 'antd';
import {
   HomeOutlined,
   PieChartOutlined,
   FileSearchOutlined,
   PhoneOutlined,
   MailOutlined,
   SnippetsOutlined,
   SettingOutlined,
   UnorderedListOutlined,
   UserOutlined,
   TeamOutlined,
   SyncOutlined,
   ToolOutlined,
   RiseOutlined,
   BranchesOutlined,
   BarChartOutlined,
   DollarCircleOutlined,
   CheckCircleOutlined,
   PauseCircleOutlined,
} from '@ant-design/icons';
import {
   getContactBySectionApi,
   getSectionsContactsApi,
} from '../../../api/sections';
import { useLocation } from 'react-router-dom';
import { getAccessTokenApi, logout } from '../../../api/auth';

import './MenuSider.scss';
import {
   ROL_ADMIN,
   ROL_MANAGER,
   ROL_OPERACIONES,
   ROL_SUPPLIER,
   ROL_FINANCIAL,
   ROL_CUSTOMER,
   ROL_PRICING,
   ROL_VENTAS,
} from '../../../utils/constants';
import { formatPhoneNumber } from '../../../utils/general';
import { getPortalTypeApi } from '../../../api/user';

const MenuSider = ({ menuCollapsed, roles = [] }) => {
   const { Sider } = Layout;
   const { Paragraph, Text } = Typography;
   const token = getAccessTokenApi();
   const portalType = getPortalTypeApi();
   const location = useLocation();
   const [nameContact, setNameContact] = useState('');
   const [phoneContact, setPhoneContact] = useState('');
   const [emailContact, setEmailContact] = useState('');
   const { SubMenu } = Menu;

   useEffect(() => {
      const role = 'Financial';
      getContactBySectionApi(token, role).then((response) => {
         if (response) {
            if (response.statusCode === 401) {
               notification['error']({
                  message: 'Usuario no autorizado',
               });
               setTimeout(() => {
                  logout();
                  window.location.reload();
               }, 1500);
               return;
            }
            if (response.statusCode === 200) {
               setNameContact(response.result.name);
               setPhoneContact(response.result.phoneNumber);
               setEmailContact(response.result.email);
            }
         }
      });
   }, [token]);

   // let selectedKey = location.pathname;
   // let splitted = location.pathname.split('/');

   // if (splitted.length > 2) {
   //     selectedKey = '/' + splitted[1];
   // }
   // console.log(selectedKey);
   return (
      <Sider
         className='admin-sider'
         collapsed={menuCollapsed}
         collapsible
         breakpoint='md'
         collapsedWidth='0'
         width={220}
      >
         <Menu
            theme='dark'
            mode='inline'
            defaultSelectedKeys={[location.pathname]}
            selectedKeys={location.pathname}
         >
            {[
               ROL_MANAGER,
               ROL_OPERACIONES,
               ROL_SUPPLIER,
               ROL_FINANCIAL,
               ROL_CUSTOMER,
            ].some((r) => roles.includes(r)) && (
               <Menu.Item key='/'>
                  <Link to='/'>
                     <HomeOutlined />
                     <span className='nav-text'>Inicio</span>
                  </Link>
               </Menu.Item>
            )}
            {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Facturación">
                    <Menu.Item key="5">Option 5</Menu.Item>
                    <Menu.Item key="6">Option 6</Menu.Item>
                    <Menu.Item key="7">Option 7</Menu.Item>
                    <Menu.Item key="8">Option 8</Menu.Item>
                </SubMenu> */}
            {[ROL_VENTAS, ROL_MANAGER, ROL_ADMIN].some((r) =>
               roles.includes(r)
            ) && (
               <SubMenu key='venta' icon={<RiseOutlined />} title='Ventas'>
                  <Menu.Item key='/cotizacion'>
                     <Link to='/cotizacion'>
                        <SnippetsOutlined />
                        <span className='nav-text'>Cotización</span>
                     </Link>
                  </Menu.Item>

                  {/* <Menu.Item key="cliente">Clientes</Menu.Item> */}
               </SubMenu>
            )}

            {[ROL_MANAGER, ROL_PRICING, ROL_ADMIN].some((r) =>
               roles.includes(r)
            ) && (
               <SubMenu
                  key='pricing'
                  icon={<DollarCircleOutlined />}
                  title='Tarifa'
               >
                  <Menu.Item key='/pricing/tarifa'>
                     <Link to='/pricing/tarifa'>
                        <CheckCircleOutlined />
                        <span className='nav-text'>Tarifa</span>
                     </Link>
                  </Menu.Item>
                  <Menu.Item key='/pricing/rfq'>
                     <Link to='/pricing/rfq'>
                        <PauseCircleOutlined />
                        <span className='nav-text'>RFQ</span>
                     </Link>
                  </Menu.Item>
               </SubMenu>
            )}

            {[
               ROL_MANAGER,
               ROL_OPERACIONES,
               ROL_SUPPLIER,
               ROL_FINANCIAL,
               ROL_PRICING,
            ].some((r) => roles.includes(r)) && (
               <Menu.Item key='/trafico' hidden={true}>
                  <Link to='/trafico'>
                     <BranchesOutlined />
                     <span className='nav-text'>Tráfico</span>
                  </Link>
               </Menu.Item>
            )}

            {[ROL_MANAGER, ROL_FINANCIAL].some((r) => roles?.includes(r)) && (
               <Menu.Item key='/proveedores'>
                  <Link to='/proveedores'>
                     <UserOutlined />
                     <span className='nav-text'>Proveedores</span>
                  </Link>
               </Menu.Item>
            )}
            {[ROL_MANAGER, ROL_FINANCIAL].some((r) => roles?.includes(r)) && (
               <Menu.Item key='/clientes'>
                  <Link to='/clientes'>
                     <UserOutlined />
                     <span className='nav-text'>Clientes</span>
                  </Link>
               </Menu.Item>
            )}
            {[ROL_CUSTOMER, ROL_FINANCIAL].some((r) => roles?.includes(r)) && (
               <Menu.Item key='/estado' hidden={true}>
                  <Link to='/estado/cuentas'>
                     <PieChartOutlined />
                     <span className='nav-text'>Estado de Cuenta</span>
                  </Link>
               </Menu.Item>
            )}
            {[
               ROL_MANAGER,
               ROL_OPERACIONES,
               ROL_FINANCIAL,
               // ROL_SUPPLIER,
               // ROL_CUSTOMER,
            ].some((r) => roles.includes(r)) && (
               <SubMenu
                  key='/facturacion'
                  icon={<BarChartOutlined />}
                  title='Facturación'
                  hidden={true}
               >
                  {(portalType === 'Clientes' ||
                     portalType === 'Clientes/Proveedores') && (
                     <Menu.Item key='/facturacion/clientes'>
                        <Link to='/facturacion/clientes'>
                           <span className='nav-text'>F. Cliente</span>
                        </Link>
                     </Menu.Item>
                  )}

                  {(portalType === 'Proveedores' ||
                     portalType === 'Clientes/Proveedores') && (
                     <Menu.Item key='/facturacion/proveedores'>
                        <Link to='/facturacion/proveedores'>
                           <span className='nav-text'>F. Proveedores</span>
                        </Link>
                     </Menu.Item>
                  )}
               </SubMenu>
            )}
            {[
               // ROL_MANAGER,
               // ROL_OPERACIONES,
               // ROL_FINANCIAL,
               ROL_SUPPLIER,
               ROL_CUSTOMER,
            ].some((r) => roles.includes(r)) && (
               <Menu.Item key='/facturacion'>
                  <Link to='/facturacion'>
                     <PieChartOutlined />
                     <span className='nav-text'>Facturación</span>
                  </Link>
               </Menu.Item>
            )}
            {[
               ROL_MANAGER,
               // ROL_OPERACIONES,
               ROL_SUPPLIER,
               ROL_FINANCIAL,
            ].some((r) => roles.includes(r)) && (
               <Menu.Item key='/pagos' hidden={true}>
                  <Link to='/pagos'>
                     <SnippetsOutlined />
                     <span className='nav-text'>Complemento de Pago</span>
                  </Link>
               </Menu.Item>
            )}
            {[ROL_MANAGER, ROL_OPERACIONES, ROL_CUSTOMER].some((r) =>
               roles?.includes(r)
            ) && (
               <Menu.Item key='/operaciones' hidden={true}>
                  <Link to='/operaciones'>
                     <SyncOutlined />
                     <span className='nav-text'>Operaciones</span>
                  </Link>
               </Menu.Item>
            )}
            {[
               ROL_MANAGER,
               ROL_OPERACIONES,
               ROL_FINANCIAL,
               // ROL_SUPPLIER,
               // ROL_CUSTOMER,
            ].some((r) => roles.includes(r)) && (
               <SubMenu
                  key='/aclaraciones'
                  icon={<FileSearchOutlined />}
                  title='Aclaraciones'
                  hidden={true}
               >
                  {(portalType === 'Clientes' ||
                     portalType === 'Clientes/Proveedores') && (
                     <Menu.Item key='/aclaraciones/clientes'>
                        <Link to='/aclaraciones/clientes'>
                           <span className='nav-text'>A. Cliente</span>
                        </Link>
                     </Menu.Item>
                  )}
                  {(portalType === 'Proveedores' ||
                     portalType === 'Clientes/Proveedores') && (
                     <Menu.Item key='/aclaraciones/proveedores'>
                        <Link to='/aclaraciones/proveedores'>
                           <span className='nav-text'>A. Proveedores</span>
                        </Link>
                     </Menu.Item>
                  )}
               </SubMenu>
            )}

            {[
               // ROL_MANAGER,
               // ROL_OPERACIONES,
               // ROL_FINANCIAL,
               ROL_SUPPLIER,
               ROL_CUSTOMER,
            ].some((r) => roles.includes(r)) && (
               <Menu.Item key='/aclaraciones'>
                  <Link to='/aclaraciones'>
                     <FileSearchOutlined />
                     <span className='nav-text'>Aclaraciones</span>
                  </Link>
               </Menu.Item>
            )}

            {roles.includes(ROL_ADMIN) && (
               <Menu.Item key='/configuracion'>
                  <Link to='/configuracion'>
                     <SettingOutlined />
                     <span className='nav-text'>Configuración</span>
                  </Link>
               </Menu.Item>
            )}
            {[ROL_ADMIN, ROL_OPERACIONES].some((r) => roles.includes(r)) && (
               <Menu.Item key='/catalog'>
                  <Link to='/catalog'>
                     <UnorderedListOutlined />
                     <span className='nav-text'>Catálogos</span>
                  </Link>
               </Menu.Item>
            )}
            {[ROL_ADMIN, ROL_OPERACIONES].some((r) => roles.includes(r)) && (
               <Menu.Item key='/administracion'>
                  <Link to='/administracion'>
                     <ToolOutlined />
                     <span className='nav-text'>Administración</span>
                  </Link>
               </Menu.Item>
            )}
         </Menu>
         {[ROL_SUPPLIER].some((r) => roles.includes(r)) && (
            <>
               {(location.pathname === '/facturacion' ||
                  location.pathname === '/pagos') && (
                  <div className='menu-extra'>
                     {phoneContact !== '' && (
                        <div className='menu-info'>
                           <Row style={{ justifyContent: 'center' }}>
                              <Space size={5} align='baseline'>
                                 <PhoneOutlined />
                                 <Paragraph>
                                    Tel. {''}
                                    {formatPhoneNumber(phoneContact)}
                                 </Paragraph>
                              </Space>
                           </Row>
                        </div>
                     )}
                     <div className='menu-info'>
                        {nameContact !== '' && (
                           <Row style={{ justifyContent: 'center' }}>
                              <div style={{ width: 200 }}>
                                 <Space size={5} align='baseline'>
                                    <MailOutlined />
                                    <Paragraph>
                                       Asesor {''}
                                       {nameContact}
                                    </Paragraph>
                                 </Space>
                              </div>
                           </Row>
                        )}
                        <Row style={{ justifyContent: 'center' }}>
                           <div style={{ width: 200 }}>
                              <Paragraph>{emailContact}</Paragraph>
                           </div>
                        </Row>
                     </div>
                  </div>
               )}
            </>
         )}
      </Sider>
   );
};

export default withRouter(MenuSider);
