import React from 'react';
import { Row, Col, Form } from 'antd';
import moment from 'moment';

const BalanceForm = ({ setModalIsVisible, type, reference }) => {
    const [form] = Form.useForm();

    return (
        <Form name="add-promo" layout="vertical" form={form}>
            <div>
                <Row>
                    <Col
                        xs={24}
                        style={{ backgroundColor: '#F1F5F7', padding: 20 }}
                    >
                        {reference.reference !== null && (
                            <Row>
                                <Col xs={10}>
                                    <p>Referencia</p>
                                </Col>
                                <Col xs={12}>
                                    <p>{reference.reference}</p>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={10}>
                                <p>Folio</p>
                            </Col>
                            <Col xs={12}>
                                <p>{reference.invoiceNum}</p>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col xs={10}>
                                <p>Nombre</p>
                            </Col>
                            <Col xs={12}>
                                <p>{reference.invoiceSerie.name}</p>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={10}>
                                <p>Serie</p>
                            </Col>
                            <Col xs={12}>
                                <p>{reference.serie}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10}>
                                <p>Fecha de recepción</p>
                            </Col>
                            <Col xs={12}>
                                <p>
                                    {moment
                                        .utc(reference.issueDate)
                                        .local()
                                        .format('DD/MM/YYYY')}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10}>
                                <p>Fecha de documento</p>
                            </Col>
                            <Col xs={12}>
                                <p>
                                    {moment
                                        .utc(reference.documentDate)
                                        .local()
                                        .format('DD/MM/YYYY')}
                                </p>
                            </Col>
                        </Row>
                        {reference.category && (
                            <>
                                <Row>
                                    <Col xs={10}>
                                        <p>Categoría:</p>
                                    </Col>
                                    <Col xs={12}>
                                        <p>{reference.category}</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {reference.observations && (
                            <>
                                <Col xs={10}>
                                    <p>Observaciones:</p>
                                </Col>
                                <Row>
                                    <Col xs={24}>
                                        <p>{reference.observations}</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            </div>
        </Form>
    );
};

export default BalanceForm;
