import React, { useEffect, useState } from 'react';
import {
    Table,
    Spin,
    Button,
    notification,
    Modal as ModalAntd,
    Input,
    Space,
    Image,
    Row,
    Col,
} from 'antd';
import {
    DownloadOutlined,
    DeleteOutlined,
    CommentOutlined,
    FileOutlined,
    ExclamationCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import AddCommentForm from '../AddCommentForm';
import AddDocumentsForm from '../AddDocumentsForm';
import Modal from '../../../../General/Modal';
import {
    deleteDocumentApi,
    getDocumentsInfoApi,
} from '../../../../../api/documents';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { useParams } from 'react-router-dom';
import { messageError } from '../../../../../utils/general';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { ROL_MANAGER, ROL_OPERACIONES } from '../../../../../utils/constants';
import useUser from '../../../../../hooks/useUser';

import './DocumentsTable.scss';

export default function DocumentsTable({ idBilling }) {
    const [loadingForm, setLoadingForm] = useState(false);
    const [reloadDocuments, setReloadDocuments] = useState(true);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [documents, setDocuments] = useState([]);
    let { id } = useParams();
    const token = getAccessTokenApi();
    const { confirm } = ModalAntd;
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const { person } = useUser();

    useEffect(() => {
        if (reloadDocuments) {
            const data = {
                reference: id,
                documentType: 'APInvoiceEvidence',
            };
            getDocumentsInfoApi(token, data).then((response) => {
                // console.log(response, 'documentos');
                if (response) {
                    if (response.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }
                    if (response.statusCode === 200) {
                        response.result.forEach((element, index) => {
                            element['key'] = index;
                        });
                        setDocuments(response.result);
                    }
                    setReloadDocuments(false);
                }
            });
        }
    }, [reloadDocuments, token, id]);

    // console.log(documents, 'documents');
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => {handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex);}}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference',
            align: 'center',
            render: (reference, operation) => (
                <p>{`${reference} - ${operation.key + 1}`}</p>
            ),
        },
        {
            title: 'Imagen',
            // dataIndex: 'description',
            key: '3',
            align: 'center',
            render: (fileName, image) => (
                <>
                    {(image.extension === 'jpeg' ||
                        image.extension === 'png') && (
                        <Image
                            src={`data:image/${image.extension};base64, ${image.stringFileContent}`}
                            alt="doc"
                            style={{ width: 100, marginLeft: 20 }}
                        />
                    )}
                    {image.extension !== 'jpeg' &&
                        image.extension !== 'png' && (
                            <FileOutlined
                                style={{ fontSize: 50, color: 'gray' }}
                            />
                        )}
                </>
            ),
        },
        {
            title: 'Nombre del archivo',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Fecha de alta de archivo',
            dataIndex: 'creationDate',
            key: 'creationDate',
            align: 'center',
            sorter: (a, b) =>
                moment(a.creationDate).unix() - moment(b.creationDate).unix(),
            render: (creationDate) => (
                <>{moment.utc(creationDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Usuario',
            dataIndex: 'creationUsername',
            key: 'creationUsername',
            align: 'center',
            ...getColumnSearchProps('creationUsername'),
        },
        {
            title: 'Acción',
            dataIndex: 'extension',
            key: 'extension',
            align: 'center',
            // width: 200,
            render: (extension, data) => (
                <Row gutter={[10, 10]}>
                    <Col xs={24} lg={12}>
                        <Button
                            icon={<DownloadOutlined />}
                            // className="btn-download"
                            type="primary"
                            // style={{ width: 130 }}
                            size="small"
                            href={
                                'data:image/png;base64,' +
                                data.stringFileContent
                            }
                            download={data.name}
                            // className="btn-download-doc"
                            style={{
                                marginBottom: 11,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'inline-flex',
                            }}
                        />
                    </Col>
                    {[ROL_OPERACIONES, ROL_MANAGER].some((r) =>
                        person.roles.includes(r)
                    ) && (
                        <Col xs={24} md={12}>
                            <Button
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() =>
                                    handleDeleteDoc(data.id, data.name)
                                }
                                type="primary"
                                // htmlType="submit"
                                size="small"
                                className="login-form-regresar"
                                // style={{ marginLeft: 20, width: 130, borderRadius: 4 }}
                            />
                        </Col>
                    )}
                </Row>
            ),
        },
        {
            title: 'Comentarios',
            dataIndex: 'hasComments',
            align: 'center',
            key: 'hasComments',
            render: (hasComments, docs) => (
                <>
                    {hasComments === false && (
                        <CommentOutlined
                            style={{ fontSize: 18 }}
                            onClick={() => showModal(docs.id)}
                        />
                    )}
                    {hasComments === true && (
                        <CommentOutlined
                            style={{ fontSize: 18, color: 'green' }}
                            onClick={() => showModal(docs.id)}
                        />
                    )}
                </>
            ),
        },
    ];

    const handleDeleteDoc = (id, name) => {
        confirm({
            title: 'Eliminar documento',
            icon: <ExclamationCircleOutlined />,
            content: `¿Estás seguro que desea eliminar el documento ${name}?, no se podrá recuperar después`,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteDocument(id);
            },
            onCancel() {},
        });
    };

    const deleteDocument = async (id) => {
        setLoadingForm(true);

        const data = {
            documentId: id,
            RefreshToken: '',
        };

        const result = await deleteDocumentApi(token, data);

        // console.log(result, 'Result');
        if (result === undefined) {
            notification['error']({
                message: messageError(),
            });
        } else {
            if (result.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1200);

                return false;
            }

            if (result.statusCode === 200) {
                notification['success']({
                    message: 'Documento eliminado exitosamente',
                });
                setReloadDocuments(true);
            } else {
                let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                if (
                    result.statusCode === 400 ||
                    result.statusCode === 409 ||
                    result.statusCode === 404
                ) {
                    msg = result.description;
                }
                notification['error']({
                    message: msg,
                });
            }
        }
        setLoadingForm(false);
    };

    const showModal = (id) => {
        setTitleModal('Agregar comentario');
        setModalIsVisible(true);
        setContextModal(
            <AddCommentForm
                setModalIsVisible={setModalIsVisible}
                documentId={id}
            ></AddCommentForm>
        );
    };

    const addDocumentModal = (id) => {
        setTitleModal('Agregar documentos');
        setModalIsVisible(true);
        setContextModal(
            <AddDocumentsForm
                setModalIsVisible={setModalIsVisible}
                operationId={id}
                setReloadDocuments={setReloadDocuments}
            ></AddDocumentsForm>
        );
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
        // setbillsExport(extra.currentDataSource);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };
    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <div>
                <Table
                    columns={columns}
                    dataSource={documents}
                    loading={reloadDocuments}
                    scroll={{ x: 800 }}
                    pagination={{ defaultPageSize: 5 }}
                />

                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Button
                            className="btn-download"
                            icon={<DownloadOutlined />}
                            onClick={() => addDocumentModal(idBilling)}
                        >
                            Agregar documento
                        </Button>
                    </Col>
                </Row>
            </div>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
            >
                {contextModal}
            </Modal>
        </Spin>
    );
}
