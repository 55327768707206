import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Spin,
    Form,
    Input,
    Button,
    notification,
    Modal as ModalAntd,
    Tooltip,
    Checkbox,
    Select,
    Tabs,
    Alert,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getSupplierApi, editSupplierApi } from '../../../../../api/supplier';
import { logout } from '../../../../../api/auth';
import { getCompanyIdApi } from '../../../../../api/company';
import {
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import {
    CERTIFICATIONS_CATALOG_ID,
    CITY_CATALOG_ID,
    COUNTRY_CATALOG_ID,
    OPERACIONES_CATALOG_ID,
    STATE_CATALOG_ID,
    UNIT_TYPE_CATALOG_ID,
    TYPE_OF_LOAD_CATALOG_ID,
    SERVICES_CATALOG_ID,
    HAZMAT_CATALOG_ID,
    TYPE_OF_SERVICE_CATALOG_ID,
    PACKAGING_UNIT_CATALOG_ID,
    MEASUREMENT_UNIT_CATALOG_ID,
} from '../../../../../helpers/constants';
import { fetchApi } from '../../../../../helpers/fetch';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import TextArea from 'antd/lib/input/TextArea';

const EditSupplierForm = ({
    setModalIsVisible,
    setReloadSuppliers,
    idSupplier,
    type
}) => {
    const [loadingForm, setLoadingForm] = useState(true);
    const [form] = Form.useForm();
    const [editUserForm, setEditUserForm] = useState([]);
    const companyId = getCompanyIdApi();
    const token = getAccessTokenApi();
    const { confirm } = ModalAntd;
    const [financiales, setFinanciales] = useState([]);
    const [countrySelected, setCountrySelected] = useState(0);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const { TabPane } = Tabs;
    const [textType, setTextType] = useState('');
    const [textDescription, setTextDescription] = useState('');
    const [showAlert, setShowAlert] = useState(false);
	const [disableAllInputs, setDisableAllInputs] = useState(type === 'watch' ? true : false);

    const [operationsCatalog, setOperationsCatalog] = useState([]);
    const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
    const [certificationsCatalog, setCertificationsCatalog] = useState([]);
    const [hazMatCatalog, setHazMatsCatalog] = useState([]);
    const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
    const [packagingCatalog, setPackagingCatalog] = useState([]);
    const [measurementUnitCatalog, setMeasurementUnitCatalog] = useState([]);
    const [servicesCatalog, setServicesCatalog] = useState([]);
    const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);

    useEffect(() => {
        if (loadingForm) {
            const filters = `?role=${'Financial'}`;
            getUsersApi(token, filters)
                .then((response) => {
                    if (response) {
                        let supp = response.result.map((f) => ({
                            value: f.id,
                            label: f.userName,
                        }));
                        setFinanciales(supp);
                    }
                    setLoadingForm(false);
                })
                .catch((err) => {});
        }
    }, [token, loadingForm]);

    useEffect(() => {
        setLoadingForm(true);
        const config = {
            method: METHOD_GET,
            url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
            data: null,
            token,
            // showNotification: true
        };
        fetchApi(config)
            .then((response) => {
                if (response) {
                    let calogsValues = response.result;

                    if (calogsValues) {
                        calogsValues.map((cat, index) => {
                            cat['key'] = index;
                            cat['label'] = cat.description;
                            cat['value'] = cat.id;
                        });
                    }
                    setCountries(calogsValues);
                }
                setLoadingForm(false);
            })
            .catch((err) => {
                console.log(err);
            });
        getCatalogValueHandle(
            STATE_CATALOG_ID,
            setStates,
            setLoadingForm,
            form.getFieldValue('country')
        );
        getCatalogValueHandle(
            CITY_CATALOG_ID,
            setCities,
            setLoadingForm,
            form.getFieldValue('state')
        );
        getCatalogValueHandle(
            OPERACIONES_CATALOG_ID,
            setOperationsCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            UNIT_TYPE_CATALOG_ID,
            setUnitTypeCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            CERTIFICATIONS_CATALOG_ID,
            setCertificationsCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            HAZMAT_CATALOG_ID,
            setHazMatsCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            TYPE_OF_SERVICE_CATALOG_ID,
            setTypeOfServiceCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            PACKAGING_UNIT_CATALOG_ID,
            setPackagingCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            MEASUREMENT_UNIT_CATALOG_ID,
            setMeasurementUnitCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            SERVICES_CATALOG_ID,
            setServicesCatalog,
            setLoadingForm,
        );
        getCatalogValueHandle(
            TYPE_OF_LOAD_CATALOG_ID,
            setTypeOfLoadCatalog,
            setLoadingForm,
        );
        //   getStateCatalogHandle();
    }, [form, token]);

    const onChangeCountryOrigin = (value) => {
        if (value !== countrySelected) {
            form.setFieldsValue({
                OriginZipcode: '',
                OriginState: '',
                OriginCity: '',
                OriginAddress: '',
                OriginPort: '',
                OriginPort: '',
                OriginAirport: '',
                // DestinyZipcode: '',
                // DestinyState: '',
                // DestinyCity: '',
                // DestinyAddress: '',
                // DestinyAirport: '',
                // DestinyPort: '',
            });
        }
        setCountrySelected(value);
        getCatalogValueHandle(
            STATE_CATALOG_ID,
            setStates,
            setLoadingForm,
            value
        );
    };
    const onChangeState = (value) => {
        getCatalogValueHandle(
            CITY_CATALOG_ID,
            setCities,
            setLoadingForm,
            value
        );
    };

    useEffect(() => {
        getSupplierApi(token, idSupplier).then((response) => {
            setEditUserForm([
                {
                    name: ['name'],
                    value: response.result.name,
                },
                {
                    name: ['supplierNum'],
                    value: response.result.supplierNum,
                },
                {
                    name: ['supplierId'],
                    value: response.result.supplierId,
                },
                {
                    name: ['active'],
                    value: response.result.active,
                },
                {
                    name: ['address'],
                    value: response.result.address,
                },
                {
                    name: ['city'],
                    value:
                        response.result.city !== 0
                            ? response.result.city
                            : null,
                },
                {
                    name: ['state'],
                    value:
                        response.result.state !== 0
                            ? response.result.state
                            : null,
                },
                {
                    name: ['zipCode'],
                    value: response.result.zipCode,
                },
                {
                    name: ['country'],
                    value:
                        response.result.country !== 0
                            ? response.result.country
                            : null,
                },
                {
                    name: ['emailAddress'],
                    value: response.result.emailAddress,
                },
                {
                    name: ['contactName'],
                    value: response.result.contactName,
                },
                {
                    name: ['fiscalID'],
                    value: response.result.fiscalID,
                },
                {
                    name: ['phoneNumber'],
                    value: response.result.phoneNumber,
                },
                {
                    name: ['financialId'],
                    value: response.result.financialId,
                },
                {
                    name: ['operationsArray'],
                    value: response.result.operationsArray,
                },
                {
                    name: ['unitsArray'],
                    value: response.result.unitsArray,
                },
                {
                    name: ['certificationsArray'],
                    value: response.result.certificationsArray,
                },
                {
                    name: ['loadTypesArray'],
                    value: response.result.loadTypesArray,
                },
                {
                    name: ['typeOfServicesArray'],
                    value: response.result.typeOfServicesArray,
                },
                {
                    name: ['servicesArray'],
                    value: response.result.servicesArray,
                },
                {
                    name: ['measurementUnitsArray'],
                    value: response.result.measurementUnitsArray,
                },
                {
                    name: ['packagingArray'],
                    value: response.result.packagingArray,
                },
                {
                    name: ['hazMatArray'],
                    value: response.result.hazMatArray,
                },
                {
                    name: ['observations'],
                    value: response.result.observations,
                },
            ]);
        });
        // return () => {
        //     cleanup
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSupplier]);

    const handleEditSupplier = (values) => {
        confirm({
            title: 'Editar proveedor',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estás seguro que deseas guardar datos modificados?',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                editSupplierFunction(values);
            },
            onCancel() {},
        });
    };

    const editSupplierFunction = async (values) => {
        setLoadingForm(true);
        let data = values;
        data['parentSupplierId'] = '';
        data['level'] = 0;
        data['company'] = companyId;
        let response = null;
        try {
            response = await editSupplierApi(token, data, idSupplier);
        } catch (error) {
        } finally {
            setLoadingForm(false);
        }
        if (response === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Inténtelo más tarde',
            });
            return false;
        }
        if (response.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });
            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (response.statusCode === 200) {
            notification['success']({
                message: 'Proveedor editado con éxito',
            });
            setModalIsVisible(false);
        } else {
            let messageError = 'Ocurrió un error, Inténtelo otra vez';
            if (response.statusCode === 409) {
                messageError = response.description;
            }
            if (response.statusCode === 400) {
                messageError = response.description;
            }
            notification['error']({
                message: messageError,
            });
        }
        setReloadSuppliers(true);

        setLoadingForm(false);
    };
    const errorFields = ({ values, errorFields, outOfDate }) => {
        if (!values.name || !values.supplierId) {
            setTextType('Datos incompletos');
            setTextDescription(
                'Complete los campos obligatorios de detalle de proveedor'
            );
            setShowAlert(true);

            setTimeout(() => {
                setShowAlert(false);
            }, 10000);
        }
    };
    return (
        <Spin spinning={loadingForm} tip="Cargando...">
            <Form
                name="add-promo"
                layout="vertical"
                form={form}
                onFinish={handleEditSupplier}
                fields={editUserForm}
                onFieldsChange={(changedFields, allFields) => {
                    setEditUserForm(allFields);
                }}
                onFinishFailed={errorFields}
            >
                <div>
                    <Tabs defaultActiveKey="1" type="card" size={'middle'}>
                        <TabPane tab="Detalle" key="1">
                            <div>
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label="Nombre de proveedor:"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Por favor ingrese un nombre',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label="Id de proveedor:"
                                            name="supplierId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Por favor ingrese un Id',
                                                },
                                            ]}
                                            hidden
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label="Número de proveedor:"
                                            name="supplierNum"
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label="Proveedor estatus:"
                                            name="active"
                                            valuePropName="checked"
                                        >
                                            <Checkbox disabled={disableAllInputs}>Activo</Checkbox>
                                        </Form.Item>

                                        <Form.Item
                                            label="Estatus:"
                                            name="active"
                                            hidden
                                        >
                                            <Checkbox checked disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} md={16}>
                                        <Form.Item
                                            label="Correo electrónico:"
                                            name="emailAddress"
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item label="RFC:" name="fiscalID">
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} md={16}>
                                        <Form.Item
                                            label="Nombre de contacto:"
                                            name="contactName"
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={
                                                <span>
                                                    Teléfono:&nbsp;
                                                    <Tooltip title="El número telefónico debe contener 10 dígitos">
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </span>
                                            }
                                            name="phoneNumber"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Por favor ingresa número telefónico',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (
                                                            !value ||
                                                            value.toString()
                                                                .length === 10
                                                        ) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(
                                                            'Por favor ingresa número telefónico a 10 dígitos'
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {financiales && (
                                    <Form.Item
                                        label="Financiero:"
                                        name="financialId"
                                        rules={[
                                            {
                                                message:
                                                    'Por favor seleccione un financiero',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            allowClear={true}
                                            disabled={disableAllInputs}
                                        >
                                            {financiales.map((f) => (
                                                <Select.Option
                                                    key={f.value}
                                                    value={f.value}
                                                >
                                                    {f.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}

                                <Form.Item
                                    label="Observaciones:"
                                    name="observations"
                                >
                                    <TextArea placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                </Form.Item>
                            </div>
                        </TabPane>
                        <TabPane tab="Ubicación" key="2">
                            <div>
                                <Row gutter={16}>
                                    <Col xs={24} md={8}>
                                        <Form.Item label="País:" name="country">
                                            <Select
                                                placeholder="Selecciona una opción"
                                                disabled={disableAllInputs}
                                                onChange={onChangeCountryOrigin}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {countries.map((s) => (
                                                    <Select.Option
                                                        key={s.value}
                                                        value={s.value}
                                                    >
                                                        {s.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item label="Estado:" name="state">
                                            <Select
                                                // disabled={disableAllInputs}
                                                placeholder="Selecciona una opción"
                                                disabled={disableAllInputs}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                onChange={onChangeState}
                                                // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                                            >
                                                {states.map((s) => (
                                                    <Select.Option
                                                        key={s.value}
                                                        value={s.value}
                                                    >
                                                        {s.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item label="Ciudad:" name="city">
                                            <Select
                                                // disabled={disableAllInputs}
                                                placeholder="Selecciona una opción"
                                                disabled={disableAllInputs}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                                            >
                                                {cities.map((s) => (
                                                    <Select.Option
                                                        key={s.value}
                                                        value={s.value}
                                                    >
                                                        {s.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col xs={24} md={16}>
                                        <Form.Item
                                            label="Dirección:"
                                            name="address"
                                        >
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item label="CP:" name="zipCode">
                                            <Input placeholder="Ingresa tu información" disabled={disableAllInputs}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab="Cátalogos" key="3" forceRender>
                            <Row gutter={16}>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        label="Operaciones:"
                                        name="operationsArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {operationsCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        label="Certificaciones:"
                                        name="certificationsArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {certificationsCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={12} xs={24}>
                                    <Form.Item label="Unidades:" name="unitsArray">
                                        <Select
                                            allowClear
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {unitTypeCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        label="Tipo de carga:"
                                        name="loadTypesArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {typeOfLoadCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        label="Servicios:"
                                        name="servicesArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {servicesCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item  
                                        label="Tipo servicio:"
                                        name="typeOfServicesArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {typeOfServiceCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={12} xs={24}>
                                    {/* <Form.Item
                                        label="Unidad de medida:"
                                        name="measurementUnitsArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {measurementUnitCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                                </Col>
                                <Col md={12} xs={24}>
                                    {/* <Form.Item
                                        label="Embalaje:"
                                        name="packagingArray"
                                    >
                                        <Select
                                            placeholder="Selecciona una opción"
                                            mode="multiple"
                                            allowClear
                                            showArrow
                                            disabled={disableAllInputs}
                                        >
                                            {packagingCatalog.map((s) => (
                                                <Select.Option
                                                    key={s.value}
                                                    value={s.value}
                                                >
                                                    {s.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item> */}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col ms={12} xs={24}><Form.Item
                                label="HAZ MAT:"
                                name="hazMatArray"
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    disabled={disableAllInputs}
                                >
                                    {hazMatCatalog.map((s) => (
                                        <Select.Option
                                            key={s.value}
                                            value={s.value}
                                        >
                                            {s.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item></Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                    {showAlert && (
                        <Alert
                            message={textType}
                            description={textDescription}
                            type="warning"
                            showIcon
                            style={{ marginBottom: 20 }}
                        />
                    )}
                    <Row xs={24} className="table-company">
                        <Col xs={20}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="btn-enviar"
                                            block
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default EditSupplierForm;
