import LayoutAdmin from '../layout/LayoutAdmin';
import LayoutBasic from '../layout/LayoutBasic';

//Admin Pages
import AdminHome from '../pages/Admin';
import AdminBilling from '../pages/Admin/Billing';
import AdminPayment from '../pages/Admin/Payment';
import AdminClarifications from '../pages/Admin/Clarifications';
import AdminClarificationsDetail from '../pages/Admin/Clarifications/ClarificationsDetail';
import AdminAddClarifications from '../pages/Admin/Clarifications/AddClarificationPage';
import AdminAddClarificationPayment from '../pages/Admin/Clarifications/AddClarificationPaymentPage';
import AdminProfile from '../pages/Admin/Profile';
import AdminConfiguration from '../pages/Admin/Configuration';
import AdminCompanyData from '../pages/Admin/Configuration/CompanyData/CompanyData';
import AdminContact from '../pages/Admin/Configuration/ContactPage';
import AdminSeries from '../pages/Admin/Configuration/SeriesPage';
import Administration from '../pages/Admin/Administration';
import SuplierCatalog from '../pages/Admin/Administration/Suplier';
import SuppliersContacts from '../pages/Admin/SupplierPage/SupplierContactsPage';
import SuppliersContainerYard from '../pages/Admin/SupplierPage/SuppliersContainerYard';

import UsersCatalog from '../pages/Admin/Administration/Users';
import AlertsCatalog from '../pages/Admin/Administration/Alert';
import BilllingDocument from '../pages/Admin/Billing/Documents';
import AdminSuppliers from '../pages/Admin/SupplierPage';
import AdminFinancial from '../pages/Admin/SuppliersAdmin';
import AdminAddClarificationDocuments from '../pages/Admin/Clarifications/Documents/Documents';
import ClientsCatalog from '../pages/Admin/Administration/Clients';

//Page Clientes
import ClientBalance from '../pages/PortalCliente/Balance';
import ClientOperation from '../pages/PortalCliente/Operation';
import ClientOperationDetail from '../pages/PortalCliente/Operation/OperationDetailPage/OperationDetailPage';
import ClientOperationDocument from '../pages/PortalCliente/Operation/Documents/Documents';
import ClientBilling from '../pages/PortalCliente/Billing';
import ClientClarifications from '../pages/PortalCliente/Clarifications';
import AdminCustomers from '../pages/PortalCliente/CustomersPage';
import CustomerClients from '../pages/PortalCliente/CustomersPage/CustomerContactsPage';

//Pages 2 Logistic
import Sales from '../pages/Logistic/Sales';
import Pricing from '../pages/Logistic/Pricing';
import Traffic from '../pages/Logistic/Traffic';
import Quote from '../pages/Logistic/QuotePage';
import AddQuote from '../pages/Logistic/QuotePage/AddQuotePage';
import LinesQuote from '../pages/Logistic/QuotePage/LinesQuote';
import Catalog from '../pages/Admin/Catalog';
import Modules from '../pages/Admin//Catalog/Modules';
import ViewPdf from '../components/Logistic/Quote/QuotePdf/ViewPdf';

//Pages
import Login from '../pages/Login';

//other
import Error404 from '../pages/Error404';
import PasswordRecovery from '../pages/PasswordRecovery/PasswordRecovery';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import {
  ROL_ADMIN,
  ROL_MANAGER,
  ROL_OPERACIONES,
  ROL_SUPPLIER,
  ROL_TODOS,
  ROL_FINANCIAL,
  ROL_CUSTOMER,
  ROL_VENTAS,
  ROL_PRICING,
} from '../utils/constants';

const routes = [
  {
    path: '/login',
    component: LayoutBasic,
    exact: false,
    routes: [
      {
        path: '/login',
        component: Login,
        exact: true,
      },
      {
        path: '/login/recuperar-contraseña',
        component: PasswordRecovery,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: '/Account',
    component: LayoutBasic,
    exact: false,
    routes: [
      {
        path: '/Account/ResetPassword',
        component: ResetPassword,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: '/',
        component: AdminHome,
        roles: [
          ROL_SUPPLIER,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
        ],
        exact: true,
      },
      {
        path: '/estado/cuentas',
        component: ClientBalance,
        roles: [ROL_CUSTOMER, ROL_FINANCIAL],

        exact: true,
      },
      {
        path: '/facturacion',
        component: AdminBilling,
        roles: [
          ROL_SUPPLIER,
          ROL_CUSTOMER,
          // ROL_MANAGER,
          // ROL_OPERACIONES,
          // ROL_FINANCIAL,
        ],
        exact: true,
      },
      {
        path: '/facturacion/clientes',
        component: ClientBilling,
        roles: [ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
        exact: true,
      },
      {
        path: '/facturacion/proveedores',
        component: AdminBilling,
        roles: [ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
        exact: true,
      },
      {
        path: '/facturacion/detalles/documentos/:id',
        roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
        component: BilllingDocument,
        exact: true,
      },
      {
        path: '/pagos',
        component: AdminPayment,
        roles: [ROL_SUPPLIER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
        exact: true,
      },
      {
        path: '/aclaraciones',
        component: AdminClarifications,
        roles: [
          ROL_SUPPLIER,
          ROL_CUSTOMER,
          // ROL_MANAGER,
          // ROL_OPERACIONES,
          // ROL_FINANCIAL,
        ],
        exact: true,
      },
      {
        path: '/aclaraciones/clientes',
        component: ClientClarifications,
        roles: [ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
        exact: true,
      },
      {
        path: '/aclaraciones/proveedores',
        component: AdminClarifications,
        roles: [ROL_SUPPLIER, ROL_OPERACIONES, ROL_FINANCIAL],
        exact: true,
      },
      {
        path: '/aclaraciones/detail',
        component: AdminClarificationsDetail,
        roles: [
          ROL_SUPPLIER,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
        ],
        exact: true,
      },
      {
        path: '/aclaraciones/archivos/:idNote',
        component: AdminAddClarificationDocuments,
        roles: [
          ROL_SUPPLIER,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
        ],
        exact: true,
      },
      {
        path: '/aclaraciones/detail/:idAclaracion',
        component: AdminClarificationsDetail,
        roles: [
          ROL_SUPPLIER,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
        ],
        exact: true,
      },
      {
        path: '/aclaraciones/nueva/:idAclaracion',
        component: AdminAddClarifications,
        roles: [
          ROL_SUPPLIER,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
        ],
        exact: true,
      },
      {
        path: '/aclaraciones/nueva/cp/:idAclaracion',
        component: AdminAddClarificationPayment,
        roles: [
          ROL_SUPPLIER,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
        ],
        exact: true,
      },
      {
        path: '/perfil',
        component: AdminProfile,
        roles: ROL_TODOS,
        exact: true,
      },
      {
        path: '/configuracion',
        roles: [ROL_ADMIN],
        component: AdminConfiguration,
        exact: true,
      },
      {
        path: '/configuracion/compañia',
        roles: [ROL_ADMIN],
        component: AdminCompanyData,
        exact: true,
      },
      {
        path: '/configuracion/contacto',
        roles: [ROL_ADMIN],
        component: AdminContact,
        exact: true,
      },
      {
        path: '/configuracion/series',
        roles: [ROL_ADMIN],
        component: AdminSeries,
        exact: true,
      },
      {
        path: '/administracion',
        roles: [ROL_ADMIN, ROL_FINANCIAL],
        component: Administration,
        exact: true,
      },
      {
        path: '/administracion/proveedores',
        roles: [ROL_ADMIN],
        component: SuplierCatalog,
        exact: true,
      },
      {
        path: '/administracion/usuarios',
        roles: [ROL_ADMIN],
        component: UsersCatalog,
        exact: true,
      },
      {
        path: '/administracion/alertas',
        roles: [ROL_ADMIN, ROL_FINANCIAL],
        component: AlertsCatalog,
        exact: true,
      },
      {
        path: '/administracion/clientes',
        component: ClientsCatalog,
        roles: [ROL_ADMIN],
        exact: true,
      },
      {
        path: '/proveedores',
        component: AdminSuppliers,
        roles: [ROL_FINANCIAL, ROL_MANAGER],
        exact: true,
      },
      {
        path: '/administracion/proveedores/:iduser',
        component: AdminFinancial,
        roles: [ROL_ADMIN],
        exact: true,
      },
      {
        path: '/ventas',
        component: Sales,
        roles: [ROL_VENTAS, ROL_MANAGER, ROL_ADMIN],
        exact: true,
      },
      {
        path: '/pricing/:pricingType',
        component: Pricing,
        roles: [ROL_PRICING, ROL_MANAGER, ROL_ADMIN],
        exact: true,
      },
      {
        path: '/trafico',
        component: Traffic,
        roles: [ROL_PRICING, ROL_MANAGER, ROL_ADMIN],
        exact: true,
      },
      {
        path: '/catalog',
        component: Catalog,
        roles: [ROL_ADMIN],
        exact: true,
      },
      {
        path: '/operaciones',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: ClientOperation,
        exact: true,
      },
      {
        path: '/operaciones/detalle/:id',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL],
        component: ClientOperationDetail,
        exact: true,
      },
      {
        path: '/operaciones/detalles/documentos/:id',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: ClientOperationDocument,
        exact: true,
      },
      {
        path: '/modulo/:moduleID',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: Modules,
        exact: true,
      },
      {
        path: '/cotizacion',
        roles: [ROL_VENTAS, ROL_MANAGER, ROL_ADMIN],
        component: Quote,
        exact: true,
      },
      {
        path: '/cotizacion/nueva',
        roles: [ROL_VENTAS, ROL_MANAGER, ROL_ADMIN],
        component: AddQuote,
        exact: true,
      },
      {
        path: '/lineas/cotizacion/:quoteId',
        roles: [ROL_VENTAS, ROL_MANAGER, ROL_ADMIN],
        component: LinesQuote,
        exact: true,
      },
      {
        path: '/cotizacion/:quoteId/pdf',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: ViewPdf,
        exact: true,
      },
      {
        path: '/proveedores/contactos/:supplierId',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: SuppliersContacts,
        exact: true,
      },
      {
        path: '/proveedores/patio/:supplierId',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: SuppliersContainerYard,
        exact: true,
      },
      {
        path: '/clientes',
        component: AdminCustomers,
        roles: [ROL_FINANCIAL, ROL_MANAGER],
        exact: true,
      },
      {
        path: '/clientes/contactos/:clientId',
        roles: [ROL_CUSTOMER, ROL_MANAGER, ROL_OPERACIONES],
        component: CustomerClients,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
