import React from 'react';
import { Row, Col, Table, Typography } from 'antd';

import './TableHome.scss';
import { formatMoney } from '../../../utils/general';

export default function TableHome({ title, dataTable }) {
    const { Title } = Typography;
    const columns = [
        {
            title: 'Serie',
            key: 'serie',
            dataIndex: 'serie',
        },
        // {
        //     title: 'Serie',
        //     dataIndex: 'serie',
        // },
        {
            title: 'Folio',
            key: 'folio',
            dataIndex: 'folio',
        },
        {
            title: 'Moneda',
            key: 'currencyId',
            dataIndex: 'currencyId',
        },
        {
            title: 'Monto',
            key: 'total',
            dataIndex: 'total',
            render: (total) => '$' + formatMoney(total),
        },
    ];

    return (
        <div className="table-home">
            <Row>
                <Col span={24} className="table-home__title">
                    <Title level={5} style={{ textTransform: 'uppercase' }}>
                        {title}
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        scroll={{ x: 200 }}
                        columns={columns}
                        dataSource={dataTable ?? []}
                        pagination={{ position: ['none'] }}
                    />
                    {/* {JSON.stringify({ dataTable })} */}
                </Col>
            </Row>
        </div>
    );
}
