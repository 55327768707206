import React, { useEffect, useState } from 'react';
import { Button, Modal as ModalAntd, Table, Tooltip, Tag } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import AddEditPricingForm from '../AddEditPricingForm/AddEditPricingForm';
import Modal from '../../General/Modal';
import { formatMoney } from '../../../utils/general';
import { METHOD_GET } from '../../../utils/constants';
import { API_URL_RFQ } from '../../../helpers/urls';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import moment from 'moment';
import { getCatalogValueHandle } from '../../../helpers/handlerFunctionApi';
import {
  SERVICES_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
} from '../../../helpers/constants';

const RfqTable = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rfqs, setRfqs] = useState([]);
  // const hasSelected = selectedRowKeys.length > 0;
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [serviceType, setServiceType] = useState([]);
  const [service, setService] = useState([]);
  const [typeOfLoad, setTypeOfLoad] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [filterServiceType, setFilterServiceType] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  useEffect(() => {
    setIsLoadingTable(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_RFQ}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        // console.log(response, 'response');
        if (response.statusCode === 200) {
          const allRfqs = response.result;
          allRfqs.map((e) => (e['key'] = e.id));
          setRfqs(allRfqs);
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, token]);

  useEffect(() => {
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setServiceType,
      setIsLoading,
      0
    );
    getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoad,
      setIsLoading,
      0
    );
  }, []);
  useEffect(() => {
    let filters = [];
    service.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterService(filters);
  }, [service]);
  useEffect(() => {
    let filters = [];
    serviceType.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterServiceType(filters);
  }, [serviceType]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showModal = (type, rfq) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar RFQ');
      setContextModal(
        <AddEditPricingForm
          type='Add'
          setModalIsVisible={setModalIsVisible}
          rateId={rfq.id}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar RFQ #${rfq.id}`);
      setContextModal(
        <AddEditPricingForm
          type='Edit'
          setModalIsVisible={setModalIsVisible}
          rateId={rfq.id}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`RFQ #${rfq.id}`);
      setContextModal(
        <AddEditPricingForm
          type='watch'
          setModalIsVisible={setModalIsVisible}
          rateId={rfq.id}
          setReloadTable={setReloadTable}
          isRfq={true}
        ></AddEditPricingForm>
      );
    }
  };

  const columns = [
    {
      title: 'Estatus',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      width: 120,
      fixed: 'left',
      filters: [
        {
          text: 'Completada',
          value: true,
        },
        { text: 'Borrador', value: false },
      ],
      onFilter: (value, record) => record.isCompleted === value,
      align: 'center',
      render: (isCompleted) => (
        <Tag
          icon={isCompleted ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
          color={isCompleted ? 'green' : 'red'}
        >
          {isCompleted ? 'Completa' : 'Incompleta'}
        </Tag>
      ),
    },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      fixed: 'left',
      filters: filterService,
      onFilter: (value, record) => record.service === value,
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfService',
      key: 'typeOfService',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filterServiceType,
      onFilter: (value, record) => record.typeOfService === value,
    },
    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountry',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCity',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'originZipCode',
          key: 'originZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'originPort',
          key: 'originPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'originAirport',
          key: 'originAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'originAddress',
          key: 'originAddress',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinyCountry',
          key: 'destinyCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinyCity',
          key: 'destinyCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'destinyZipCode',
          key: 'destinyZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'destinyPort',
          key: 'destinyPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'destinyAirport',
          key: 'destinyAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'destinyAddress',
          key: 'destinyAddress',
          align: 'center',
          width: 100,
        },
      ],
    },
    {
      title: 'Costo',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 100,
      fixed: 'right',
      align: 'center',
      render: (unitPrice) => <span>${formatMoney(unitPrice)}</span>,
    },
    {
      title: 'Vigencia',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      fixed: 'right',
      align: 'center',
      render: (endDate) => <span>{`${moment.unix(endDate).format('L')}`}</span>,
    },
    {
      title: 'Aciones',
      key: 'operation',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (rfq) => (
        <>
          {!rfq.isCompleted && (
            <Tooltip title='Editar'>
              <Button
                style={{ marginLeft: 2 }}
                type='primary'
                shape='default'
                icon={<EditOutlined />}
                onClick={() => showModal('Edit', rfq)}
              />
            </Tooltip>
          )}
          {/* <Tooltip title="Eliminar">
                        <Button style={{marginLeft: 10}} type="primary" danger shape="default" icon={<DeleteOutlined /> } />
                    </Tooltip> */}
          <Tooltip title='Ver más información'>
            <Button
              style={{ marginLeft: 10 }}
              type='primary'
              ghost
              shape='default'
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', rfq)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* <Button
                className="btn-download"
                onClick={() => showModal('Add')}
                icon={<PlusCircleOutlined />}
            >
                Agregar
            </Button>
            <Button
                disabled={!hasSelected}
                className="btn-delete"
                onClick={() => showModal('Edit')}
                icon={<DeleteOutlined />}
            >
                Eliminar
            </Button>
            <Button className="btn-download" icon={<ReloadOutlined />}>
                Refrescar
            </Button>
            {selectedRowKeys.length > 0 && (
                <p>Seleccionados: {selectedRowKeys.length} elementos</p>
            )} */}

      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={rfqs}
        columns={columns}
        bordered
        size='middle'
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className='table-striped-rows'
        // rowSelection={rowSelection}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default RfqTable;
