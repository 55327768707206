import React, { useEffect, useState } from 'react';
import {
    Table,
    notification,
    Tag,
    Button,
    Col,
    Row,
    Modal as ModalAntd,
} from 'antd';
import { getAllClients, deleteClientApi } from '../../../../api/clients';
import { getAccessTokenApi } from '../../../../api/auth';
import AddClientForm from '../ClientsTable/AddClientForm';
import EditClientForm from '../ClientsTable/EditClientForm';
import Modal from '../../../General/Modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import './ClientsTable.scss';

export default function ClientsTable() {
    const [reloadClients, setReloadClients] = useState(true);
    const [clientSelected, SetClientSelected] = useState(null);
    const [clientsTable, setClientsTable] = useState([]);
    // const [loadingForm, setLoadingForm] = useState(false);
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const { confirm } = ModalAntd;

    useEffect(() => {
        if (reloadClients) {
            const token = getAccessTokenApi();

            getAllClients(token).then((response) => {
                // console.log(response, 'clientes');
                if (response) {
                    if (response.statusCode === 200) {
                        response.result.forEach((element, index) => {
                            element['key'] = index;
                        });
                        setClientsTable(response.result);
                    }
                } else {
                    notification['error']({
                        message: 'Inténtelomas tarde',
                    });

                    // setTimeout(() => {
                    //     logout();
                    //     window.location.reload();
                    // }, 1500);
                    // return;
                }
                setReloadClients(false);
            });
        }
    }, [reloadClients]);
    const checkClient = (id) => {
        if (clientSelected === id) {
            SetClientSelected(null);
        } else {
            SetClientSelected(id);
        }
    };
    const columns = [
        {
            title: 'Selecciona',
            width: 100,
            dataIndex: 'id',
            key: 'company',
            render: (id) => (
                <Checkbox
                    // disabled={!clientsTable?.find((st) => st.id === id)?.active}
                    onChange={(val) => checkClient(id)}
                    checked={clientSelected === id}
                    className="button-detail"
                ></Checkbox>
            ),
        },
        {
            title: 'ID cliente',
            width: 100,
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Cliente',
            dataIndex: 'name',
            key: 'name',
            width: 150,
        },
        {
            title: 'Estatus',
            dataIndex: 'active',
            key: 'active',
            filters: [
                { text: 'Activo', value: true },
                { text: 'Inactivo', value: false },
            ],
            filteredValue: filteredInfo.active || null,
            onFilter: (value, record) => record.active === value,
            render: (active) => (
                <Tag color={active === true ? 'green' : 'red'}>
                    {active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase()}
                </Tag>
            ),
            width: 150,
        },
        {
            title: 'RFC',
            dataIndex: 'fiscalID',
            key: 'fiscalID',
            width: 150,
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
            width: 150,
        },
        // {
        //     title: 'Ejecutivo CTA',
        //     dataIndex: 'email',
        //     key: 'email',
        //     width: 150,
        // },
    ];

    const handleChange = (pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
    };

    const showModal = (type) => {
        if (type === 'Add') {
            setModalIsVisible(true);
            setTitleModal('Agregar cliente');
            setContextModal(
                <AddClientForm
                    setModalIsVisible={setModalIsVisible}
                    setReloadClients={setReloadClients}
                ></AddClientForm>
            );
        } else if (type === 'Edit') {
            if (clientSelected !== null) {
                setModalIsVisible(true);

                setTitleModal('Editar cliente');
                setContextModal(
                    <EditClientForm
                        setModalIsVisible={setModalIsVisible}
                        setReloadClients={setReloadClients}
                        idClient={clientSelected}
                    ></EditClientForm>
                );
            } else if (clientSelected === null) {
                notification['error']({
                    message: 'Debes seleccionar un cliente',
                });
            }
        }
    };
    const handleDeleteClient = (id) => {
        if (clientSelected !== null) {
            confirm({
                title: 'Desactivar cliente',
                icon: <ExclamationCircleOutlined />,
                content:
                    '¿Estás seguro que deseas desactivar el cliente seleccionado?, no podrá reactivarse después',
                okText: 'Si',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    deleteClient(id);
                },
                onCancel() {},
            });
        } else {
            notification['error']({
                message: 'Debes seleccionar un usuario a desactivar',
            });
        }
    };

    const deleteClient = async (id) => {
        const accessToken = getAccessTokenApi();
        const result = await deleteClientApi(accessToken, id);
        if (result?.statusCode === 409) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 405) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 404) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 200) {
            notification['success']({
                message: 'Usuario desactivado exitosamente.',
            });
            SetClientSelected(undefined);
        }
        setReloadClients(true);
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Button
                        className="btn-download"
                        onClick={() => showModal('Add')}
                    >
                        Agregar
                    </Button>

                    <Button
                        className="btn-download"
                        onClick={() => showModal('Edit')}
                    >
                        Editar
                    </Button>
                    <Button
                        className="btn-download"
                        onClick={() => handleDeleteClient(clientSelected)}
                    >
                        Desactivar
                    </Button>
                </Col>
            </Row>
            {/* <Spin spinning={loadingForm} tip="Cargando..."> */}
            <Table
                onChange={handleChange}
                columns={columns}
                dataSource={clientsTable}
                scroll={{ x: 500 }}
                loading={reloadClients}
                // pagination={{ defaultPageSize: 5 }}
            />
            {/* </Spin> */}
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </>
    );
}
