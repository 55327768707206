import { logout } from '../api/auth';
import { notification } from 'antd';

export function returnTime12Hours(horaCompleta) {
  var hora = horaCompleta.split(':')[0];
  var detailHour = 'A.M.';
  if (hora >= 12) {
    hora = '0' + (hora - 12);
    detailHour = 'P.M.';
  }

  if (hora === 0) {
    hora = 12;
  }
  return (
    (hora.length === 1 ? '0' + hora : hora) +
    ':' +
    (horaCompleta.split(':')[1].length === 1
      ? '0' + horaCompleta.split(':')[1]
      : horaCompleta.split(':')[1]) +
    ' ' +
    detailHour
  );
}

export function formatMoney(number) {
  if (number === undefined) return '0.00';
  let priceFormat = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'MXN',
  });

  let priceString = priceFormat.toString();
  priceString = priceString.replace('MX', '');
  return (priceFormat = priceString.replace('$', ''));
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function isMobile() {
  if (
    detectedMobile().includes('Windows') ||
    detectedMobile().includes('Mac')
  ) {
    return false;
  } else {
    return true;
  }
}

function detectedMobile() {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Mac/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows/i)
  );
}

export function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
}

export function detectBrowser() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}

export function getFullAddress(person) {
  if (
    person.neighborhood !== null ||
    person.city !== null ||
    person.zipCode !== null ||
    person.state !== null
  ) {
    return `${person.address} ${person.neighborhood} ${person.city} ${person.zipCode} ${person.state}`;
  } else {
    return person.address;
  }
}

export function userLogOut() {
  notification['error']({
    message: 'Usuario no autorizado.',
  });

  setTimeout(() => {
    logout();
    window.location.reload();
  }, 1200);
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function suscriptionType(numberType) {
  let type;
  switch (numberType) {
    case 1:
      type = 'Basic';
      break;

    case 2:
      type = 'Plus';
      break;

    case 3:
      type = 'Premium';
      break;

    default:
      type = '';
      break;
  }
  return type;
}

export function companySubscriptionStatus(numberType) {
  let type;
  switch (numberType) {
    case 0:
      type = 'Iniciada';
      break;

    case 1:
      type = 'Activa';
      break;

    case 2:
      type = 'Inactiva';
      break;

    case 3:
      type = 'Caducada';
      break;

    case 4:
      type = 'Cancelada por Super Admin';
      break;

    case 5:
      type = 'Cancelada por Compañía';
      break;

    default:
      type = 'Basic';
      break;
  }
  return type;
}

export function getPriceSubscription(type) {
  let price = 0;
  switch (type) {
    case 1:
      price = 469;
      break;

    case 2:
      price = 799;
      break;

    case 3:
      price = 1299;
      break;

    default:
      break;
  }

  return price;
}

export function messageError() {
  return 'Verifique su conexión a internet, si no se resuelve intente más tarde.';
}
export function messageErrorGeneral() {
  return 'Ocurrió un error, inténtelo  más tarde y si el problema persiste comuníquese con soporte.';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function nameMonth(noMonth) {
  switch (noMonth) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return '';
  }
}
