import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button, Divider, DatePicker, Typography } from 'antd';
import {
    DownOutlined,
    HistoryOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import moment from 'moment';

export default function DatePickerOptions({
    filterDate,
    typeDoc,
    dateDisplay,
}) {
    const [dateSelected, setDateSelected] = useState({
        dateStart: 0,
        dateEnd: 0,
        dateString: '',
    });
    const [optionSelected, setOptionSelected] = useState('1');
    const { RangePicker } = DatePicker;

    useEffect(() => {
        const now = moment();
        const lastWeek = moment().subtract(7, 'days');
        const date = `${lastWeek.format('DD/MM/YYYY')} - ${now.format(
            'DD/MM/YYYY'
        )}`;
        setDateSelected({
            dateStart: lastWeek.unix(),
            dateEnd: now.unix(),
            dateString: date,
        });
    }, []);

    useEffect(() => {
        if (dateDisplay) {
            if (dateDisplay.length > 0) {
                const dateReceive = `${dateDisplay[0].format(
                    'DD/MM/YYYY'
                )} - ${dateDisplay[1].format('DD/MM/YYYY')}`;
                setDateSelected({
                    dateStart: dateDisplay[0],
                    dateEnd: dateDisplay[1],
                    dateString: dateReceive,
                });
            }
        }
    }, [dateDisplay]);

    const menu = (
        <Menu
            className="chart-amount-select"
            defaultSelectedKeys={[optionSelected]}
            selectedKeys={optionSelected}
        >
            <Menu.Item
                key="1"
                icon={<HistoryOutlined />}
                onClick={() => handleChangeDate('1', typeDoc)}
            >
                Últimos 7 días
            </Menu.Item>
            <Menu.Item
                key="2"
                icon={<HistoryOutlined />}
                onClick={() => handleChangeDate('2', typeDoc)}
            >
                Mes actual
            </Menu.Item>
            <Menu.Item
                key="3"
                icon={<HistoryOutlined />}
                onClick={() => handleChangeDate('3', typeDoc)}
            >
                Mes anterior
            </Menu.Item>
            <Menu.Item
                key="4"
                icon={<HistoryOutlined />}
                onClick={() => handleChangeDate('4', typeDoc)}
            >
                Últimos 3 meses
            </Menu.Item>
            <Menu.Item
                key="5"
                icon={<HistoryOutlined />}
                onClick={() => handleChangeDate('5', typeDoc)}
            >
                Últimos 6 meses
            </Menu.Item>
            <Menu.Item
                key="6"
                icon={<HistoryOutlined />}
                onClick={() => handleChangeDate('6', typeDoc)}
            >
                Últimos 12 meses
            </Menu.Item>
            <Menu.Item key="7" disabled className="other-range">
                <Divider>Otro rango</Divider>
                <div className="container-rangepicker">
                    <RangePicker onChange={onChange} />
                </div>
            </Menu.Item>
        </Menu>
    );

    function onChange(value, dateString) {
        if (value) {
            setDateSelected({
                dateStart: value && value[0],
                dateEnd: value && value[1],
                dateString: `${value[0].format(
                    'DD/MM/YYYY'
                )} - ${value[1].format('DD/MM/YYYY')}`,
            });
            setOptionSelected('');
            filterDate(value, typeDoc);
        }
    }

    const handleChangeDate = (type, typeDoc) => {
        let dateEnd = moment();
        let dateStart = moment().subtract(7, 'days');
        let dateString = `${dateStart.format('DD/MM/YYYY')} - ${dateEnd.format(
            'DD/MM/YYYY'
        )}`;

        let arregloDias = [];
        switch (type) {
            // last-7-days
            case '1':
                dateEnd = moment();
                dateStart = moment().subtract(7, 'days');
                dateString = `${moment()
                    .subtract(7, 'days')
                    .format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;

                break;

            // currentMonth
            case '2':
                dateStart = moment().startOf('month');
                dateEnd = moment().endOf('month');
                dateString = `${moment()
                    .startOf('month')
                    .format('DD/MM/YYYY')} - ${moment()
                    .endOf('month')
                    .format('DD/MM/YYYY')}`;
                break;

            // lastmonth
            case '3':
                dateStart = moment().startOf('month').subtract(1, 'month');
                dateEnd = moment().endOf('month').subtract(1, 'month');
                dateString = `${moment()
                    .startOf('month')
                    .subtract(1, 'month')
                    .format('DD/MM/YYYY')} - ${moment()
                    .endOf('month')
                    .subtract(1, 'month')
                    .format('DD/MM/YYYY')}`;
                break;

            // last3month
            case '4':
                dateStart = moment().startOf('month').subtract(2, 'month');
                dateEnd = moment().endOf('month').subtract(0, 'month');
                dateString = `${moment()
                    .startOf('month')
                    .subtract(2, 'month')
                    .format('DD/MM/YYYY')} - ${moment()
                    .endOf('month')
                    .subtract(0, 'month')
                    .format('DD/MM/YYYY')}`;
                break;

            case '5':
                dateStart = moment().startOf('month').subtract(5, 'month');
                dateEnd = moment().endOf('month').subtract(0, 'month');
                dateString = `${moment()
                    .startOf('month')
                    .subtract(5, 'month')
                    .format('DD/MM/YYYY')} - ${moment()
                    .endOf('month')
                    .subtract(0, 'month')
                    .format('DD/MM/YYYY')}`;
                break;

            case '6':
                dateStart = moment().startOf('month').subtract(11, 'month');
                dateEnd = moment().endOf('month').subtract(0, 'month');
                dateString = `${moment()
                    .startOf('month')
                    .subtract(11, 'month')
                    .format('DD/MM/YYYY')} - ${moment()
                    .endOf('month')
                    .subtract(0, 'month')
                    .format('DD/MM/YYYY')}`;
                break;

            default:
                break;
        }

        setDateSelected({
            dateStart,
            dateEnd,
            dateString,
        });
        arregloDias = [dateStart, dateEnd];
        filterDate(arregloDias, typeDoc);
        setOptionSelected(type);
    };

    return (
        <Dropdown overlay={menu} arrow={true} trigger={['click']}>
            <Button>
                {dateSelected.dateString} <DownOutlined />
            </Button>
        </Dropdown>
    );
}
