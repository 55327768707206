import React, { useState, useEffect } from 'react';
import {
    Table,
    Row,
    Col,
    Button,
    Tag,
    Input,
    Space,
    Modal as ModalAntd,
    Tooltip,
} from 'antd';
import {
    FileExcelFilled,
    FilePdfFilled,
    SearchOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    PlusCircleOutlined,
    FileOutlined,
    FileAddOutlined,
    FileMarkdownOutlined,
    PaperClipOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { formatMoney } from '../../../../utils/general';
import Highlighter from 'react-highlight-words';
import FileForm from '../FileForm/FileForm';
import Modal from '../../../General/Modal';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import BalanceForm from '../BalanceForm';
import {
    ROL_FINANCIAL,
    ROL_LAYOUT,
    ROL_MANAGER,
    ROL_SUPPLIER,
} from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import useStatusFilter from '../../../../hooks/useStatusFilter';
import AddDocumentsForm from '../Documents/AddDocumentsForm';
import './BillingTable.scss';

export default function BillingTable({ bills, reloadBills, dateRange }) {
    const [billsData, setBillsData] = useState(bills);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [excelLoading, setExcelLoading] = useState(false);
    const { person } = useUser();
    let history = useHistory();
    const [showAllColumns, setShowAllColumns] = useState(false);
    const [billsExport, setbillsExport] = useState(bills);
    const { statusFilter, reset } = useStatusFilter();
    const [fisrtTime, setFisrtTime] = useState(true);

    useEffect(() => {
        setbillsExport(bills);
    }, [bills]);
    useEffect(() => {
        if (statusFilter.type === '') {
            setFilteredInfo({});
        }
    }, [dateRange]);

    useEffect(() => {
        //filtar dependiendo el estatus de los amount
        const statusReceive = statusFilter.type;
        if (statusReceive != '') {
            // console.log(statusFilter);
            // const billsFilters = bills.filter(
            //     (bill) => bill.status === statusReceive
            // );
            let billsFilters;
            if (statusReceive === 'Total') {
                billsFilters = bills.filter(
                    (bill) =>
                        bill.invoiceStatus === 'Vigente' ||
                        bill.invoiceStatus === 'Vencido'
                );
            } else {
                billsFilters = bills.filter(
                    (bill) => bill.invoiceStatus === statusReceive
                );
            }

            const filterEntrance = {
                documentType: null,
                invoiceSerieType: null,
                invoiceNum: null,
                currencyId: null,
                hasOpenNote: null,
                status:
                    statusReceive === 'Total'
                        ? ['Vigente', 'Vencido']
                        : [statusReceive],
            };
            const pagination = {
                current: 1,
                pageSize: 10,
            };

            const extra = {
                currentDataSource: billsFilters,
                action: 'filter',
            };

            handleChange(pagination, filterEntrance, {}, extra);
            setTimeout(() => {
                reset();
            }, 3000);
            setFisrtTime(false);
        }
    }, []);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => {handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex);}}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Fecha recepción',
            dataIndex: 'issueDate',
            key: 'issueDate',
            align: 'center',
            width: 120,
            sorter: (a, b) => a.issueDate - b.issueDate,
            render: (issueDate) => (
                <>{moment.utc(issueDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Fecha documento',
            dataIndex: 'documentDate',
            key: 'documentDate',
            align: 'center',
            width: 120,
            sorter: (a, b) => a.documentDate - b.documentDate,
            render: (documentDate) => (
                <>{moment.utc(documentDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Tipo de documento',
            dataIndex: 'documentType',
            key: 'documentType',
            width: 100,
            align: 'center',
            filters: [
                { text: 'Ingreso', value: 'I' },
                { text: 'Egreso', value: 'E' },
            ],
            filteredValue: filteredInfo.documentType || null,
            onFilter: (value, record) => record.documentType.includes(value),

            render: (documentType) => (
                <p>{documentType === 'I' ? 'Ingreso' : 'Egreso'}</p>
            ),
            ellipsis: false,
        },
        {
            title: 'Serie',
            dataIndex: 'invoiceSerieType',
            key: 'invoiceSerieType',
            align: 'center',
            ...getColumnSearchProps('invoiceSerieType'),
            render: (invoiceSerieType) => <p>{invoiceSerieType}</p>,
        },
        {
            title: 'Folio',
            dataIndex: 'invoiceNum',
            align: 'center',
            key: 'invoiceNum',
            ...getColumnSearchProps('invoiceNum'),
            render: (folio, invoiceSerie) => (
                <Tag
                    style={{ cursor: 'pointer' }}
                    onClick={() => showModal(`Folio`, invoiceSerie)}
                >
                    {folio}
                </Tag>
            ),
        },
        {
            title: 'Orden de compra',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            align: 'center',
            width: 100,

            ...getColumnSearchProps('purchaseOrder'),
            render: (purchaseOrder, bill) => (
                <p>{purchaseOrder ? purchaseOrder : '-'}</p>
            ),
        },
        {
            title: 'Importe',
            dataIndex: 'total',
            align: 'center',
            key: 'total',
            render: (total) => <p>${formatMoney(total)}</p>,
        },
        {
            title: 'Moneda',
            dataIndex: 'currencyId',
            key: 'currencyId',
            align: 'center',
            filters: [
                { text: 'MXN', value: 'MXN' },
                { text: 'USD', value: 'USD' },
            ],
            filteredValue: filteredInfo.currencyId || null,
            onFilter: (value, record) => record.currencyId.includes(value),
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                { text: 'Pagado', value: 'Pagado' },
                { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            render: (status) => (
                <Tag
                    color={
                        status === 'Vigente'
                            ? 'orange'
                            : status === 'Pagado'
                            ? 'green'
                            : 'red'
                    }
                >
                    {status.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Programación de pago',
            dataIndex: 'programDate',
            key: 'programDate',
            align: 'center',
            width: 100,
            sorter: (a, b) => a.programDate - b.programDate,
            render: (programDate) => (
                <>{moment.utc(programDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Archivos de facturas',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 120,
            render: (id, invoice) => (
                <>
                    <Tooltip title="Xml">
                        <Tag color={'green'}>
                            <FileMarkdownOutlined
                                onClick={() => showModal('xml', id)}
                            />
                        </Tag>
                    </Tooltip>

                    <Tooltip title="PDF">
                        <Tag color={'red'}>
                            <FilePdfOutlined
                                onClick={() => showModal('pdf', id)}
                            />
                        </Tag>
                    </Tooltip>
                    {invoice.hasAcuse !== false && (
                        <Tooltip title="cfdi">
                            <Tag color={'orange'}>
                                <FileOutlined
                                    onClick={() => showModal('cfdi', id)}
                                />
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: 'Evidencia',
            dataIndex: 'description',
            key: '3',
            width: 120,
            render: (statusText, record) => (
                <>
                    <Tooltip title={'Agregar evidencia'}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<FileAddOutlined />}
                            onClick={() => {
                                addDocumentModal(record.id);
                            }}
                        ></Button>
                    </Tooltip>

                    <Tooltip title={'Ver evidencias'}>
                        <Button
                            type="primary"
                            ghost
                            size="small"
                            icon={<PaperClipOutlined />}
                            onClick={() => {
                                history.push(
                                    `/facturacion/detalles/documentos/${record.id}`
                                );
                            }}
                            style={{ marginLeft: 15 }}
                        ></Button>
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Aclaraciones',
            key: 'hasOpenNote',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 100,
            filters: [
                { text: 'Nuevo', value: 'new' },
                { text: 'Abierto', value: 'open' },
                { text: 'Cerrado', value: 'close' },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => {
                //cerrada : hasOpenNote === false && noteid !== null
                if (value === 'new') {
                    return (
                        (!record.hasOpenNote && record.noteId === 0) ||
                        (!record.hasOpenNote && record.noteId !== 0)
                    );
                }
                if (value === 'open') {
                    return record.hasOpenNote && record.noteId != null;
                }
                if (value === 'close') {
                    return !record.hasOpenNote && record.noteId != null;
                }
            },
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.noteId !== null && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.noteId}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                            >
                                {invoice.noteId}
                            </Tag>
                        </Tooltip>
                    )}
                    {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER].some((r) =>
                        person.roles.includes(r)
                    ) &&
                        !hasOpenNote && (
                            <Tooltip placement="topLeft" title={'Nueva'}>
                                <Tag
                                    onClick={() =>
                                        history.push(
                                            `/aclaraciones/nueva/${invoice.id}`
                                        )
                                    }
                                    color={'green'}
                                >
                                    <PlusCircleOutlined />
                                </Tag>
                            </Tooltip>
                        )}
                </>
            ),
        },
    ];
    const columnsAll = [
        // COMO EJECUTIVO
        {
            title: 'ID proveedor',
            dataIndex: 'supplierId',
            key: 'supplierId',
            ...getColumnSearchProps('supplierId'),
        },
        {
            title: 'Fecha recepción',
            dataIndex: 'issueDate',
            key: 'issueDate',
            align: 'center',
            width: 120,
            sorter: (a, b) => a.issueDate - b.issueDate,
            render: (issueDate) => (
                <>{moment.utc(issueDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Fecha documento',
            dataIndex: 'documentDate',
            key: 'documentDate',
            align: 'center',
            width: 120,
            sorter: (a, b) => a.documentDate - b.documentDate,
            render: (documentDate) => (
                <>{moment.utc(documentDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Tipo de documento',
            dataIndex: 'documentType',
            key: 'documentType',
            align: 'center',
            width: 100,
            filters: [
                { text: 'Ingreso', value: 'I' },
                { text: 'Egreso', value: 'E' },
            ],
            filteredValue: filteredInfo.documentType || null,
            onFilter: (value, record) => record.documentType.includes(value),

            render: (documentType) => (
                <p>{documentType === 'I' ? 'Ingreso' : 'Egreso'}</p>
            ),
            ellipsis: false,
        },
        {
            title: 'Serie',
            dataIndex: 'invoiceSerieType',
            key: 'invoiceSerieType',
            align: 'center',
            ...getColumnSearchProps('invoiceSerieType'),
        },
        {
            title: 'Folio',
            dataIndex: 'invoiceNum',
            align: 'center',
            key: 'invoiceNum',
            ...getColumnSearchProps('invoiceNum'),
        },
        {
            title: 'Orden de compra',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            align: 'center',
            ...getColumnSearchProps('purchaseOrder'),
            // render: (purchaseOrder, bill) => (
            //     <p>{purchaseOrder ? purchaseOrder : '-'}</p>
            // ),
        },
        {
            title: 'Importe',
            dataIndex: 'total',
            align: 'center',
            key: 'total',
            render: (total) => <p>${formatMoney(total)}</p>,
        },
        {
            title: 'Moneda',
            dataIndex: 'currencyId',
            key: 'currencyId',
            align: 'center',
            filters: [
                { text: 'MXN', value: 'MXN' },
                { text: 'USD', value: 'USD' },
            ],
            filteredValue: filteredInfo.currencyId || null,
            onFilter: (value, record) => record.currencyId.includes(value),
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                { text: 'Pagado', value: 'Pagado' },
                { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            render: (status) => (
                <Tag
                    color={
                        status === 'Vigente'
                            ? 'orange'
                            : status === 'Pagado'
                            ? 'green'
                            : 'red'
                    }
                >
                    {status.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Programación de pago',
            dataIndex: 'programDate',
            key: 'programDate',
            align: 'center',
            width: 100,
            sorter: (a, b) => a.programDate - b.programDate,
            render: (programDate) => (
                <>{moment.utc(programDate).local().format('DD/MM/YYYY')}</>
            ),
        },
        {
            title: 'Archivos de facturas',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (id, invoice) => (
                <>
                    <Tooltip title="Xml">
                        <Tag color={'green'}>
                            <FileMarkdownOutlined
                                onClick={() => showModal('xml', id)}
                            />
                        </Tag>
                    </Tooltip>

                    <Tooltip title="PDF">
                        <Tag color={'red'}>
                            <FilePdfOutlined
                                onClick={() => showModal('pdf', id)}
                            />
                        </Tag>
                    </Tooltip>
                    {invoice.hasAcuse !== false && (
                        <Tooltip title="cfdi">
                            <Tag color={'orange'}>
                                <FileOutlined
                                    onClick={() => showModal('cfdi', id)}
                                />
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            title: 'Evidencia',
            dataIndex: 'description',
            key: '3',
            width: 120,
            render: (statusText, record) => (
                <>
                    <Tooltip title={'Agregar evidencia'}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<FileAddOutlined />}
                            onClick={() => {
                                addDocumentModal(record.id);
                            }}
                        ></Button>
                    </Tooltip>

                    <Tooltip title={'Ver evidencias'}>
                        <Button
                            type="primary"
                            ghost
                            size="small"
                            icon={<PaperClipOutlined />}
                            onClick={() => {
                                history.push(
                                    `/facturacion/detalles/documentos/${record.id}`
                                );
                            }}
                            style={{ marginLeft: 15 }}
                        ></Button>
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Aclaraciones',
            key: 'noteId',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 100,
            filters: [
                // { text: 'Nuevo', value: null },
                { text: 'Abierto', value: true },
                { text: 'Cerrado', value: false },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => record.hasOpenNote.includes(value),
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.noteId !== null && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.noteId}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                            >
                                {invoice.noteId}
                            </Tag>
                        </Tooltip>
                    )}
                    {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER].some((r) =>
                        person.roles.includes(r)
                    ) &&
                        !hasOpenNote && (
                            <Tooltip placement="topLeft" title={'Nueva'}>
                                <Tag
                                    onClick={() =>
                                        history.push(
                                            `/aclaraciones/nueva/${invoice.id}`
                                        )
                                    }
                                    color={'green'}
                                >
                                    <PlusCircleOutlined />
                                </Tag>
                            </Tooltip>
                        )}
                </>
            ),
        },
    ];

    useEffect(() => {
        // console.log(ROL_MANAGER, ROL_FINANCIAL, ROL_SUPPLIER);
        [ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r)) &&
            setShowAllColumns(true);
        // setColumns([newColumn,...columns])
    }, [person.roles]);

    useEffect(() => {
        bills.forEach((bill) => {
            bill['invoiceSerieType'] = bill.serie;
        });

        setBillsData(bills);
    }, [bills]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        // console.log(pagination, filters, sorter, extra);
        setFilteredInfo(filters);
        setbillsExport(extra.currentDataSource);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };

    const showModal = (type, reference) => {
        setModalIsVisible(true);

        if (type === 'xml') {
            setTitleModal('Archivo XML');
            setContextModal(
                <FileForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></FileForm>
            );
        }
        if (type === 'pdf') {
            setTitleModal('Archivo PDF');
            setContextModal(
                <FileForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></FileForm>
            );
        }
        if (type === 'cfdi') {
            setTitleModal('Archivo Acuse de recibo');
            setContextModal(
                <FileForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></FileForm>
            );
        }
        if (type === 'Folio') {
            setTitleModal('Folio');
            setContextModal(
                <BalanceForm
                    setModalIsVisible={setModalIsVisible}
                    type={type}
                    reference={reference}
                ></BalanceForm>
            );
        }
    };

    const generateExcel = (entries) => {
        setExcelLoading(true);
        const filename = `Facturación-y-notasdecredito${moment().format(
            'L'
        )}.xlsx`;

        let fecha = moment().format('LLL');
        const ws_name = 'Hoja1';
        let wb = {
            SheetNames: [],
            Sheets: {},
        };
        let ws = {};
        let renInicial = 4;

        var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

        if (
            [ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r))
        ) {
            ws = {
                A1: { v: mstrTitulo, t: 's' },
                I1: { v: fecha, t: 's' },

                A3: { v: 'ID proveedor', t: 's' },
                B3: { v: 'Fecha de recepción', t: 's' },
                C3: { v: 'Fecha documento', t: 's' },
                D3: { v: 'Tipo documento', t: 's' },
                E3: { v: 'Serie', t: 's' },
                F3: { v: 'Folio', t: 's' },
                G3: { v: 'Orden de compra', t: 's' },
                H3: { v: 'Importe', t: 's' },
                I3: { v: 'Moneda', t: 's' },
                J3: { v: 'Estatus', t: 's' },
                K3: { v: 'Programación de pago', t: 's' },
            };
            for (let i in entries) {
                const {
                    supplierId,
                    issueDate,
                    documentDate,
                    documentType,
                    serie,
                    invoiceNum,
                    purchaseOrder,
                    total,
                    currencyId,
                    status,
                    programDate,
                } = entries[i];
                ws['A' + renInicial] = { v: supplierId, t: 's' };

                ws['B' + renInicial] = {
                    v: moment.utc(issueDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['C' + renInicial] = {
                    v: moment.utc(documentDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['D' + renInicial] = {
                    v: documentType === 'I' ? 'Ingreso' : 'Egreso',
                    t: 's',
                };
                ws['E' + renInicial] = { v: serie, t: 's' };
                ws['F' + renInicial] = { v: invoiceNum, t: 's' };
                ws['G' + renInicial] = {
                    v: purchaseOrder ? purchaseOrder : '-',
                    t: 's',
                };
                ws['H' + renInicial] = {
                    v: `$${formatMoney(total)}`,
                    t: 's',
                };
                ws['I' + renInicial] = { v: currencyId, t: 's' };
                ws['J' + renInicial] = { v: status, t: 's' };
                ws['K' + renInicial] = {
                    v: moment.utc(programDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                renInicial++;
            }
        } else {
            ws = {
                A1: { v: mstrTitulo, t: 's' },
                I1: { v: fecha, t: 's' },

                A3: { v: 'Fecha de recepción', t: 's' },
                B3: { v: 'Fecha documento', t: 's' },
                C3: { v: 'Tipo documento', t: 's' },
                D3: { v: 'Serie', t: 's' },
                E3: { v: 'Folio', t: 's' },
                F3: { v: 'Orden de compra', t: 's' },
                G3: { v: 'Importe', t: 's' },
                H3: { v: 'Moneda', t: 's' },
                I3: { v: 'Estatus', t: 's' },
                J3: { v: 'Programación de pago', t: 's' },
            };
            for (let i in entries) {
                const {
                    issueDate,
                    documentDate,
                    documentType,
                    serie,
                    invoiceNum,
                    purchaseOrder,
                    total,
                    currencyId,
                    status,
                    programDate,
                } = entries[i];
                ws['A' + renInicial] = {
                    v: moment.utc(issueDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['B' + renInicial] = {
                    v: moment.utc(documentDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                ws['C' + renInicial] = {
                    v: documentType === 'I' ? 'Ingreso' : 'Egreso',
                    t: 's',
                };
                ws['D' + renInicial] = { v: serie, t: 's' };
                ws['E' + renInicial] = { v: invoiceNum, t: 's' };
                ws['F' + renInicial] = {
                    v: purchaseOrder ? purchaseOrder : '-',
                    t: 's',
                };
                ws['G' + renInicial] = {
                    v: `$ ${formatMoney(total)}`,
                    t: 's',
                };
                ws['H' + renInicial] = { v: currencyId, t: 's' };
                ws['I' + renInicial] = { v: status, t: 's' };
                ws['J' + renInicial] = {
                    v: moment.utc(programDate).local().format('DD/MM/YYYY'),
                    t: 's',
                };
                renInicial++;
            }
        }

        // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
        // ws['!cols'] = wscols;
        ws['!ref'] = XLSX.utils.encode_range({
            s: { c: 0, r: 0 },
            e: { c: 15, r: renInicial },
        });
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;
        XLSX.writeFile(wb, filename, { cellStyles: true });
        setExcelLoading(false);
    };

    const exportPdf = (bills, startDate, endDate) => {
        var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;

        // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
        //     .unix(startDate)
        //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;

        var mstrPdf = `Facturación_y_notasdecredito${moment().format('L')}.pdf`;

        var registros = [];
        if (
            [ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r))
        ) {
            bills.forEach((element) => {
                registros.push([
                    element.supplierId,
                    moment.utc(element.issueDate).local().format('DD/MM/YYYY'),
                    moment
                        .utc(element.documentDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    element.documentType === 'I' ? 'Ingreso' : 'Egreso',
                    element.serie,
                    element.purchaseOrder ? element.purchaseOrder : '-',
                    element.invoiceNum,
                    '$ ' + formatMoney(element.total),
                    element.currencyId,
                    element.status,
                    moment
                        .utc(element.programDate)
                        .local()
                        .format('DD/MM/YYYY'),
                ]);
            });
        } else {
            bills.forEach((element) => {
                registros.push([
                    moment.utc(element.issueDate).local().format('DD/MM/YYYY'),
                    moment
                        .utc(element.documentDate)
                        .local()
                        .format('DD/MM/YYYY'),
                    element.documentType === 'I' ? 'Ingreso' : 'Egreso',
                    element.serie,
                    element.invoiceNum,
                    element.purchaseOrder ? element.purchaseOrder : '-',
                    '$ ' + formatMoney(element.total),
                    element.currencyId,
                    element.status,
                    moment
                        .utc(element.programDate)
                        .local()
                        .format('DD/MM/YYYY'),
                ]);
            });
        }

        let fecha = moment().format('LLL');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 220;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(-10, -10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        if (
            [ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r))
        ) {
            doc.autoTable({
                head: [
                    [
                        'ID proveedor',
                        'Fecha recepción',
                        'Fecha documento',
                        'Tipo de documento',
                        'serie',
                        'Folio',
                        'Orden de compra',
                        'Importe',
                        'Moneda',
                        'Estatus',
                        'Programación de pago',
                    ],
                ],
                didDrawPage: pageContent,
                theme: 'grid',
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    fillColor: [49, 101, 120],
                    textColor: [255, 255, 255],
                },
                body: registros,
                columnStyles: {
                    text: {
                        cellWidth: 'wrap',
                    },
                },
            });
        } else {
            doc.autoTable({
                head: [
                    [
                        'Fecha recepción',
                        'Fecha documento',
                        'Tipo de documento',
                        'serie',
                        'Folio',
                        'Orden de compra',
                        'Importe',
                        'Moneda',
                        'Estatus',
                        'Programación de pago',
                    ],
                ],
                didDrawPage: pageContent,
                theme: 'grid',
                headStyles: {
                    valign: 'middle',
                    halign: 'center',
                    fillColor: [49, 101, 120],
                    textColor: [255, 255, 255],
                },
                body: registros,
                columnStyles: {
                    text: {
                        cellWidth: 'wrap',
                    },
                },
            });
        }

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };

    const addDocumentModal = (id) => {
        setTitleModal(`Agregar documentos - ${id}`);
        setModalIsVisible(true);
        setContextModal(
            <AddDocumentsForm
                setModalIsVisible={setModalIsVisible}
                operationId={id}
            ></AddDocumentsForm>
        );
    };
    return (
        <>
            {/* <Button danger onClick={clearAll} style={{ marginRight: 10 }}>
                Limpiar filtros
            </Button> */}
            <Table
                // bordered
                // rowSelection={rowSelection}
                onChange={handleChange}
                columns={showAllColumns ? columnsAll : columns}
                dataSource={billsData}
                scroll={{ x: 1200 }}
                // defaultPageSize={10}
                loading={reloadBills}
                // pagination={{
                //     pageSize: 10,
                // }}
            />

            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        icon={<FilePdfFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => exportPdf(billsExport)}
                        disabled={billsExport.length === 0}
                    >
                        Descargar Pdf
                    </Button>
                    <Button
                        icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => generateExcel(billsExport)}
                        disabled={billsExport.length === 0}
                    >
                        Descargar Excel
                    </Button>
                </Col>
            </Row>
            <Modal
                title={titleModal}
                visible={modalIsVisible}
                setIsVisible={setModalIsVisible}
                width={1000}
            >
                {contextModal}
            </Modal>
        </>
    );
}
