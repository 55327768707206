import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../components/Admin/GreetingsTop/GreetingsTop';
import DatePickerOptions from '../../../components/General/DatePickerOptions/DatePickerOptions';
import PricingTable from '../../../components/Logistic/PricingTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import RfqTable from '../../../components/Logistic/RfqTable/RfqTable';
import moment from 'moment';

export default function Pricing() {
    let { pricingType } = useParams();
    const date = [moment().subtract(7, 'days'), moment()];
    const [dateRange, setDateRange] = useState({
        startDate: moment(date[0]).unix(),
                endDate: moment(date[1]).unix()
    });
    function filterDate(date, dateString) {
        if (date) {
            let date2 = {
                startDate: moment(date[0]).unix(),
                endDate: moment(date[1]).unix()
            };
            setDateRange(date2);
           
        }
    }
    return (
        <Layout>
            <GreetingsTop />

            <HeaderTop pricingType={pricingType} filterDate={filterDate}/>
            {pricingType === 'tarifa' && (
                <div className="billing__container">
                    <PricingTable dateRange={dateRange}/>
                </div>
            )}
            {pricingType === 'rfq' && (
                <div className="billing__container">
                    <RfqTable/>
                </div>
            )}
        </Layout>
    );
}

function HeaderTop({ pricingType,filterDate }) {
    const { Title } = Typography;

    return (
        <div className="billing__container">
            <Row className="header-top">
                <Col xs={24} md={12} className="header-top__left">
                    {pricingType === 'rfq' && (
                        <Title level={3}>Tarifa RFQ</Title>
                    )}
                    {pricingType === 'tarifa' && (
                        <Title level={3}>Tarifa</Title>
                    )}
                </Col>
                <Col
                    xs={20}
                    md={12}
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p xs={24} style={{ marginRight: 20 }}>
                            Fecha:
                        </p>
                        <DatePickerOptions
                            xs={24}
                            filterDate={filterDate}
                        />
                    </Row>
                   
                </Col>
                {/* <Col
                    xs={20}
                    md={12}
                    style={{
                        display: 'grid',
                        alignItems: 'center',
                        justifyContent: 'end',
                    }}
                >
                    <Row xs={24} md={12} style={{ marginBottom: 10 }}>
                        <p xs={24} style={{ marginRight: 20 }}>
                            Fecha:
                        </p>
                        <DatePickerOptions
                            xs={24}
                            // filterDate={filterDate}
                            typeDoc={'Documento'}
                            // dateDisplay={dateReceive}
                        />
                    </Row>
                </Col> */}
            </Row>
        </div>
    );
}
