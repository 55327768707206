import React, { useState, createContext, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi, getEmailUserApi, logout } from '../api/auth';
import { getUserApi } from '../api/user';
// import { getCompanyApi } from '../api/company';
import { COMPANY_ID, FATHER_COMPANY_ID } from '../utils/constants';
import { getCompanyIdApi } from '../api/company';
import { messageErrorGeneral } from '../utils/feedbackMessages';

export const UserContext = createContext();

export default function UserProvider(props) {
    const { children } = props;
    const [person, setPerson] = useState({
        userType: 0,
        companyId: null,
        name: '',
        shortName: '',
        lastName: '',
        address: null,
        address2: null,
        city: null,
        state: null,
        claims: [],
        neighborhood: null,
        phoneNumber: '',
        registrationNumber: null,
        vehicle: null,
        zipCode: null,
        loading: true,
        profilePicture: '',
        idUser: '',
    });

    useEffect(() => {
        checkPerson(setPerson);
    }, []);

    return (
        <UserContext.Provider value={{ person, setPerson }}>
            {children}
        </UserContext.Provider>
    );
}

function checkPerson(setPerson) {
    const accessToken = getAccessTokenApi();
    if (accessToken) {
        // const email = getEmailUserApi(accessToken);
        // const companyId = getCompanyIdApi();

        getUserApi(accessToken).then((response) => {
            if (response?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return;
            }
            // if (
            //     response?.result.roles.length === 1 &&
            //     response?.result.roles[0] === 'Customer'
            // ) {
            //     setTimeout(() => {
            //         logout();
            //         window.location.reload();
            //     }, 1500);
            //     return;
            // }
            if (response !== undefined || response) {
                const data = response;

                // console.log(data);
                if (data.statusCode !== 200) {
                    notification['error']({
                        message: messageErrorGeneral(),
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }

                const firstName = data.result.name.split(' ');
                let completeName = '';

                if (firstName[1] !== undefined) {
                    completeName = firstName[0];
                    // completeName = firstName[0]+" "+firstName[1];
                } else {
                    completeName = firstName[0];
                }

                let personInfo = {
                    // ...person,
                    userType: data.result.userType,
                    companyId: data.result.company,
                    name: data.result.name,
                    lastName: data.result.lastName,
                    shortName: completeName,
                    address: data.result.address,
                    phoneNumber: data.result.phoneNumber,
                    city: data.result.city,
                    neighborhood: data.result.neighborhood,
                    zipCode: data.result.zipCode,
                    state: data.result.state,
                    loading: false,
                    emailConfirmed: data.result.emailConfirmed,
                    email: data.result.email,
                    active: data.result.active,
                    roles: data.result.roles,
                    userName: data.result.userName,
                    passwordChanged: data.result.passwordChanged,
                    fiscalID: data.result.fiscalID,
                    profilePicture: data.result.profilePicture,
                    idUser: data.result.id,
                };

                //OBTENER IMAGEN DEL RESTAURANTE
                // getCompanyApi(accessToken, data.companyId).then((res) => {
                //     if (res) {
                //         if (res.statusCode) {
                //             if (res.statusCode === 401) {
                //                 notification['error']({
                //                     message: 'Usuario no autorizado.',
                //                 });

                //                 // setTimeout(() => {
                //                 //     logout();
                //                 //     window.location.reload();
                //                 // }, 1500);
                //                 return;
                //             }
                //         } else {
                //             if (res !== undefined) {
                //                 if (!res.error) {
                //                     if (res.companyImage) {
                //                         const extensionImage =
                //                             res.companyImage?.extension;
                //                         const imageProfile = `data:image/${extensionImage};base64, ${res.companyImage?.imageData}`;
                //                         personInfo['avatar'] = imageProfile;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // });
                setPerson(personInfo);
            } else {
                setPerson({
                    userType: 0,
                    companyId: null,
                    name: '',
                    avatar: '',
                    loading: false,
                });
            }
        });
    } else {
        setPerson({
            userType: 0,
            companyId: null,
            name: '',
            avatar: '',
            loading: false,
        });
    }
}
