/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Select,
  Button,
  Col,
  Modal as ModalAntd,
  Row,
  Table,
  Tag,
  Tooltip,
  Spin,
  Input,
} from 'antd';
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import AddEditPricingForm from '../AddEditPricingForm/AddEditPricingForm';
import Modal from '../../General/Modal';
import ClientForm from '../ ClientForm/ClientForm';
import { formatMoney } from '../../../utils/general';
import { METHOD_DELETE, METHOD_GET } from '../../../utils/constants';
import { API_URL_RATE } from '../../../helpers/urls';
import { getAccessTokenApi } from '../../../api/auth';
import { fetchApi } from '../../../helpers/fetch';
import {
  TYPE_OF_SERVICE_CATALOG_ID,
  SERVICES_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  HAZMAT_VALUE_ID,
} from '../../../helpers/constants';
import { getCatalogValueHandle } from '../../../helpers/handlerFunctionApi';

import './PricingTable.scss';

const PricingTable = ({
  pickRate,
  setRateSelected,
  filterRate,
  supplierSelected,
  setSupplierSelected,
  setModalIsVisibleRate,
  dateRange,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [reloadTable, setReloadTable] = useState(false);
  const [rates, setRates] = useState([]);
  const [filterServiceType, setFilterServiceType] = useState([]);
  const [filterService, setFilterService] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [service, setService] = useState([]);
  // const hasSelected = selectedRowKeys.length > 0;
  const textModal = '¿Estás seguro que deseas borrar esta tarifa';
  const { confirm } = ModalAntd;
  const token = getAccessTokenApi();
  useEffect(() => {
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setServiceType,
      setIsLoading,
      0
    );
    getCatalogValueHandle(SERVICES_CATALOG_ID, setService, setIsLoading, 0);
  }, []);

  useEffect(() => {
    let filters = [];
    serviceType.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterServiceType(filters);
  }, [serviceType]);

  useEffect(() => {
    let filters = [];
    service.forEach((element) => {
      const json = { text: element.label, value: element.label };
      filters.push(json);
    });
    setFilterService(filters);
  }, [service]);

  useEffect(() => {
    setIsLoadingTable(true);
    let filters = '';
    let filter2 = `startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`;

    if (pickRate) {
      const { unit, typeOfLoad, service, typeOfService, hazmat } = filterRate;
      filters = `?Unit=${unit !== undefined ? unit : ''}&LoadType=${
        typeOfLoad !== undefined ? typeOfLoad : ''
      }&Service=${service !== undefined ? service : ''}&TypeOfService=${
        typeOfService !== undefined ? typeOfService : ''
      }&HazMat=${hazmat !== undefined ? hazmat : ''}&SupplierId=${
        supplierSelected?.supplierId !== undefined
          ? supplierSelected?.supplierId
          : ''
      }`;
    } else {
      filters = `?${filter2}`;
    }

    const config = {
      method: METHOD_GET,
      url: `${API_URL_RATE}${filters}`,
      data: null,
      token,
      showNotificationError: true,
    };
    fetchApi(config)
      .then((response) => {
        if (response.statusCode === 200) {
          const allRates = response.result;
          allRates.map((rate) => (rate['key'] = rate.id));
          if (pickRate) {
            const completeRate = allRates.filter((rate) => rate.isCompleted);
            setRates(completeRate);
          } else {
            setRates(allRates);
          }
        }
        setIsLoadingTable(false);
        setReloadTable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, dateRange]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    if (pickRate) {
      setRateSelected(selectedRows[0]);
      // if (!supplierSelected) {
      //    console.log(selectedRows[0].supplier);
      //    setSupplierSelected(selectedRows[0].supplier);
      // }
      setSupplierSelected(selectedRows[0].supplier);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    type: pickRate ? 'radio' : 'checkbox',
    onChange: onSelectChange,
  };

  const showModal = (type, rate) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar tarifa');
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          setReloadTable={setReloadTable}
        ></AddEditPricingForm>
      );
    } else if (type === 'Edit') {
      setModalIsVisible(true);
      setTitleModal(`Editar tarifa #${rate.rateId}`);
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          type='edit'
          rateId={rate.id}
          setReloadTable={setReloadTable}
        ></AddEditPricingForm>
      );
    } else if (type === 'Watch') {
      setModalIsVisible(true);
      setTitleModal(`Tarifa #${rate.rateId}`);
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          type='watch'
          rateId={rate.id}
        ></AddEditPricingForm>
      );
    } else if (type === 'Other') {
      setModalIsVisible(true);
      setTitleModal('Clientes - VENTAS');
      setContextModal(
        <ClientForm
          setModalIsVisible={setModalIsVisible}
          // setReloadUsers={setReloadUsers}
          // idUser={userSelected}
        ></ClientForm>
      );
    }
  };

  const columns = [
    {
      title: 'Id tarifa',
      dataIndex: 'rateId',
      key: 'rateId',
      width: 100,
      // fixed: 'left',
    },
    {
      title: 'Servicio',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      //   fixed: 'left',
      filters: filterService,
      onFilter: (value, record) => record.service === value,
    },
    {
      title: 'Tipo de servicio',
      dataIndex: 'typeOfService',
      key: 'typeOfService',
      width: 100,
      // fixed: 'left',
      align: 'center',
      filters: filterServiceType,
      onFilter: (value, record) => record.typeOfService === value,
    },
    {
      title: 'Origen',
      children: [
        {
          title: 'País',
          dataIndex: 'originCountry',
          key: 'originCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'originCity',
          key: 'originCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'originZipCode',
          key: 'originZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'originPort',
          key: 'originPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'originAirport',
          key: 'originAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'originAddress',
          key: 'originAddress',
          width: 100,
          align: 'center',
        },
      ],
    },
    {
      title: 'Destino',
      children: [
        {
          title: 'País',
          dataIndex: 'destinyCountry',
          key: 'destinyCountry',
          width: 100,
          align: 'center',
        },
        {
          title: 'Ciudad',
          dataIndex: 'destinyCity',
          key: 'destinyCity',
          width: 100,
          align: 'center',
        },
        {
          title: 'CP',
          dataIndex: 'destinyZipCode',
          key: 'destinyZipCode',
          width: 100,
          align: 'center',
        },
        {
          title: 'Puerto',
          dataIndex: 'destinyPort',
          key: 'destinyPort',
          width: 100,
          align: 'center',
        },
        {
          title: 'Aeropuerto',
          dataIndex: 'destinyAirport',
          key: 'destinyAirport',
          width: 100,
          align: 'center',
        },
        {
          title: 'Dirección',
          dataIndex: 'destinyAddress',
          key: 'destinyAddress',
          align: 'center',
          width: 100,
        },
      ],
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      width: 100,
      align: 'center',
      render: (cost) => <span>{formatMoney(cost)}</span>,
    },
    {
      title: 'Precio unitario',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (unitPrice) => <span>{`$ ${formatMoney(unitPrice)}`}</span>,
    },
    {
      title: 'Estatus',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      width: 100,
      // fixed: 'right',
      filters: [
        {
          text: 'Completada',
          value: true,
        },
        { text: 'Borrador', value: false },
      ],
      onFilter: (value, record) => record.isCompleted === value,
      align: 'center',
      render: (isCompleted) => (
        <Tag
          icon={isCompleted ? <CheckCircleOutlined /> : <MinusCircleOutlined />}
          color={isCompleted ? 'green' : 'default'}
        >
          {isCompleted ? 'Completa' : 'Borrador'}
        </Tag>
      ),
    },
    {
      title: 'Vigencia',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      // fixed: 'right',
      align: 'center',
      render: (endDate) => <span>{`${moment.unix(endDate).format('L')}`}</span>,
    },
    {
      title: 'Acciones',
      key: 'operation',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (rate) => (
        <>
          {!rate.isCompleted && !pickRate && (
            <Tooltip title='Editar'>
              <Button
                style={{ marginLeft: 2 }}
                type='primary'
                shape='default'
                icon={<EditOutlined />}
                onClick={() => showModal('Edit', rate)}
              />
            </Tooltip>
          )}
          {/* <Tooltip title="Eliminar">
                        <Button style={{marginLeft: 10}} type="primary" danger shape="default" icon={<DeleteOutlined /> } />
                    </Tooltip> */}
          <Tooltip title='Ver más información'>
            <Button
              style={{ marginLeft: 10 }}
              type='primary'
              ghost
              shape='default'
              icon={<EyeOutlined />}
              onClick={() => showModal('Watch', rate)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const deleteRate = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `${API_URL_RATE}/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
        setSelectedRowKeys([]);
      }
    }
  };

  const showDeleteConfirm = (id, description = '') => {
    confirm({
      title: `${textModal}?`,
      icon: <ExclamationCircleOutlined />,
      content: 'No podrá deshacer esta acción después..',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteRate(id);
      },
      onCancel() {},
    });
  };

  return (
    <div>
      {!pickRate && (
        <>
          <Button
            className='btn-download'
            onClick={() => showModal('Add')}
            icon={<PlusCircleOutlined />}
          >
            Agregar
          </Button>
          <Button
            disabled={selectedRowKeys.length !== 1}
            className='btn-delete'
            onClick={() => showDeleteConfirm(selectedRowKeys[0])}
            icon={<DeleteOutlined />}
          >
            Eliminar
          </Button>
          <Button
            className='btn-download'
            style={{ marginTop: 10 }}
            icon={<ReloadOutlined />}
            onClick={() => setReloadTable(true)}
          >
            Refrescar
          </Button>
          {selectedRowKeys.length > 0 && (
            <p>Seleccionados: {selectedRowKeys.length} elementos</p>
          )}
        </>
      )}

      {pickRate && (
        <FilterRate
          filterRate={filterRate}
          supplierSelected={supplierSelected}
        />
      )}
      <Table
        // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        dataSource={rates}
        columns={columns}
        bordered
        size='middle'
        scroll={{ x: 1200 }}
        setSelectedRowKeys
        className='table-striped-rows'
        rowSelection={rowSelection}
        loading={isLoadingTable || isLoading}
        footer={() => {
          return (
            <FooterTable
              pickRate={pickRate}
              setModalIsVisibleRate={setModalIsVisibleRate}
              // clearSupplier={clearSupplier}
            />
          );
        }}
      />

      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default PricingTable;

function FilterRate({ filterRate, supplierSelected }) {
  const [formFilter] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [loadingForm, setLoadingForm] = useState(false);
  const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
  const [hazMatCatalog, setHazMatsCatalog] = useState([]);
  const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
  // const [packagingCatalog, setPackagingCatalog] = useState([]);
  // const [measurementUnitCatalog, setMeasurementUnitCatalog] = useState([]);
  const [servicesCatalog, setServicesCatalog] = useState([]);
  const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);

  useEffect(() => {
    setLoadingForm(true);

    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setUnitTypeCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(HAZMAT_CATALOG_ID, setHazMatsCatalog, setLoadingForm);
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setTypeOfServiceCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      SERVICES_CATALOG_ID,
      setServicesCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoadCatalog,
      setLoadingForm
    );
    setLoadingForm(false);
  }, []);

  useEffect(() => {
    formFilter.setFieldsValue({
      unitsArray: filterRate.unit || filterRate.equipmentType,
      typeOfLoadArray: filterRate.typeOfLoad,
      servicesArray: filterRate.service,
      typeOfServiceArray: filterRate.typeOfService,
      hazMatArray:
        filterRate.typeOfLoad === HAZMAT_VALUE_ID ? filterRate.hazMat : '',
      supplierId: supplierSelected?.supplierId,
    });
  }, [filterRate]);

  return (
    <Spin spinning={loadingForm}>
      <Form {...layout} form={formFilter} name='control-hooks'>
        <Row>
          <Col md={12} xs={24}>
            <Form.Item label='Tipo de carga:' name='typeOfLoadArray'>
              <Select
                disabled
                placeholder='Selecciona una opción'
                allowClear
                showArrow
                // style={{
                //    minWidth: width > 480 ? 220 : '100%',
                //    width: width > 480 ? 220 : '100%',
                // }}
              >
                {typeOfLoadCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label='Servicios:' name='servicesArray'>
              <Select
                disabled
                placeholder='Selecciona una opción'
                allowClear
                showArrow
                // style={{
                //    minWidth: width > 480 ? 220 : '100%',
                //    width: width > 480 ? 220 : '100%',
                // }}
              >
                {servicesCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label='Tipo servicio:' name='typeOfServiceArray'>
              <Select
                allowClear
                placeholder='Selecciona una opción'
                disabled
                showArrow
                style={
                  {
                    // minWidth: width > 480 ? 220 : '100%',
                    // width: width > 480 ? 220 : '100%',
                  }
                }
              >
                {typeOfServiceCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label='Unidades:' name='unitsArray'>
              <Select
                placeholder='Selecciona una opción'
                disabled
                showArrow
                // style={{
                //    minWidth: width > 480 ? 220 : '100%',
                //    width: width > 480 ? 220 : '100%',
                // }}
              >
                {unitTypeCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label='HAZ MAT:' name='hazMatArray'>
              <Select
                allowClear
                placeholder='Selecciona una opción'
                disabled
                showArrow
                // style={{
                //    minWidth: width > 480 ? 220 : '100%',
                //    width: width > 480 ? 220 : '100%',
                // }}
              >
                {hazMatCatalog.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item label='Id proveedor:' name='supplierId'>
              <Input
                placeholder='Ingresa tu información'
                disabled
                // style={{
                //    minWidth: width > 480 ? 220 : '100%',
                //    width: width > 480 ? 220 : '100%',
                // }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

function FooterTable({ pickRate, setModalIsVisibleRate }) {
  return (
    <div className='footer-table'>
      {pickRate && (
        <>
          {/* <Button
                  icon={<ClearOutlined />}
                  title='Limpiar proveedor'
                  style={{ marginRight: 10 }}
                  onClick={() => clearSupplier()}
               >
                  Limpiar proveedor
               </Button> */}

          <Button
            type='primary'
            onClick={() => {
              setModalIsVisibleRate(false);
            }}
          >
            Aceptar
          </Button>
        </>
      )}
    </div>
  );
}
