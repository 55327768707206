import React, { useEffect, useState } from 'react';
import {
  Table,
  notification,
  Button,
  Col,
  Row,
  Modal as ModalAntd,
  Input,
  Space,
  Tag,
  Form,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAllSuppliers, deleteSupplierApi } from '../../../../api/supplier';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import AddSupplierForm from '../SuppliersTable/AddSupplierForm';
import EditSupplierForm from '../SuppliersTable/EditSupplierForm';
import Modal from '../../../General/Modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Checkbox from 'antd/lib/checkbox/Checkbox';

export default function SuppliersTable() {
  const [reloadSuppliers, setReloadSuppliers] = useState(true);
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [suppliersTable, setSuppliersTable] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const { confirm } = ModalAntd;

  useEffect(() => {
    if (reloadSuppliers) {
      const token = getAccessTokenApi();
      let filters = '';
      getAllSuppliers(token, filters).then((response) => {
        // console.log(response, 'suppliers');
        if (response === undefined) {
          notification['error']({
            message: 'Ocurrió un error, Inténtelo más tarde',
          });
          return false;
        }
        if (response.statusCode === 200) {
          response.result.forEach((element, index) => {
            element['key'] = index;
          });
          setSuppliersTable(response.result);
        } else {
          notification['error']({
            message: 'Usuario no autorizado',
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return;
        }
        setReloadSuppliers(false);
      });
    }
  }, [reloadSuppliers]);

  const checkUser = (id) => {
    if (supplierSelected === id) {
      setSupplierSelected(null);
    } else {
      setSupplierSelected(id);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size='small'
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Selecciona',
      width: 100,
      dataIndex: 'supplierId',
      key: 'company',
      render: (supplierId) => (
        <Checkbox
          // type="primary"                    {sectionContacts?.find((sc) => sc.sectionId === id)?.name}

          // disabled={
          //     !suppliersTable?.find(
          //         (st) => st.supplierId === supplierId
          //     )?.active
          // }
          onChange={() => checkUser(supplierId)}
          checked={supplierSelected === supplierId}
          className='button-detail'
        ></Checkbox>
      ),
      ellipsis: true,
    },
    {
      title: 'ID proveedor',
      width: 100,
      dataIndex: 'supplierId',
      key: 'supplierId',
      ...getColumnSearchProps('supplierId'),
      ellipsis: true,
    },
    {
      title: 'Proveedor',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ...getColumnSearchProps('name'),
      ellipsis: true,
    },
    {
      title: 'Estatus',
      dataIndex: 'active',
      key: 'active',
      filters: [
        { text: 'Activo', value: true },
        { text: 'Inactivo', value: false },
      ],
      filteredValue: filteredInfo.active || null,
      onFilter: (value, record) => record.active === value,
      render: (active) => (
        <Tag color={active === true ? 'green' : 'red'}>
          {active ? 'Activo'.toUpperCase() : 'inactivo'.toUpperCase()}
        </Tag>
      ),
      width: 150,
      ellipsis: true,
    },
    {
      title: 'RFC',
      dataIndex: 'fiscalID',
      key: 'fiscalID',
      width: 150,
      ...getColumnSearchProps('fiscalID'),
      ellipsis: true,
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      key: 'address',
      width: 150,
      ...getColumnSearchProps('address'),
      ellipsis: true,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
      ...getColumnSearchProps('phoneNumber'),
      ellipsis: true,
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
      width: 150,
      ...getColumnSearchProps('emailAddress'),
      ellipsis: true,
    },
    // {
    //     title: 'Ejecutivo CTA',
    //     dataIndex: 'email',
    //     key: 'email',
    //     width: 150,
    // },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
  };

  const showModal = (type) => {
    if (type === 'Add') {
      setModalIsVisible(true);
      setTitleModal('Agregar proveedor');
      setContextModal(
        <AddSupplierForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadSuppliers}
        ></AddSupplierForm>
      );
    } else if (type === 'Edit') {
      if (supplierSelected !== null) {
        setModalIsVisible(true);

        setTitleModal('Editar proveedor');
        setContextModal(
          <EditSupplierForm
            setModalIsVisible={setModalIsVisible}
            setReloadSuppliers={setReloadSuppliers}
            idSupplier={supplierSelected}
          ></EditSupplierForm>
        );
      } else if (supplierSelected === null) {
        notification['error']({
          message: 'Debes seleccionar un proveedor',
        });
      }
    }
  };
  const handleDeleteSupplier = (id) => {
    if (supplierSelected !== null) {
      confirm({
        title: 'Desactivar proveedor',
        icon: <ExclamationCircleOutlined />,
        content:
          '¿Estás seguro que deseas desactivar el proveedor seleccionado?, no se podrá reactivar después',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteSupplier(id);
        },
        onCancel() {},
      });
    } else {
      notification['error']({
        message: 'Debes seleccionar un proveedor a desactivar',
      });
    }
  };
  const deleteSupplier = async (id) => {
    const accessToken = getAccessTokenApi();
    const result = await deleteSupplierApi(accessToken, id);
    if (result?.statusCode === 409) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 405) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 404) {
      notification['error']({
        message: result.description,
      });
    }
    if (result?.statusCode === 200) {
      notification['success']({
        message: 'Proveedor desactivado exitosamente.',
      });
      setSupplierSelected(null);
    }
    setReloadSuppliers(true);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Button className='btn-download' onClick={() => showModal('Add')}>
            Agregar
          </Button>

          <Button className='btn-download' onClick={() => showModal('Edit')}>
            Editar
          </Button>
          <Button
            className='btn-download'
            onClick={() => handleDeleteSupplier(supplierSelected)}
          >
            Desactivar
          </Button>
        </Col>
      </Row>
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={suppliersTable}
        scroll={{ x: 1200 }}
        loading={reloadSuppliers}
        // pagination={{ defaultPageSize: 5 }}
      />
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
