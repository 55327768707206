import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Spin } from 'antd';
import FilesIcon from '../../../assets/img/png/archivos.png';
import { useHistory } from 'react-router';
import useUser from '../../../hooks/useUser';
import './Modules.scss';
import { toTitleCase } from '../../../utils/general';

export default function Modules(props) {
    const { catalogs, loading } = props;
    const { person } = useUser();

    return (
        <Spin spinning={loading} tip="Cargando...">
            <div className="modules">
                <Row gutter={[0, 15]}>
                    {catalogs.map((cat) => {
                        return (
                            <Col
                                key={cat.id}
                                lg={6}
                                md={8}
                                sm={12}
                                xs={24}
                                className="modules__container animate__animated  animate__bounceInLeft"
                            >
                                <ButtonAction type={cat.name} id={cat.id} inactive={cat.status === 1 ? false : true} key={cat.id} />
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </Spin>
    );
}

function ButtonAction(props) {
    const { type, id, inactive } = props;
    const { Title } = Typography;
    const history = useHistory();

    const handleRedirect = () => {
        history.push(`/modulo/${id}`);
        
    };

    return (
        <Button
            className={`modules__button ${inactive ? 'modules__button-inactive': ''}`}
            style={{ padding: 10 }}
            onClick={() => handleRedirect()}
            key={id}
        >
            <img width={60} src={FilesIcon} />
            <Title style={{ color: 'white', marginTop: 10 }} level={5}>
                {toTitleCase(type)} 
            </Title>
        </Button>
    );
}
