import { basePath } from './config';

//GET FACTURACION
export function getFinantialBillingApi(token, filters) {
    const url = `${basePath}/Finantial/GetBalance${filters}`;
    const params = {
        method: 'POST',
        // body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//GET FACTURACION ROL CUSTOMER
export function getFinantialBillingCustomerApi(token, idCustomer) {
    const url = `${basePath}/Finantial/GetBalance?pCustId=${idCustomer}`;
    const params = {
        method: 'POST',
        // body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//GET ESTADO DE CUENTA
export function getFinantialBalanceApi(token, filters) {
    const url = `${basePath}/Finantial/GetBalance?pOnlyUnpaidBalance=true${filters}`;
    const params = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//GET ESTADO DE CUENTA ROL CUSTOMER
export function getFinantialBalanceCustomerApi(token, idCustomer) {
    const url = `${basePath}/Finantial/GetBalance?pCustId=${idCustomer}&pOnlyUnpaidBalance=true`;
    const params = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
//GET INICIO CARDS CUSTOMER
export function getBalanceSummaryApi(token, filters) {
    const url = `${basePath}/Finantial/GetBalanceSummary${filters}`;
    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

//GET INICIO TABLA CUENTAS
export function getInvoiceNoticesApi(token) {
    const url = `${basePath}/Finantial/GetInvoiceNotices`;
    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
