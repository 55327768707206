import React from 'react';
import { Row, Col, Form, Button, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const BalanceForm = ({ setModalIsVisible, type }) => {
    const [form] = Form.useForm();

    return (
        <Form name="add-promo" layout="vertical" form={form}>
            <div>
                <Row>
                    {type === 'Saldo' && (
                        <Col
                            xs={24}
                            style={{ backgroundColor: '#F1F5F7', padding: 20 }}
                        >
                            <Row>
                                <Col xs={10}>
                                    <p>Fecha de pago</p>
                                </Col>
                                <Col xs={12}>
                                    <p>13/02/2019</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Hora</p>
                                </Col>
                                <Col xs={12}>
                                    <p>13:00</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Concepto</p>
                                </Col>
                                <Col xs={12}>
                                    <p>Test</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Monto</p>
                                </Col>
                                <Col xs={12}>
                                    <p>$2000</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Banco</p>
                                </Col>
                                <Col xs={12}>
                                    <p>BBVA Bancomer</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Cuenta origen</p>
                                </Col>
                                <Col xs={12}>
                                    <p>1234</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Cuenta destino</p>
                                </Col>
                                <Col xs={12}>
                                    <p>1234</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Folio/Referencia</p>
                                </Col>
                                <Col xs={12}>
                                    <p>FA491</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Importe total</p>
                                </Col>
                                <Col xs={12}>
                                    <p>$2000</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <p>Validación fiscal</p>
                                </Col>
                                <Col xs={12}>
                                    <p>Vigente</p>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    {type === 'Folio' && (
                        <Col>
                            <Select placeholder="Seleccionar formato de lectura a descargar">
                                <Select.Option key="1">Opcion1</Select.Option>
                                <Select.Option key={'2'}>Opcion2</Select.Option>
                            </Select>
                            <Button
                                icon={
                                    <DownloadOutlined
                                        style={{ fontSize: 18 }}
                                    />
                                }
                                className="btn-download"
                            >
                                Descargar
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>
        </Form>
    );
};

export default BalanceForm;
