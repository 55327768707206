import React, { useState, useEffect } from 'react';
import {
    Table,
    Row,
    Col,
    Button,
    Tag,
    Input,
    Space,
    Typography,
    Tooltip,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { formatMoney } from '../../../utils/general';
import {
    FileExcelFilled,
    FilePdfFilled,
    SearchOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useStatusFilter from '../../../hooks/useStatusFilter';
import { ROL_CUSTOMER, ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER } from '../../../utils/constants';
import useUser from '../../../hooks/useUser';

export default function AccountBalance({
    balance,
    reloadBalance,
    setTotal,
    dateRange,
    total,
    titleFilter,
}) {
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [balanceExport, setBalanceExport] = useState(balance);
    let history = useHistory();
    const { statusFilter, reset } = useStatusFilter();
    const { person } = useUser();

    useEffect(() => {
        setBalanceExport(balance);
    }, [balance]);
    useEffect(() => {
        if (statusFilter.type === '') {
            setFilteredInfo({});
        }
    }, [dateRange]);
    useEffect(() => {
        //filtar dependiendo el estatus de los amount
        const statusReceive = statusFilter.type;
        if (statusReceive != '') {
            // console.log(statusFilter);
            let balanceFilters;
            if (statusReceive === 'Todos') {
                balanceFilters = balance.filter(
                    (bal) =>
                        bal.invoiceStatus === 'Vigente' ||
                        bal.invoiceStatus === 'Vencido'
                );
            } else {
                balanceFilters = balance.filter(
                    (bal) => bal.invoiceStatus === statusReceive
                );
            }

            const filterEntrance = {
                customerId: null,
                invoiceRef: null,
                hasOpenNote: null,
                currencyId: null,
                invoiceTotal: null,
                balance: null,
                invoiceStatus:
                    statusReceive === 'Todos'
                        ? ['Vigente', 'Vencido']
                        : [statusReceive],
            };
            const pagination = {
                current: 1,
                pageSize: 10,
            };

            const extra = {
                currentDataSource: balanceFilters,
                action: 'filter',
            };

            handleChange(pagination, filterEntrance, {}, extra);
            setTimeout(() => {
                reset();
            }, 3000);
        }
    }, []);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => {handleReset(clearFilters); handleSearch(selectedKeys, confirm, dataIndex);}}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    // console.log(balance, 'balance table');

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'invoiceRef',
            key: 'invoiceRef',
            ...getColumnSearchProps('invoiceRef'),
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Cliente',
            dataIndex: 'customerId',
            key: 'customerId',
            ...getColumnSearchProps('customerId'),
            ellipsis: true,
            width: [
                ROL_LAYOUT,
                ROL_MANAGER,
                ROL_FINANCIAL,
                // ROL_OPERACIONES,
                // ROL_CUSTOMER,
            ].some((r) => person.roles.includes(r)) ? 150 :0,
        },
        {
            title: 'Emisión',
            dataIndex: 'invoiceIssueDate',
            key: 'invoiceIssueDate',
            render: (invoiceIssueDate) => (
                <>{moment.utc(invoiceIssueDate).local().format('DD/MM/YYYY')}</>
            ),
            sorter: (a, b) => a.invoiceIssueDate - b.invoiceIssueDate,
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Vencimiento',
            dataIndex: 'invoiceDueDate',
            key: 'invoiceDueDate',
            render: (invoiceDueDate) => (
                <>{moment.utc(invoiceDueDate).local().format('DD/MM/YYYY')}</>
            ),
            sorter: (a, b) => a.invoiceDueDate - b.invoiceDueDate,
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Referencia cte.	',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            width: 150,
            ...getColumnSearchProps('purchaseOrder'),
            ellipsis: true,
        },
        {
            title: 'Estatus',
            dataIndex: 'invoiceStatus',
            key: 'invoiceStatus',
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                // { text: 'Pagado', value: 'Pagado' },
                { text: 'Vencido', value: 'Vencido' },
            ],
            filteredValue: filteredInfo.invoiceStatus || null,
            onFilter: (value, record) => record.invoiceStatus.includes(value),
            render: (invoiceStatus) => (
                <Tag
                    color={
                        invoiceStatus === 'Vigente'
                            ? 'green'
                            : invoiceStatus === 'Pagado'
                            ? 'orange'
                            : 'red'
                    }
                >
                    {invoiceStatus.toUpperCase()}
                </Tag>
            ),
            width: 120,
            ellipsis: true,
        },

        {
            title: 'Moneda',
            dataIndex: 'currencyId',
            key: 'currencyId',
            align: 'center',
            filters: [
                { text: 'MXN', value: 'MXN' },
                { text: 'USD', value: 'USD' },
            ],
            filteredValue: filteredInfo.currencyId || null,
            onFilter: (value, record) => record.currencyId.includes(value),
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Total',
            dataIndex: 'invoiceTotal',
            key: 'invoiceTotal',
            width: 150,
            ...getColumnSearchProps('invoiceTotal'),
            render: (invoiceTotal) => <>${formatMoney(invoiceTotal)}</>,
            ellipsis: true,
        },

        {
            title: 'Saldo',
            dataIndex: 'balance',
            key: 'balance',
            ...getColumnSearchProps('balance'),
            render: (balance) => <>${formatMoney(balance)}</>,
            ellipsis: true,
            width: 150,
        },
        {
            title: 'Aclaraciones',
            key: 'hasOpenNote',
            dataIndex: 'hasOpenNote',
            align: 'center',
            width: 150,
            ellipsis: true,
            filters: [
                { text: 'Nuevo', value: 'new' },
                { text: 'Abierto', value: 'open' },
                { text: 'Cerrado', value: 'close' },
            ],
            filteredValue: filteredInfo.hasOpenNote || null,
            onFilter: (value, record) => {
                //cerrada : hasOpenNote === false && noteid !== null
                if (value === 'new') {
                    return (
                        (!record.hasOpenNote && record.noteId === 0) ||
                        (!record.hasOpenNote && record.noteId !== 0)
                    );
                }
                if (value === 'open') {
                    return record.hasOpenNote && record.noteId !== 0;
                }
                if (value === 'close') {
                    return !record.hasOpenNote && record.noteId !== 0;
                }
            },
            render: (hasOpenNote, invoice) => (
                <>
                    {invoice.noteId !== 0 && (
                        <Tooltip
                            placement="topLeft"
                            title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
                        >
                            <Tag
                                onClick={() =>
                                    history.push(
                                        `/aclaraciones/detail/${invoice.noteId}`
                                    )
                                }
                                color={hasOpenNote ? 'green' : 'gray'}
                            >
                                {invoice.noteId}
                            </Tag>
                        </Tooltip>
                    )}
                    {[ROL_CUSTOMER].some((r) => person.roles.includes(r)) &&
                        !hasOpenNote && (
                            <Tooltip placement="topLeft" title={'Nueva'}>
                                <Tag
                                    onClick={() =>
                                        history.push(
                                            `/aclaraciones/nueva/${invoice.invoiceRef}`
                                        )
                                    }
                                    color={'green'}
                                >
                                    <PlusCircleOutlined />
                                </Tag>
                            </Tooltip>
                        )}
                </>
            ),
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter, extra) => {
        // console.log(filters);
        setFilteredInfo(filters);
        setBalanceExport(extra.currentDataSource);
        let sumatotal = 0;
        extra.currentDataSource.forEach((element) => {
            sumatotal += element.balance;
        });
        setTotal(sumatotal);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };
    const generateExcel = (entries) => {
        const filename = `Estado-de-cuenta${moment().format('L')}.xlsx`;
        let fecha = moment().format('LLL');
        const ws_name = 'Hoja1';
        let wb = {
            SheetNames: [],
            Sheets: {},
        };
        let ws = {};
        let renInicial = 4;
        var mstrTitulo = `Estado de cuenta del ${dateRange.startDate} al: ${dateRange.endDate}`;
        // var mstrTitulo = `Estado de cuenta por fecha de ${titleFilter} del ${dateRange.startDate} al: ${dateRange.endDate}`;

        ws = {
            A1: { v: mstrTitulo, t: 's' },
            H1: { v: fecha, t: 's' },

            A3: { v: 'Referencia cte.', t: 's' },
            B3: { v: 'Folio', t: 's' },
            C3: { v: 'Emisión', t: 's' },
            D3: { v: 'Vencimiento', t: 's' },
            E3: { v: 'Estatus', t: 's' },
            F3: { v: 'Moneda', t: 's' },
            G3: { v: 'Total', t: 's' },
            H3: { v: 'Saldo', t: 's' },
        };

        for (let i in entries) {
            const {
                customerId,
                invoiceRef,
                invoiceIssueDate,
                invoiceDueDate,
                invoiceStatus,
                currencyId,
                invoiceTotal,
                balance,
            } = entries[i];
            ws['A' + renInicial] = {
                v: customerId,
                t: 's',
            };
            ws['B' + renInicial] = { v: invoiceRef, t: 's' };
            ws['C' + renInicial] = {
                v: moment.utc(invoiceIssueDate).local().format('DD/MM/YYYY'),
                t: 's',
            };
            ws['D' + renInicial] = {
                v: moment.utc(invoiceDueDate).local().format('DD/MM/YYYY'),
                t: 's',
            };
            ws['E' + renInicial] = { v: invoiceStatus, t: 's' };
            ws['F' + renInicial] = { v: currencyId, t: 's' };
            ws['G' + renInicial] = {
                v: `$ ${formatMoney(invoiceTotal)}`,
                t: 's',
            };
            ws['H' + renInicial] = { v: `$ ${formatMoney(balance)}`, t: 's' };
            renInicial++;
        }
        ws['G' + (entries.length + 5)] = { v: 'Saldo total', t: 's' };
        ws['H' + (entries.length + 5)] = {
            v: `$ ${formatMoney(total)}`,
            t: 's',
        };

        // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
        // ws['!cols'] = wscols;
        ws['!ref'] = XLSX.utils.encode_range({
            s: { c: 0, r: 0 },
            e: { c: 15, r: renInicial },
        });
        wb.SheetNames.push(ws_name);
        wb.Sheets[ws_name] = ws;
        XLSX.writeFile(wb, filename, { cellStyles: true });
    };

    const exportPdf = (bills, startDate, endDate) => {
        var mstrTitulo = `Estado de cuenta del ${dateRange.startDate} al: ${dateRange.endDate}`;
        // var mstrTitulo = `Estado de cuenta por fecha de ${titleFilter} del ${dateRange.startDate} al: ${dateRange.endDate}`;

        // var mstrTitulo = `CORTE DE CAJA - Reporte de Ventas del: ${moment
        //     .unix(startDate)
        //     .format('LLL')} al: ${moment.unix(endDate).format('LLL')}`;
        var mstrPdf = `Estado-de-cuenta${moment().format('L')}.pdf`;

        var registros = [];
        bills.forEach((element) => {
            registros.push([
                element.customerId,
                element.invoiceRef,
                moment
                    .utc(element.invoiceIssueDate)
                    .local()
                    .format('DD/MM/YYYY'),
                moment.utc(element.invoiceDueDate).local().format('DD/MM/YYYY'),
                element.invoiceStatus,
                element.currencyId,
                '$ ' + formatMoney(element.invoiceTotal),
                '$ ' + formatMoney(element.balance),
            ]);
        });

        // console.log(total, 'total');
        // registros.push([''], ['Saldo Total', formatMoney(total)]);
        registros.push(
            [''],
            ['', '', '', '', '', '', 'Saldo Total', formatMoney(total)]
        );

        let fecha = moment().format('LLL');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 220;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(-10, -10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };

        doc.autoTable({
            head: [
                [
                    'Referencia cte.',
                    'Folio',
                    'Emisión',
                    'Vencimineto',
                    'Estatus',
                    'Moneda',
                    'Total',
                    'Saldo',
                ],
            ],
            didDrawPage: pageContent,
            theme: 'grid',
            headStyles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [49, 101, 120],
                textColor: [255, 255, 255],
            },
            body: registros,
            columnStyles: {
                text: {
                    cellWidth: 'wrap',
                },
            },
        });

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };
    return (
        <>
            {/* <Title level={5} style={{ color: 'gray' }}>
                Filtro por fecha de: {titleFilter}
            </Title> */}
            <Table
                onChange={handleChange}
                columns={columns}
                dataSource={balance}
                scroll={{ x: 1200 }}
                defaultPageSize={10}
                // pagination={{
                //     pageSize: 10,
                // }}
                loading={reloadBalance}
            />

            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        icon={<FilePdfFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => exportPdf(balanceExport)}
                        disabled={balanceExport.length === 0}
                    >
                        Descargar Pdf
                    </Button>
                    <Button
                        icon={<FileExcelFilled style={{ fontSize: 18 }} />}
                        className="btn-download"
                        onClick={() => generateExcel(balanceExport)}
                        disabled={balanceExport.length === 0}
                    >
                        Descargar Excel
                    </Button>
                </Col>
            </Row>
        </>
    );
}
