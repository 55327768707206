export const types = {
   getTypeOfLoad: '[catalogs] getTypeOfLoad',
   getOperations: '[catalogs] getOperations',
   getHazMat: '[catalogs] getHazMat',
   getTypeOfService: '[catalogs] getTypeOfService',
   getUnity: '[catalogs] getUnity',
   getEquipmentType: '[catalogs] getEquipmentType',
   getCurrency: '[catalogs] getCurrency',
   getMeasurementUnit: '[catalogs] getMeasurementUnit',
   getPackagingUnit: '[catalogs] getPackagingUnit',
   getFrecuency: '[catalogs] getFrecuency',
};
