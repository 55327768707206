import React from 'react';
import { Button, Col, Layout, Row, Typography } from 'antd';
import GreetingsTop from '../../../../components/Admin/GreetingsTop/GreetingsTop';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import QuoteLinesTable from '../../../../components/Logistic/Quote/QuoteLinesTable/QuoteLinesTable';

export default function LinesQuote() {
  return (
    <Layout>
      <GreetingsTop />

      <HeaderTop />

      <div className='clients__container'>
        <QuoteLinesTable />
      </div>
    </Layout>
  );
}

function HeaderTop({ supplier }) {
  const { Title } = Typography;
  let history = useHistory();
  let { quoteId } = useParams();

  return (
    <div className='clients__container'>
      <Row xs={23} className='header-top'>
        <Col xs={19} className='header-top__left'>
          <Title level={3}>Líneas Cotización {quoteId}</Title>
        </Col>
        <Col xs={1}>
          <Button type='link' onClick={() => history.goBack()}>
            <ArrowLeftOutlined /> Atrás
          </Button>
        </Col>
      </Row>
    </div>
  );
}
