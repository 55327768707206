import { types } from '../../types/types';

export const catalogsReducer = (state, action) => {
    switch (action.type) {
        case types.getTypeOfLoad:
            return {
                ...state,
                typeOfLoad: action.payload,
            };

        case types.getOperations:
            return {
                ...state,
                operations: action.payload,
            };

        case types.getHazMat:
            return {
                ...state,
                hazMat: action.payload,
            };

        case types.getTypeOfService:
            return {
                ...state,
                typeOfService: action.payload,
            };
        case types.getEquipmentType:
            return {
                ...state,
                typeOfEquipment: action.payload,
            };
        case types.getUnity:
            return {
                ...state,
                unit: action.payload,
            };
        case types.getMeasurementUnit:
            return {
                ...state,
                measurementUnit: action.payload,
            };
        case types.getCurrency:
            return {
                ...state,
                currency: action.payload,
            };
        case types.getPackagingUnit:
            return {
                ...state,
                packaging: action.payload,
            };

        default:
            return state;
    }
};
